import gql from 'graphql-tag';

export const LOGIN_USER = gql`
  mutation LoginUser($credential: signInInput) {
    signIn(credential: $credential) {
      accessToken
      features
      userStatus
      fullName
      isWalletRegistered
      email
      telephone
      did
      image
      refreshToken
    }
  }
`;
