//@ts-nocheck
import React from 'react';
import {View, StyleSheet} from 'react-native';
import {StepV} from 'react-steps-rs';
import blueprint from '../config/default-blueprint.json';
import {HeaderV, VerifyForm, VerifyWallet} from './index';
import {PanelV} from 'react-panel-rs';
import {ConsoleLogger, LoggerService} from 'logger-extension-vfs';
import {useViewport} from 'react-viewport-provider';

const console: LoggerService = new LoggerService(
  new ConsoleLogger('VerifyUser:VerifyScreen'),
);

const BP = blueprint.VerifyScreen;

interface VerifyScreenProps {
  onSubmitPress?(): void;
  qrValue?: string;
  qrLabel?: string;
}

export const VerifyScreen = (props: VerifyScreenProps) => {
  const [step, setStep] = React.useState([]);
  const {width, height} = useViewport();

  const styles = {
    panelStyle: {
      marginLeft: '10%',
      marginRight: '10%',
      justifyContent: 'center',
      backgroundColor: 'transparent',
      borderRadius: 0,
      height: height - 66,
    },
  };

  const _getBodyContent = key => {
    if (key === 0) {
      return <VerifyForm {...props} />;
    } else if (key === 1) {
      return <VerifyWallet {...props} />;
    }
  };

  return (
    <View>
      <HeaderV />
      <PanelV style={StyleSheet.flatten([styles.panelStyle])}>
        <StepV
          steps={BP.steps}
          bodyContent={key => _getBodyContent(key)}
          currentStep={() => {
            console.trace('entered');
          }}
        />
      </PanelV>
    </View>
  );
};
