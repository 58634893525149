import {useQuery} from '@apollo/react-hooks';
import {ConsoleLogger, LoggerService} from 'logger-extension-vfs';
import React from 'react';
import {pretty} from 'react-native-helpers';
import {ScrollView} from 'react-native';
import {VerifyScreen} from './components';
import {ViewportProvider} from 'react-viewport-provider';

const console: LoggerService = new LoggerService(
  new ConsoleLogger('VerifyUser:container'),
);

export const VerifyUser = () => {
  return (
    <ViewportProvider>
      <VerifyScreen />
    </ViewportProvider>
  );
};
