import React from 'react';
import {useViewport} from 'react-viewport-provider';
import {DashboardLG} from './Dashboard.lg';
import {DashboardMD} from './Dashboard.md';
import {DashboardSM} from './Dashboard.sm';

export interface IProps {
  netwrokCredData?:any;
  adminUsers?:any;
  adminLoading?:boolean;
  userProfile?:any;
  opeProfileData?:any;
  orgProfileData?:any;
  getUsersLoading?:boolean;
  holdersList?: any[];
  issuersList?: any[];
  verifiersList?: any[];
  sidebarNavItemSelect?(eventKey?:string): void;
  categoryClick?(eventKey?: string): void;
  inviteIssuerFormData?(formContext?:any): void;
  onInviteIssuer?(formData?:any):void;
  onInviteVerifier?(formData?:any): void;
  onInviteHolders?(formData?:any): void;
  onInviteAdmin?(formData?:any): void;
  onTemplateSelect?(eventKey?:any): void;
  onIssueCredential?(issueData?:any): any;
  inviteUserLoading?:boolean;
  inviteUserStaus?:boolean;
  credentialCategoryLoading?:boolean;
  credentialCategries?:any;
  templateData?: any[];
  issueCredentialSuccess?:any[];
  issueCredLoading?:boolean;
  findTemplates?:boolean;
  sideNavItems?:any[];
  networkData?:any;
  networkLoading?:boolean;
  onCategoryClick?(eventKey?:any):void;
  onVerifyRequest?(requests?:any):void;
  reqCredLoading?:boolean;
  reqCredSuccess?:boolean;
  onNetworkPageChange?(page?:number):void;
  onPresentationSelect?(edgeTag?:string):void;
  presentationDetails?:any;
  presentationDetailLoading?:boolean;
  onCredentialResend?(resendData?:any): void;
  resendCredentialLoading?:boolean;
}

export const Dashboard = (props: IProps) => {
  const {bp} = useViewport();

  if (bp === 'xs' || bp === 'sm') {
    return <DashboardSM {...props}/>;
  }

  // if (bp === 'md') {
  //   return <DashboardMD />;
  // }

  //bp === 'lg' || bp === 'xl' || bp === 'md
  return <DashboardLG  {...props}/>;
};
