import {ConsoleLogger, LoggerService} from 'logger-extension-vfs';
import React from 'react';
import {pretty} from 'react-native-helpers';
import {SignupPage} from './components';

const console: LoggerService = new LoggerService(
  new ConsoleLogger('Login:container'),
);

export const Signup = () => {
  return <SignupPage />;
};
