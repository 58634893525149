import React, { useContext, useEffect } from 'react';
import { StyleSheet } from 'react-native';
import config from '../config/default-blueprint.json';
import { ThemeContext } from '../../../../../../../../providers/theme';
import { BodyTemplateV } from 'react-body-template-1';
import { NetworkCredentialTemplateV } from 'react-network-credentail-template';
import { Whisper, Tooltip } from 'rsuite';
import { ButtonV, IconButtonV } from 'react-button-rs';
import { DrawerV } from 'react-drawer-rs';
import { useViewport } from 'react-viewport-provider';
import { View } from 'react-native';
import { TLProfileTemplateV } from 'react-profile-timeline-template';


const humanizeString = require('humanize-string');
const BP = config.credentialView;

import{ IProps} from './index';



export const CredentialViewLG = (props: IProps) => {
    const theme = useContext(ThemeContext);
    const { width, height } = useViewport();
    const [timelineData, setTimelineData] = React.useState<any>();
    const [userActionKey, setUserActionKey] = React.useState('');
    const [drawer, setDrawer] = React.useState<any>({
        placement: 'right',
        title: '',
        size: 'sm',
        backdrop: false,
        show: false,
    });
    console.log('cred view in component', props?.netwrokCredData)

    const styles = {
        leftcontainerStyle: {
            display: 'flex',
        },
        rightContainerStyle: {
            float: 'right',
            padding: 10,
        },
        containerStyle: {
            backgroundColor: '#f7f7fa',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: 'none',
            height: height - (height * 0.16),
            padding: 20,
        },
    };

    const onAddNewTemplate = (eventKey: string) => {
        setUserActionKey(eventKey);
        if (eventKey === 'new certificate') {
            let _drawer = { ...drawer };
            _drawer.title = humanizeString(eventKey);
            _drawer.placement = BP?.userDrawerPlacement;
            _drawer.show = true;
            setDrawer({
                ..._drawer,
            });
        }
    };

    const renderDrawerBody = () => {
        console.log('drawer item', timelineData);
        return (
            <View >
                <TLProfileTemplateV
                    backgroundImage={'https://i.ibb.co/mFkJgxw/pattern-2.png'}
                    profileImage={timelineData?.icon?.imageURI}
                    fullName={timelineData?.title}
                    nickName={timelineData?.nickName}
                    email={timelineData?.email}
                    vid={timelineData?.vid}
                    vidTooltip={timelineData?.vidTooltip}
                    profileDetails={timelineData?.credentialDetials}
                    timelineItems={timelineData?.timelineItems}
                    timelineAlign={'left'}
                    timelineContainerStyle={{
                        justifyContent: 'flex-start',
                        flex: 1,
                    }}
                    timelineProps={{
                        timeContainerStyle: {
                            backgroundColor: '#de6177',
                            color: '#fff',
                            padding: 10,
                            borderRadius: 10
                        },
                        textContainerStyle: {
                            backgroundColor: '#ededed',
                            padding: 10,
                            borderRadius: 10
                        }
                    }}

                    timeLineOuterContainerStyle={{
                        flex: 1,
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}

                />
            </View>
        )
    };

    const handleOnDrawerHide = () => {
        let _drawer = { ...drawer };
        _drawer.show = false;
        setDrawer({
            ..._drawer,
        });
    };

    const renderDrawerHeader = () => {
        return <div />;
    };

    const renderDrawerFooter = () => {
        return <div />;
    };

    const onCardClick = (eventKey: any) => {
        console.log('cardClick', eventKey)
        setUserActionKey(eventKey.key);
        setTimelineData(eventKey);
        let _drawer = { ...drawer };
        _drawer.title = humanizeString(eventKey?.key);
        _drawer.placement = BP?.drawerPlacement;
        _drawer.show = true;
        setDrawer({
            ..._drawer,
        });
    }

    const onNavItemClick = (eventKey: string) => {
        setUserActionKey(eventKey);
        if (eventKey === 'newIssuer') {
            let _drawer = { ...drawer };
            _drawer.title = humanizeString(eventKey);
            _drawer.placement = BP?.drawerPlacement;
            _drawer.show = true;
            setDrawer({
                ..._drawer,
            });
        }
    props?.navItemSelect && props?.navItemSelect(eventKey);
    };

    const bodyContent = () => {
        return (
            <NetworkCredentialTemplateV
                templateDetails={props?.netwrokCredData?.INDIVISUAL_CREDENTIAL}
                onCardClick={onCardClick}
                containerStyle={StyleSheet.flatten([styles.containerStyle])}
            >
                <NetworkCredentialTemplateV
                    templateDetails={props?.netwrokCredData?.ORGANIZATION_PROFILE}
                    title={"Organization"}
                    onCardClick={onCardClick}
                    containerStyle={StyleSheet.flatten([styles.containerStyle])}
                />
            </NetworkCredentialTemplateV>
        );
    };
    return (
        <>
            <BodyTemplateV
                primaryColor={BP?.primaryColor || theme?.palette.primary}
                primaryHighlightColor={
                    BP?.primaryHighlightColor || theme?.palette.secondary
                }
                secondaryColor={BP?.secondaryColor || theme?.palette.secondary}
                ternaryColor={BP?.ternaryColor || theme?.palette.ternary}
                renderContent={bodyContent}
                dashboardContainerStyle={StyleSheet.flatten([styles.containerStyle])}
                contentScrollableInline={true}
                headerTitleStyle={{ marginLeft: 20 }}
                navbarStyle={{}}
                layout={'sidebar-header-content-footer'}
                navBarRightItems={BP?.navRightItems}
                navBarLeftItems={BP?.navLeftItems}
                onNavItemSelect={onNavItemClick}
                headerBackgroundColor={'#fff'}
                renderFooter={() => <div />}
            />
            <DrawerV
                placement={drawer.placement}
                show={drawer.show}
                backdrop={drawer.backdrop}
                size={drawer.size}
                renderHeader={renderDrawerHeader}
                onHide={handleOnDrawerHide}
                drawerTitle={drawer.title}
                renderBody={renderDrawerBody}
                renderFooter={renderDrawerFooter}
            />
        </>
    );
};
