import React, { useContext, useLayoutEffect } from 'react';
import { StyleSheet } from 'react-native';
import config from './config/default-blueprint.json';
import { ThemeContext } from '../../../../../../providers/theme';
import { BodyTemplateV } from 'react-body-template-1';
import { CredentialTemplateV } from 'react-credentail-template';
import { Whisper, Tooltip } from 'rsuite';
import { ButtonV, IconButtonV } from 'react-button-rs';
import { DrawerV } from 'react-drawer-rs';
import jsf from 'json-schema-faker';
import faker from 'faker';
import Chance from 'chance';
import { useViewport } from 'react-viewport-provider';
import { LogoSpinnerV } from 'react-logo-spinner-ext';

jsf.extend('faker', () => faker);
jsf.extend('chance', () => new Chance());

const humanizeString = require('humanize-string');
const BP = config.CredentialCategory;

const schema = BP?.users;

type CredentailsProps = {
  onSearchClick?(): void;
  onCardClick?(eventKey?: string): void;
  credentialCategories?:any;
  credentialCategoryLoading?:boolean;
};


export const CredentailCategory = (props: CredentailsProps) => {
  const theme = useContext(ThemeContext);
  const { width, height } = useViewport();
  const [data, setData] = React.useState<any>([]);
  const [userActionKey, setUserActionKey] = React.useState('');
  const [drawer, setDrawer] = React.useState<any>({
    placement: 'right',
    title: '',
    size: 'sm',
    backdrop: false,
    show: false,
  });

  const styles = {
    leftcontainerStyle: {
      display: 'flex',
    },
    rightContainerStyle: {
      float: 'right',
      padding: 10,
    },
    containerStyle: {
      backgroundColor: '#f7f7fa',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 'none',
      height: height - (height * 0.16),
      padding: 20,
    },
  };

  const renderDrawerBody = () => {
    if (userActionKey === 'adduser') {
      return <InviteUser />;
    }
    return <div />;
  };

  const handleOnDrawerHide = () => {
    let _drawer = { ...drawer };
    _drawer.show = false;
    setDrawer({
      ..._drawer,
    });
  };

  const renderDrawerHeader = () => {
    return <div />;
  };

  const renderDrawerFooter = () => {
    return <div />;
  };

  const onNavItemClick = (eventKey: string) => {
    setUserActionKey(eventKey);
    if (eventKey === 'newIssuer') {
      let _drawer = { ...drawer };
      _drawer.title = humanizeString(eventKey);
      _drawer.placement = BP?.drawerPlacement;
      _drawer.show = true;
      setDrawer({
        ..._drawer,
      });
    }
  };

  const bodyContent = () => {
    let categories = Array.isArray(props.credentialCategories)  && props.credentialCategories.map((item:any)=>{
      return({
        ...item,
        key: item?.templateIdentifiers,
      });
    })
    if(props.credentialCategoryLoading){
      return <LogoSpinnerV imageStyle={{left:'50%'}}/>
    }
    else {
      return (
        <CredentialTemplateV
          templateDetails={categories}
          onCardClick={eventKey => props.onCardClick(eventKey)}
          containerStyle={StyleSheet.flatten([styles.containerStyle])}
        />
      );
    }
    
  };
  const hederLeftData = () => {
    return (
      <div style={StyleSheet.flatten([styles.leftcontainerStyle])}>
        <Whisper
          placement="top"
          trigger="hover"
          speaker={<Tooltip>Search </Tooltip>}
          onClick={props.onSearchClick}
        >
          <IconButtonV icon={{ name: 'search' }} circle />
        </Whisper>
      </div>
    );
  };
  return (
    <>
      <BodyTemplateV
        primaryColor={BP?.primaryColor || theme?.palette.primary}
        primaryHighlightColor={
          BP?.primaryHighlightColor || theme?.palette.secondary
        }
        secondaryColor={BP?.secondaryColor || theme?.palette.secondary}
        ternaryColor={BP?.ternaryColor || theme?.palette.ternary}
        renderContent={bodyContent}
        dashboardContainerStyle={StyleSheet.flatten([styles.containerStyle])}
        contentScrollableInline={true}
        headerTitleStyle={{ marginLeft: 20 }}
        navbarStyle={{}}
        layout={'sidebar-header-content-footer'}
        navBarRightItems={BP?.navRightItems}
        navBarLeftItems={BP?.navLeftItems}
        onNavItemSelect={onNavItemClick}
        headerBackgroundColor={'#fff'}
        renderFooter={() => <div />}
      />
      <DrawerV
        placement={drawer.placement}
        show={drawer.show}
        backdrop={drawer.backdrop}
        size={drawer.size}
        renderHeader={renderDrawerHeader}
        onHide={handleOnDrawerHide}
        drawerTitle={drawer.title}
        renderBody={renderDrawerBody}
        renderFooter={renderDrawerFooter}
      />
    </>
  );
};
