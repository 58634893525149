import React from 'react';
import { useViewport } from 'react-viewport-provider';
import { VerificationLG } from './Verification.lg';

export interface IProps {
    onSearchClick?(): void;
  drawerWidth: number;
  credentialCategories?: any;
  onCategoryClick?(eventKey?:any):void;
  credList?: any;
  onClaimPress?(claims?:any):void;
}

export const Verification = (props: IProps) => {
    const { bp } = useViewport();

    // if (bp === 'xs' || bp === 'sm') {
    //     return <DashboardSM />;
    // }

    // if (bp === 'md') {
    //   return <DashboardMD />;
    // }

    //bp === 'lg' || bp === 'xl' || bp === 'md
    return <VerificationLG {...props} />;
};
