import React, { useContext, useState } from 'react';
import { ThemeContext } from '../../../../../../providers/theme';
import { TLProfileTemplateV } from 'react-profile-timeline-template';
import { DrawerV } from 'react-drawer-rs';
import { BodyTemplateV } from 'react-body-template-1';
import { View, StyleSheet, Text } from 'react-native';
import { NetworkGraphV } from '../../../../../../widgets/NetworkGraphV';
import blueprint from './config/default-blueprint.json';
import { ViewportProvider, useViewport } from 'react-viewport-provider';
import { IProps } from './index';
import Popover from 'rsuite/lib/Popover';
import { AvatarV } from 'react-native-avatar-vfw';
import { ImageBackgroundV } from 'react-image-background';
import { FBGridV, FBItemV } from 'react-flexbox-grid-rs';
import { CheckPickerV } from 'react-checkpicker-rs';
import { DateRangePickerV } from 'react-daterange-picker-rs';
import { ButtonV } from 'react-button-rs';
import { ChipV } from 'react-chip-mui';
import moment from 'moment';
import { ToggleV } from 'react-toggle-rs';
import { ProfileTemplateV } from 'react-profile-template-rs';
import IconButton from 'rsuite/lib/IconButton';
import Icon from 'rsuite/lib/Icon';
import { LogoSpinnerV } from 'react-logo-spinner-ext';
import { humanize } from 'uno-js';
import Pagination from '@material-ui/lab/Pagination';
import { Panel } from 'rsuite';

const ids = require('short-id');

const BP = blueprint.TrustNetwork;

const TITLE_COLOR = '#717171';
const EDGES_PER_PAGE = 12;

const handleEvidenceClick = (evidence: string) => {
    evidence && window.open(evidence, "_blank");
}


const TrustNetworkInternal = (props: IProps) => {
    const { drawerWidth } = props;
    const theme = useContext(ThemeContext);
    const { height, width } = useViewport();
    const [navItemKey, setNavItemKey] = useState<string>();
    const [resendpushData, setResendPushData] = useState<any>({
        edgeTag: '',
        did: '',
        type: ''
    });
    const [drawer, setDrawer] = useState<any>({
        placement: 'right',
        title: '',
        size: 'xs',
        backdrop: false,
        show: false,
    });
    const [show, setShow] = React.useState<boolean>(false);
    const [nodeData, setNodeData] = useState<any>();
    const [chipData, setChipData] = useState<any>([]);
    const [pos, setPos] = React.useState<any>({
        x: 0,
        y: 0,
    });
    const [selectValues, setSelectValues] = useState<any>({
        organizationValues: [],
        credentialValues: [],
        dateRange: []
    });
    const [hoverNode, setHoverNode] = useState<number>();
    const [page, setPage] = useState<number>(1);
    const triggerRef = React.createRef();
    const styles = {
        navbarItemIconStyle: {
            color: TITLE_COLOR,
        },
        avatarStyle: {
            background: 'transparent',
            width: 100,
        },
        rightContainerStyle: {
            float: 'right',
        },
        fixedDivStyle: {
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'transparent',
            position: 'absolute',
            height: '300px',
            top: '10px',
            width: width - drawerWidth + 70,
            borderRadius: 10,
            display: 'flex',
            margin: '0 auto',
            zIndex: 10,
        },
        bodyContainerStyle: {
            justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute',
            width: width - drawerWidth + 70,
            backgroundColor: '#fffff0',
        },
        popOverStyle: {
            position: 'absolute',
            // top:50,
            right: 5,
            width: 320,
            zIndex: 'auto'
        },
        iconContainerStyle: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'start',
            alignSelf: 'center'
        },
        dateContainerStyle: {
            marginTop: 20,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItem: 'center',
            borderTop: '1px dotted #ccc'
        },
        avatarContainerStyle: {
            backgroundColor: '#fff',
            //  borderRadius: 10,
            shadowOpacity: 0.4,
            shadowRadius: 5,
            shadowColor: '#373a3b',
            elevation: 4,
            shadowOffset: { height: 0, width: 0 },
        },
        headingStyle: {
            fontSize: 20,
            alignSelf: 'center',
            fontWeight: 600,
            paddingLeft: 15
        },
        dateHeadingStyle: {
            display: 'block',
            fontSize: 14,
            fontWeight: '500',

        },
        dateStyle: {
            fontSize: 12,
        },
        issuerTitleStyle: {
            display: 'block',
            fontSize: 16,
            fontWeight: 500,
            paddingTop: 8
        },
        paginationStyle: {
            alignSelf: 'cnter',
            padding: 10,
            backgroundColor: '#fff',
            borderTop: '1px solid #ccc',
            width: '100%'
        },
        cardStyle: {
            margin: '10px 0px',
            backgroundColor: '#ececec',
            cursor: 'pointer'
        }
    };

    const BUTTONS = [
        {
            buttonTitle: 'connect',
            containerStyle: {
                backgroundColor: '#fccfcf',
            },
        },
    ];

    const onResendPushData = () => {
        console.log('inside resend button press', resendpushData);
        props.onCredentialResend && props.onCredentialResend(resendpushData);
    }

    const handleSelect = event => {
        console.log('on select node', event?.nodes[0]);
        let nodeIndex = event?.nodes[0];
        let _drawer = drawer;
        setPos({
            x: 0,
            y: 0,
        });
        let _selectedNode = Array.isArray(props.networkData?.graphs?.nodeDetails) && props.networkData?.graphs?.nodeDetails.filter(item => {
            if (item?.id === nodeIndex) {
                return true;
            }
        });
        let _nodeTypeObj = Array.isArray(props.networkData?.graphs?.nodes) && props.networkData?.graphs?.nodes.filter(item => {
            if (item?.id === nodeIndex) {
                return true;
            }
        });
        let _nodeType = _nodeTypeObj[0]?.nodeType;
        let _edgeTag = _nodeTypeObj[0]?.edgeTag;
        let _toDid = _nodeTypeObj[0]?.toDID;
        if (_nodeType === 'presentation') {
            props?.onPresentationSelect && props?.onPresentationSelect(_edgeTag);
        }
        if (_nodeType === 'credential') {
            let _resendData = resendpushData;
            _resendData.edgeTag = _edgeTag;
            _resendData.did = _toDid;
            _resendData.type = "ISSUE_CREDENTIAL"
            setResendPushData({
                ..._resendData
            });
        }
        setNavItemKey(_nodeType);
        console.log('selected node:', _selectedNode);
        setNodeData(_selectedNode[0]);

        _drawer.show = true;

        setShow(true);
        setDrawer({ ..._drawer });
    };

    const handleHover = event => {
        console.log('on node hover', event?.node);
        setHoverNode(event?.node)
        setShow(true);
        setPos({
            x: event?.pointer?.DOM?.x,
            y: event?.pointer?.DOM?.y,
        });
        setShow(true);
    };

    const handleBlur = event => {
        setPos({
            x: 0,
            y: 0,
        });
        setShow(false);
    };

    const handleDragStart = event => {
        console.log('handleDragStart');
        setPos({
            x: 0,
            y: 0,
        });
        setShow(false);
    };

    const _getHoverCard = () => {


        if (hoverNode) {
            let _nodes = Array.isArray(props.networkData?.graphs?.nodeDetails) && props.networkData?.graphs?.nodeDetails.filter(item => {
                if (item?.id === hoverNode) {
                    return true;
                }
            });

            let _hoverNode = Array.isArray(_nodes) && _nodes[0];
            console.log('node hover data', _hoverNode);
            let validFrom = Array.isArray(_hoverNode?.details) && _hoverNode?.details.find(item => {
                if (item?.key === 'validFrom') {
                    return item?.value
                }
            });
            let validUntil = Array.isArray(_hoverNode?.details) && _hoverNode?.details.find(item => {
                if (item?.key === 'validUntil') {
                    return item?.value
                }
            });
            let issuer = Array.isArray(_hoverNode?.details) && _hoverNode?.details.find(item => {
                if (item?.key === 'issuer') {
                    return item?.value
                }
            });

            console.log('isuer name _nodes', validFrom);
            return (
                <View style={styles.popOverStyle}>
                    <Popover visible={show} style={{ width: 300 }}>
                        <ImageBackgroundV image={"https://i.ibb.co/k0V3g40/cert600x400.png"} width={'100%'} height={"auto"} >
                            <View style={StyleSheet.flatten([styles.iconContainerStyle])}>
                                <AvatarV
                                    source={{ uri: _hoverNode?.image }}
                                    size={80}
                                    containerStyle={StyleSheet.flatten([styles.avatarContainerStyle])}
                                />
                                <Text style={StyleSheet.flatten([styles.headingStyle])}>{humanize(_hoverNode?.title).split(/(?=[A-Z])/).join("").replace("Credential", "")}</Text>
                            </View>
                            <Text style={StyleSheet.flatten([styles.issuerTitleStyle])}>{humanize(issuer?.value)}</Text>
                            <View style={StyleSheet.flatten([styles.dateContainerStyle])}>
                                {
                                    validFrom ?
                                        <View>
                                            <Text style={StyleSheet.flatten([styles.dateHeadingStyle])}>Valid From</Text>
                                            <Text style={StyleSheet.flatten([styles.dateStyle])}>{validFrom?.value && moment(validFrom?.value).format("DD-MM-YYYY HH:MM:SS")}</Text>
                                        </View> : null
                                }
                                {
                                    validUntil ?
                                        <div >
                                            <Text style={StyleSheet.flatten([styles.dateHeadingStyle])}>Valid Until</Text>
                                            <Text style={StyleSheet.flatten([styles.dateStyle])}>{validUntil?.value && moment(validUntil?.value).format("DD-MM-YYYY HH:MM:SS")}</Text>
                                        </div> : null
                                }


                            </View>
                        </ImageBackgroundV>
                    </Popover>
                </View>
            )
        }
        else return null;

    }
    const handlePageChange = (event: any, value: number) => {
        setPage(value);
        props.networkPageChange && props.networkPageChange(value);
    }


    const renderContent = () => {
        const profileCardData = BP.profileCardData;
        let pageCount = Math.ceil((props?.networkData?.actualEdgecount) / (EDGES_PER_PAGE));
        console.log('network data in component', props.networkData);
        console.log('network count in component', pageCount);
        let nodeCount = Array.isArray(props?.networkData?.graphs?.nodes) && (props?.networkData?.graphs?.nodes).length;
        console.log('node count', nodeCount);
        if (props?.networkLoading) {
            return <LogoSpinnerV imageStyle={{ marginLeft: '40%' }} />
        }
        else {
            return (
                <>
                    {_getHoverCard()}
                    <FBGridV justify={"center"} align={"top"}>
                        <FBItemV colspan={24}>
                            <div style={StyleSheet.flatten(styles.paginationStyle)}>
                                <Pagination color="primary" shape={"rounded"} count={pageCount} page={page} onChange={handlePageChange} variant="outlined" shape="rounded" style={{ width: '50%' }} />
                            </div>
                        </FBItemV>
                        <FBItemV colspan={24}>
                            {
                                nodeCount > 0 ? (
                                    <NetworkGraphV
                                        style={{ width: '100%', height: '100vh' }}
                                        options={BP.options}
                                        graph={props?.networkData && props?.networkData?.graphs}
                                        // graph={BP?.graphs}
                                        events={{
                                            select: handleSelect,
                                            hoverNode: handleHover,
                                            blurNode: handleBlur,
                                            dragStart: handleDragStart,
                                            dragging: handleDragStart,
                                            draggingEnd: handleDragStart,
                                        }}
                                    />
                                ) :
                                    (
                                        <h4 style={{ height: '55vh', marginTop: '10%', textAlign: 'center', fontSize: 20, fontWeight: 500, }}>No credentials Found</h4>
                                    )
                            }

                        </FBItemV>

                    </FBGridV>
                </>
            );
        }
    };


    const renderDrawerHeader = () => {
        return <div />;
    };

    const renderDrawerFooter = () => {
        return <div />;
    };


    const onApplyFilters = () => {
        let _temp = Object.keys(selectValues);
        const _chipData: any = [];
        _temp.map(item => {
            Array.isArray(selectValues[item]) && selectValues[item]?.map((value: string) => {
                console.log('filter values', item);
                if (item === 'daterange') {
                    _chipData.push(
                        {
                            key: ids.generate(),
                            label: moment(value).format("DD-MM-YYYY")
                        }
                    )
                }
                else {
                    _chipData.push(
                        {
                            key: ids.generate(),
                            label: value
                        }
                    )
                }

            });
            setChipData([..._chipData]);
        });
    }

    const onOrganizationSelect = (value: any) => {
        console.log('select', value);
        setSelectValues({
            ...selectValues,
            organizationValues: value
        });
    }
    const onCredentialSelect = (value: any) => {
        console.log('select', value);
        setSelectValues({
            ...selectValues,
            credentialValues: value
        });
    }

    const onDateRangeSelect = (value: any) => {
        setSelectValues({
            ...selectValues,
            daterange: value
        });
    }

    const handleDelete = (data) => {
        console.log('delete data', data);
    }

    const renderDrawerBody = () => {
        const profileData = nodeData ?? BP.profileData;
        console.log('profileData', profileData);
        let _nodeDetails = Array.isArray(nodeData?.details) && nodeData?.details.map(item => {
            return ({
                name: humanize(item?.key?.toString()),
                value: humanize(item?.value?.toString()),
                labelStyle: {
                    "color": "#fff"
                },
                valueStyle: {
                    "color": "#DE6177"
                }
            });
        });
        const _getChips = [];
        Array.isArray(chipData) &&
            chipData.map((data) => {
                _getChips.push(
                    <FBItemV colspan={10} >
                        <ChipV
                            size={"small"}
                            label={data.label}
                            clickable={true}
                            onDelete={() => handleDelete(data)}
                            chipStyle={{ width: '100%', backgroundColor: 'red', margin: 10, color: '#fff' }}
                        />
                    </FBItemV>

                );
            });
        let _name = nodeData?.title && humanize(nodeData?.title).split(/(?=[A-Z])/).join("");
        let _lastIndex = nodeData?.title && humanize(nodeData?.title).split(/(?=[A-Z])/).join("").lastIndexOf(" ");
        console.log('inside else node deails', navItemKey);
        if (navItemKey === 'filter') {
            return (
                <View>

                    <FBGridV justify="center" style={{ justifyContent: 'space-around' }}>
                        {_getChips}
                    </FBGridV>

                    <CheckPickerV
                        value={selectValues?.organizationValues}
                        title={"Select Organization"}
                        data={BP?.filters?.selectOrganization}
                        checkPickerStyle={{ width: '100%' }}
                        onSelect={value => onOrganizationSelect(value)}
                    />
                    <CheckPickerV
                        value={selectValues?.credentialValues}
                        title={"Select Credentials"}
                        data={BP?.filters?.selectCredential}
                        checkPickerStyle={{ width: '100%' }}
                        onSelect={onCredentialSelect}
                    />
                    <DateRangePickerV
                        value={selectValues?.daterange}
                        onChange={onDateRangeSelect}
                        dateRangePickerStyle={{ width: '100%', marginTop: 15 }}
                        placeholder={"Select Date Range"}
                    />
                    <ButtonV
                        onPress={onApplyFilters}
                        buttonTitle={"Apply Filters"}
                        color={"red"}
                        buttonTitleStyle={{ color: '#fff' }}
                        containerStyle={{ marginTop: 15 }}
                    />
                </View>
            )
        }
        if (navItemKey === 'presCredential') {
            let logo;
            let evidence;
            if (_nodeDetails && Array.isArray(_nodeDetails) && _nodeDetails.length > 0) {
                _nodeDetails = _nodeDetails.filter(node => {
                    console.log('_node =>', node);
                    if (node.name &&
                        !String(node.name).toLowerCase().includes('image') &&
                        !String(node.name).toLowerCase().includes('logo')) {

                        if (String(node.name).toLowerCase().includes('evidence')) {
                            evidence = node.value;
                            return false;
                        }
                        return true;
                    }
                    logo = node.value;
                    return false;
                })
            }
            return (
                <>
                    <ProfileTemplateV
                        backgroundImage={'https://i.ibb.co/mFkJgxw/pattern-2.png'}
                        containerStyle={{ width: '100%' }}
                        avatar={logo ?? nodeData?.image}
                        name={_name && _name.replace("Credential", "")}
                        profileDetails={_nodeDetails && _nodeDetails}
                    />
                    {
                        evidence && (
                            <View style={{ alignItems: 'center', justifyContent: 'center', top: -15, }}>
                                <IconButton icon={<Icon icon="attachment" />} color="red"
                                    circle style={{ alignItems: 'center', alignSelf: 'center' }}
                                    onClick={handleEvidenceClick.bind(null, evidence)}
                                />
                            </View>
                        )
                    }
                </>
            )
        }
        if (navItemKey === 'credential') {
            if (props?.resendCredentialLoading) {
                return <LogoSpinnerV imageStyle={{ marginLeft: '10%' }} />
            }
            else {
                let logo;
                let evidence;
                if (_nodeDetails && Array.isArray(_nodeDetails) && _nodeDetails.length > 0) {
                    _nodeDetails = _nodeDetails.filter(node => {
                        console.log('_node =>', node);
                        if (node.name &&
                            !String(node.name).toLowerCase().includes('image') &&
                            !String(node.name).toLowerCase().includes('logo')) {
                            if (String(node.name).toLowerCase().includes('evidence')) {
                                evidence = node.value;
                            }
                            return true;
                        }
                        logo = node.value;
                        return false;
                    })
                }
                return (
                    <>
                        <ProfileTemplateV
                            backgroundImage={'https://i.ibb.co/mFkJgxw/pattern-2.png'}
                            containerStyle={{ width: '100%' }}
                            avatar={logo ?? nodeData?.image}
                            name={_name && _name.replace("Credential", "")}
                            profileDetails={_nodeDetails && _nodeDetails}
                        />
                        {
                            evidence && (
                                <View style={{ alignItems: 'center', justifyContent: 'center', top: -15, }}>
                                    <IconButton
                                        icon={<Icon icon="attachment" />}
                                        color="red"
                                        circle
                                        style={{ alignItems: 'center', alignSelf: 'center' }}
                                        onClick={handleEvidenceClick.bind(null, evidence)}
                                    />
                                </View>
                            )
                        }
                        {_nodeDetails ? (
                            <ButtonV
                                buttonTitle={"Resend"}
                                color={"red"}
                                containerStyle={{ marginTop: 20 }}
                                buttonTitleStyle={{ color: '#fff', fontWeight: 'bold' }}
                                onPress={id => onResendPushData()}
                            // appearance={'ghost'}
                            />
                        ) : null}
                    </>
                )
            }
        }
        if (navItemKey === 'identity') {
            return (
                <ProfileTemplateV
                    backgroundImage={'https://i.ibb.co/mFkJgxw/pattern-2.png'}
                    containerStyle={{ width: '100%' }}
                    avatar={nodeData?.image}
                    name={_name && _name.substring(0, _lastIndex)}
                    profileDetails={_nodeDetails && _nodeDetails}
                />
            )
        }
        if (navItemKey === 'presentation') {
            if (props?.presentationDetailLoading) {
                return <LogoSpinnerV imageStyle={{ marginLeft: '10%' }} />
            }
            else {
                return (
                    <View >
                        <h4>{BP?.presentationTitle}</h4>
                        {Array.isArray(props?.presentationDetails) && props?.presentationDetails.map(item => {
                            return (
                                <div onClick={() => {
                                    setNodeData(item);
                                    setNavItemKey("presCredential");
                                }}
                                >
                                    <Panel shaded style={StyleSheet.flatten([styles.cardStyle])} >

                                        <View style={{ display: 'flex', flexDirection: 'row' }}>
                                            <AvatarV
                                                source={{ uri: item?.image ? item?.image : `https://ui-avatars.com/api/?name=${item?.title}` }}
                                                rounded
                                                containerStyle={{ margin: 5 }}
                                            />
                                            <View>
                                                <Text style={{ display: 'block' }}>{humanize(item?.title).split(/(?=[A-Z])/).join("").replace("Credential", "")}</Text>
                                            </View>
                                        </View>
                                    </Panel>
                                </div>
                            )
                        })}
                    </View>
                );
            }
        }
        else {
            console.log('inside else node deails ', _nodeDetails);

        }

    };
    const onNavItemSelect = (eventKey: string) => {
        console.log('navItem eventKey', eventKey)
        if (eventKey === 'filter') {
            let _drawer = drawer;
            _drawer.show = true;
            _drawer.title = "Apply Filters";
            setNavItemKey(eventKey);
            setShow(true);
            setDrawer({ ..._drawer });
        }
        else {
            props.navItemSelect && props.navItemSelect(eventKey)
        }

    }

    const handleOnDrawerHide = () => {
        let _drawer = { ...drawer };
        _drawer.show = false;
        setDrawer({
            ..._drawer,
        });
    };

    return (
        <>
            <BodyTemplateV
                primaryColor={theme?.palette.primary}
                primaryHighlightColor={theme?.palette.primaryDark}
                secondaryColor={theme?.palette.secondary}
                ternaryColor={theme?.palette.ternary}
                //headerLogo={'https://i.ibb.co/3N3q9Z4/vLogo.png'}
                dashboardContainerStyle={{ backgroundColor: '#fff' }}
                headerBackgroundColor={'#fff'}
                //headerTitle={'vlinder Labs Pvt. Ltd.'}
                renderContent={renderContent}
                contentScrollableInline={false}
                // headerTitleStyle={{marginLeft: 20}}
                navBarRightItems={BP?.navRightItems}
                navBarLeftItems={BP?.navLeftItems}
                onNavItemSelect={onNavItemSelect}
                navbarStyle={{}}
                layout={'sidebar-header-content-footer'}
                renderFooter={() => <div />}
            // sidebarFixedWidth={80}
            // renderCustomNavbarItem={renderCustomItem}
            />
            <DrawerV
                placement={drawer.placement}
                show={drawer.show}
                backdrop={drawer.backdrop}
                size={drawer.size}
                renderHeader={renderDrawerHeader}
                onHide={handleOnDrawerHide}
                drawerTitle={drawer.title}
                renderBody={renderDrawerBody}
                renderFooter={renderDrawerFooter}
            />
        </>
    );
};

export const TrustNetworkLG = (props: TrustNetworkProps) => {
    return (
        <ViewportProvider>
            <TrustNetworkInternal {...props} />
        </ViewportProvider>
    );
};
