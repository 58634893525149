import React from 'react';
import {useViewport} from 'react-viewport-provider';
import {SignupPageLG} from './SignupPage.lg';

interface Props {}

export const SignupPage = ({}: Props) => {
  const {bp} = useViewport();

  // if (bp === 'xs' || bp === 'sm') {
  //   return <div />;
  // }

  // if (bp === 'md') {
  //   return <div />;
  // }

  //bp === 'lg' || bp === 'xl' || bp === 'md
  return <SignupPageLG />;
};
