import React from 'react';
import { ForgotPasswordPage } from './components';

export const ForgotPassword = () => {

    const _renderBody = () => {
        return (
            <>
                <ForgotPasswordPage />
            </>
        )
    }

    return (
        <>
            {_renderBody()}
        </>
    )
}