import React, {useContext} from 'react';
import {View, StyleSheet, Text} from 'react-native';
import {QRCodeGeneratorV} from 'react-native-qr-code-generator-vfw';
import config from './config/default-blueprint.json';
import {useViewport} from 'react-viewport-provider';
import {ThemeContext} from '../../../../../../../providers/theme';
import {PanelV} from 'react-panel-rs';
import {FBGridV, FBItemV} from 'react-flexbox-grid-rs';
import {AvatarV} from 'react-avatar-mui';
import {IconV} from 'react-icon-rs';
import {TextV} from 'react-native-text-vfw';
import {IconButtonV} from 'react-button-rs';
import { ImageV } from 'react-native-image-vfw';

const BP = config.signScreen;

interface RegisterWalletProps {
  androidUrl?: string | URL;
  iOSUrl?: string | URL;
}

export const SignVerification = (props: RegisterWalletProps) => {
  const {height, width} = useViewport();
  const theme = useContext(ThemeContext);
  const pimaryColor = theme?.palette.primary;
  const styles = {
    containerStyle: {
      justifyContent: 'center',
      alignItems: 'flex-start',
      borderRadius: 10,
      margin: '5px auto',
      padding: width * 0.015,
    },
    rightHeaderTitleContainerStyle: {
      // justifyContent: 'center',
      // alignItems: 'center',
    },
    rightHeaderTitleStyle: {
      ...theme?.typography?.headline,
      textAlign: 'left',
      color: theme?.palette.primary,
      padding: 10,
    },
    iconContainerStyle: {},
    panelStyle: {
      justifyContent: 'center',
      alignItems: 'space-between',
      backgroundColor: '#fff',
      margin: '10px 5px',
    },
    credTitleStyle: {
      fontWeight: 500,
      paddingLeft: 10,
    },
    credPanelStyle: {
      display: 'flex',
      flex: 'grow',
      flexDirection: 'row',
      alignItems: 'center',
    },
    titleStyle: {
      display: 'block',
      color: theme?.palette.primary,
      fontWeight: 500,
      fontSize: width * 0.015,
      textAlign: 'left',
      padding: 10,
      position: 'relative',
      bottom: 10,
    },
    subTitleStyle: {
      color: theme?.palette.primary,
      display: 'block',
      fontSize: width * 0.012,
      textAlign: 'center',
      padding: 5,
    },
    qrStyle: {
      // border: '2px solid #ccc',
      margin: '5px auto',
      backgroundColor: '#dcdcdc',
      padding: 10,
      // alignItems: 'center',
    },
    outerQrStyle: {
      alignItems: 'center',
    },
    iconstyle: {
      color: theme?.palette.primary,
      borderRadius: 25,
      padding: 5,
      alignItems: 'center',
      margin: 10,
    },
    buttonsContianer:{
      display:'flex',
      flexDirection:'row',
      margin:10
    }
  };

  const _getBodyContent = () => {
    return (
      <>
        <View
          style={StyleSheet.flatten([styles.buttonsContianer])}
        >
          <div onClick={() => window.open(BP?.androidURL)} style={{cursor:'pointer'}}> 
            <ImageV 
             source={{uri:BP?.androidButton}}
             imageStyle={{width:140, height:50}}
            />
          </div>
          <div onClick={() => window.open(BP?.iosURL)} style={{cursor:'pointer'}}>
          <ImageV 
             source={{uri:BP?.iosButton}}
             imageStyle={{width:140, height:50}}
            />
          </div>         
        </View>
        <Text style={StyleSheet.flatten([styles.rightHeaderTitleStyle])}>
          <a href={"#"}>
          {BP.linkText}
          </a>
            
          </Text>
      </>
    );
  };

  return (
    <FBGridV
      justify={'center'}
      style={StyleSheet.flatten([styles.containerStyle])}
    >
      <FBItemV colspan={16}>
        <View style={StyleSheet.flatten([styles.outerQrStyle])}>
          <QRCodeGeneratorV
            containerStyle={StyleSheet.flatten([styles.qrStyle])}
            size={280}
          />
          {_getBodyContent()}
        </View>
      </FBItemV>
    </FBGridV>
  );
};
