import {useLazyQuery, useQuery, useMutation} from '@apollo/react-hooks';
import {useEffect} from 'react';
import {createNewDataPipeFrom, DataSet} from 'vis-data';
import {
  GET_HOLDERS_DATA,
  GET_CREDENTIAL_CATEGORY,
  GET_CREDENTIAL_TEMPLATE,
  GET_NETWORK_DATA,
  GET_OPERATOR_PROFILE,
  GET_ORGANIZATION_PROFILE,
  GET_ADMINS,
  GET_PRESENTATION_DETAIL
} from './queries';
import {INVITE_USER, ISSUE_CREDENTIAL, REQUEST_CREDENTIAL, RESEND_CREDENTIAL} from './mutation';
import BP from './config/default-blueprint.json';
import {CREATE_ACCOUNT} from '../Registration/mutation';
import {OrganisationProfile} from '../Registration/components/RegistrationPage/components/OrganisationProfile';

export const provHoldersDS = new DataSet({
  fieldId: '_id',
});

export const provIssuersDS = new DataSet({
  fieldId: 'key',
});

export const provVerifiersDS = new DataSet({
  fieldId: 'key',
});

export const provTemplateFormDataDS = new DataSet({
  fieldId: 'key',
});

export const provCredentialCategoriesDS = new DataSet({
  fieldId: '_id',
});

export const provHoldersTransformedDS = new DataSet({
  fieldId: '_id',
});

provHoldersTransformedDS.update(BP.searchUsers);

export const provIssuersTransformedDS = new DataSet({
  fieldId: 'key',
});

provIssuersTransformedDS.update(BP.issuersList);

export const provVerifiersTransformedDS = new DataSet({
  fieldId: 'key',
});

provVerifiersTransformedDS.update(BP.verifiersList);

export const provCredentialCategoriesTransformedDS = new DataSet({
  fieldId: '_id',
});

provCredentialCategoriesTransformedDS.update(BP?.credentialCategory);

/* Network Dataset */

export const provNetworkDS = new DataSet({
  fieldId: 'id',
});

export const provNetworkTransformedDS = new DataSet({
  fieldId: 'id',
});

provNetworkTransformedDS.update(BP?.networkData);

export const provOperatorDS = new DataSet({
  fieldId: 'id',
});

export const provOperatorTransformedDS = new DataSet({
  fieldId: 'id',
});

provOperatorTransformedDS.update(BP?.dashboard?.operatorProfile);

export const provOrganizationProfile = new DataSet({
  fieldId: 'id',
});

export const provOrganizationProfileTransformedDS = new DataSet({
  fieldId: 'id',
});

provOrganizationProfileTransformedDS.update(BP?.dashboard?.organizationProfile);

export const provAdminsDS = new DataSet({
  fieldId: 'id',
});

export const provAdminsTransformedDS = new DataSet({
  fieldId:'id',
});

export const provNetworkCredDS = new DataSet({
  fieldId: 'id',
});

export const provNetworkCredTransformedDS = new DataSet({
  fieldId:'id',
});

provNetworkCredTransformedDS.update(BP?.credentialView);

export const provNetworkPresentationDetails = new DataSet({
  fieldId: 'id',
})

export const useProvDashboard = () => {
  useEffect(() => {
    return function cleanup() {
      provHoldersDS.clear();
      provHoldersTransformedDS.clear();
      provCredentialCategoriesDS.clear();
      provCredentialCategoriesTransformedDS.clear();
      provAdminsDS.clear();
      provAdminsTransformedDS.clear();
    };
  }, []);

  const _getProvHoldersData = () => {
    const _holders = provHoldersDS.get();
    if (_holders) {
      return _holders;
    }
  };

  const _getIssuersList = () => {
    const _data = provHoldersDS.get();
    console.log('issuers get data', _data);
    let _issuers =
      Array.isArray(_data) &&
      _data.filter(item => {
        if (
          item?.features.includes('ISSUER_ADMIN') ||
          item?.features.includes('ISSUER')
        ) {
          return true;
        }
      });
    console.log('issuer after filter', _issuers);
    return _issuers;
  };

  const _getAdminUsers = () => {
    const _data = provAdminsDS.get();
    console.log('admins get data', _data);
    let _admins;
    if(_data){
      _admins = _data;
    }
    return _admins;
  }

  const _getVerifierList = () => {
    const _data = provHoldersDS.get();
    console.log('verifiers get data', _data);
    let _verifiers =
      Array.isArray(_data) &&
      _data.filter(item => {
        if (
          item?.features.includes('VERIFIER_ADMIN') ||
          item?.features.includes('VERIFIER')
        ) {
          return true;
        }
      });
    console.log('verifier after filter', _verifiers);
    return _verifiers;
  };

  const _getCredCategoryData = () => {
    const _data = provCredentialCategoriesTransformedDS.get(
      BP?.credentialCategory?._id,
    );
    console.log('_after transformation data', _data);
    let credCategories;
    if (_data && _data.data) {
      credCategories = _data.data;
    }
    return credCategories;
  };

  const _getNetworkData = () => {
    const _data = provNetworkTransformedDS.get(BP?.networkData?.id);
    console.log('after network transformation', _data);
    let _networkData;
    if (_data) {
      _networkData = _data;
    }
    return _networkData;
  };

  const _getOrgProfile = () => {
    const _data = provOrganizationProfileTransformedDS.get(
      BP?.dashboard?.organizationProfile?.id,
    );
    console.log('after org profile transformation', _data);
    let orgProfData;
    if (_data) {
      orgProfData = _data;
    }
    return orgProfData;
  };

  const _getOperatorProfile = () => {
    const _data = provOperatorTransformedDS.get(
      BP?.dashboard?.operatorProfile?.id,
    );
    console.log('after operator profile transformation', _data);
    let operProfileData;
    if (_data) {
      operProfileData = _data;
    }
    return operProfileData;
  };

  const credCategorypipeTransformation = () => {
    //@ts-ignore
    const pipe = createNewDataPipeFrom(provCredentialCategoriesDS)
      //@ts-ignore
      .map((item: any) => ({
        ...item,
      }))
      //@ts-ignore
      .to(provCredentialCategoriesTransformedDS);
    pipe.all().start();
  };

  const networkPipeTrasformation = () => {
    //@ts-ignore
    const pipe = createNewDataPipeFrom(provNetworkDS)
      //@ts-ignore
      .map((item: any) => ({
        ...item,
      }))
      //@ts-ignore
      .to(provNetworkTransformedDS);
    pipe.all().start();
  };

  const orgProfilePipeTransformation = () => {
    //@ts-ignore
    const pipe = createNewDataPipeFrom(provOrganizationProfile)
      //@ts-ignore
      .map((item: any) => ({
        ...item,
      }))
      //@ts-ignore
      .to(provOrganizationProfileTransformedDS);
    pipe.all().start();
  };

  const operProfilePipeTransformation = () => {
    //@ts-ignore
    const pipe = createNewDataPipeFrom(provOperatorDS)
      //@ts-ignore
      .map((item: any) => ({
        ...item,
      }))
      //@ts-ignore
      .to(provOperatorTransformedDS);
    pipe.all().start();
  };

  const [
    GetHolders,
    {
      data: getUserData,
      loading: getUserLoading,
      error: getUserError,
      called: getUserCalled,
    },
  ] = useLazyQuery(GET_HOLDERS_DATA, {fetchPolicy: `network-only`});

  if (getUserData && getUserData.searchUsers) {
    const _provHomeHolderData =
      getUserData.searchUsers && getUserData.searchUsers.all;
    console.log('_provHome before Transformation =>', _provHomeHolderData);
    provHoldersDS.update(_provHomeHolderData);
    // pipeTransformation();
  }

  const [
    CreateUser,
    {data: createUserData, loading: createUserLoading, error: createUserError},
  ] = useMutation(INVITE_USER);

  const [
    ResendCredential,
    {data: resendCredentialData, loading: resendCredentialLoading, error: resendCredentialError},
  ] = useMutation(RESEND_CREDENTIAL, {fetchPolicy: `no-cache`});

  const [
    CredentialCategory,
    {
      loading: credentialCategoryLoading,
      data: credentialCategoryData,
      error: credentialCategoryError,
    },
  ] = useLazyQuery(GET_CREDENTIAL_CATEGORY);

  const [
    FindTemplates,
    {
      loading: templateFormLoading,
      data: templateFormData,
      error: templateFormError,
    },
  ] = useLazyQuery(GET_CREDENTIAL_TEMPLATE);

  const [
    IssueCredential,
    {loading: issueCredLoading, data: issueCredData, error: issueCredError},
  ] = useMutation(ISSUE_CREDENTIAL);

  const [
    RequestCredentials,
    {loading: reqCredLoading, data: reqCredData, error: reqCredError},
  ] = useMutation(REQUEST_CREDENTIAL);

  const [
    getMyGraph,
    {loading: networkLoading, data: networkData, error: networkError},
  ] = useLazyQuery(GET_NETWORK_DATA, {fetchPolicy: `network-only`});

  const [
    getOrganizationProfile,
    {loading: orgProfileLoading, data: orgProfileData, error: orgProfileError},
  ] = useLazyQuery(GET_ORGANIZATION_PROFILE);

  const [getAdmins,
  {loading:getAdminsLoading, data:adminUsersData, error: getAdminsError}
  ] = useLazyQuery(GET_ADMINS, {fetchPolicy: `network-only`});

  const [
    getOperatorProfile,
    {
      loading: operatorProfileLoading,
      data: operatorProfileData,
      error: operatorProfileError,
    },
  ] = useLazyQuery(GET_OPERATOR_PROFILE);

  const [
    getPresentationDetials,
    {
      loading: presentationDetailLoading,
      data: presentationData,
      error:presentationError
    },
  ] = useLazyQuery(GET_PRESENTATION_DETAIL);

  if (templateFormData && templateFormData?.findTemplates) {
    provTemplateFormDataDS.clear();
    console.log('template form', templateFormData);
    const _formData = templateFormData?.findTemplates;
    provTemplateFormDataDS.update(_formData);
  }

  if (
    credentialCategoryData &&
    credentialCategoryData.searchCategoriesWATransformed
  ) {
    const _allCatgory = credentialCategoryData.searchCategoriesWATransformed;

    console.log('credentials after update =>', _allCatgory);
    provCredentialCategoriesDS.update(_allCatgory);
    credCategorypipeTransformation();
  }

  if(adminUsersData && adminUsersData?.searchUsers){
    console.log('')
    let _adminUsers = adminUsersData?.searchUsers?.all;
    provAdminsDS.update(_adminUsers);
  }

  if (networkData) {
    console.log('before transformation networkData', networkData?.getMyGraph);
    const _network = networkData?.getMyGraph;
    provNetworkDS.update(_network);
    networkPipeTrasformation();
  }

  if (orgProfileData) {
    console.log('after query org profileData', orgProfileData);
    const _orgProfileData = orgProfileData?.getMyOrganizationTransformedWeb;
    provOrganizationProfile.update(_orgProfileData);
    orgProfilePipeTransformation();
  }

  if (operatorProfileData && operatorProfileData) {
    console.log('after query operator profileData', operatorProfileData);
    const _operProfileData =
      operatorProfileData?.getNetworkOrganizationTransformedWeb;
    provOperatorDS.update(_operProfileData);
    operProfilePipeTransformation();
  }

  if(presentationData){
    console.log('presentation detail query', presentationData);
    const _presentationData = presentationData?.getVerifiablePresentationDetails;
    provNetworkPresentationDetails.update(_presentationData);
  }

  const holdersData = _getProvHoldersData();
  const credCategoryData = _getCredCategoryData();
  const trustNetworkData = _getNetworkData();
  const orgProfData = _getOrgProfile();
  const operatorProfData = _getOperatorProfile();
  const issuersList = _getIssuersList();
  const verifierList = _getVerifierList();
  const adminUsers = _getAdminUsers();

  return {
    getUserLoading,
    getUserError,
    holdersData,
    createUserData,
    createUserError,
    createUserLoading,
    CreateUser,
    CredentialCategory,
    credCategoryData,
    credentialCategoryLoading,
    credentialCategoryError,
    FindTemplates,
    templateFormLoading,
    templateFormError,
    GetHolders,
    provTemplateFormData: provTemplateFormDataDS.get(),
    IssueCredential,
    issueCredData,
    issueCredError,
    issueCredLoading,
    getMyGraph,
    trustNetworkData,
    networkLoading,
    RequestCredentials,
    reqCredLoading,
    reqCredError,
    reqCredData,
    getOrganizationProfile,
    getOperatorProfile,
    orgProfData,
    operatorProfData,
    issuersList,
    verifierList,
    getAdmins,
    adminUsers,
    getAdminsLoading,
    getAdminsError,
    netwrokCredData: provNetworkCredTransformedDS.get(BP?.credentialView?.id),
    getPresentationDetials,
    presentationDetail: provNetworkPresentationDetails.get(),
    presentationDetailLoading,
    ResendCredential,
    resendCredentialData,
    resendCredentialLoading
  };
};
