import React from 'react';
import { useViewport } from 'react-viewport-provider';
import { CredentialViewLG } from './CredentialView.lg';

export interface IProps {
    onSearchClick?(): void;
    onCardClick?(eventKey?: string): void;
    netwrokCredData?:any;
    navItemSelect?(eventKey?: string): void;
}

export const CredentialView = (props: IProps) => {
    const { bp } = useViewport();

    // if (bp === 'xs' || bp === 'sm') {
    //     return <DashboardSM />;
    // }

    // if (bp === 'md') {
    //   return <DashboardMD />;
    // }

    //bp === 'lg' || bp === 'xl' || bp === 'md
    return <CredentialViewLG {...props} />;
};
