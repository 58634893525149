import gql from 'graphql-tag';

export const GET_HOLDERS_DATA = gql`
  query getUserData {
    searchUsers {
      all {
        email
        firstName
        lastName
        fullName
        did
        emailVerified
        accessForbidden
        organization
        image
        userActiveStatus
        features
        accessForbidden
        isWalletRegistered
        organizationFull{
          name
          logo
          phoneNo
          website
          parentOrgType
          OrgType
        }
      }
    }
  }
`;

export const GET_CREDENTIAL_CATEGORY = gql`
 query{
  searchCategoriesWATransformed(query:{}){
    _id
    data{
    subtitle1
    icon{
      imageURI
      imgWidth
      imgHeight
    }
    templateIdentifiers
    name
    title
    subtile2
  }
  }
}`

export const GET_CREDENTIAL_TEMPLATE = gql`
query FindTemplates($templateKeys: [String]!){
  findTemplates(templateKeys:$templateKeys){
    name
    templateKey
    description
    category
    dataProperties{
      key
      label
      placeholder
      listValues
      valType
      schemaRule
      isMandatory
    }
  }
}
`
export const GET_NETWORK_DATA = gql`
query getMyGraph($offset:Int){
  getMyGraph(
    query: {
      isOrganization: true
      decoded: true
      args:{
        criteria:{type:["VerifiableCredential","VerifiablePresentation"]}
        offset:$offset
        limit:12
      }
    }
  ) {
    id
    actualEdgecount
    graphs{
      nodes{
      nodeType
      edgeTag
      id
      title
      image
      toDID
      fromDID
    }
    edges{
      from
      to
      id
      label
    }
    nodeDetails{
      id
      title
      image
      details{
        key
        value
      }
    }
    }
  }
}
`;
export const GET_OPERATOR_PROFILE = gql`
    query{
      getNetworkOrganizationTransformedWeb{
        id
        leftAvatar
        headerButtonTitle
        title
        subtitle
        profileDetails{
          title
          tooltip
          subtitle1
          subtitle2
          type
          icon{
            name
            size
          }
        }
      }
    }
`;

export const GET_ORGANIZATION_PROFILE = gql`
query{
  getMyOrganizationTransformedWeb{
    id
    leftAvatar
    headerButtonTitle
    title
    subtitle
    profileDetails{
      title
      tooltip
      subtitle1
      subtitle2
      type
      icon{
        name
        size
      }
    }
  }
  }
`;

export const GET_ADMINS = gql`
query getAdmins{
  searchUsers(query:{criteria:{isFromMyOrganization:true}}){
    all{
      email
       firstName
        lastName
        fullName
        did
        emailVerified
        accessForbidden
        organization
        image
        userActiveStatus
        features
        accessForbidden
        isWalletRegistered
        organizationFull{
          name
          image
          phoneNo
          website
          parentOrgType
          OrgType
        }
    }
    count
  }
}
`;

export const GET_PRESENTATION_DETAIL = gql`
query getPresentationDetials($authToken:String, $isOrganization:Boolean, $edgeTag:String!){
    getVerifiablePresentationDetails(
      authToken:$authToken
      isOrganization: $isOrganization
      edgeTag: $edgeTag
    ){
      id
      title
      image
      details{
        key
        value
      }
    }
}
`;