import React from 'react';
import { useViewport } from 'react-viewport-provider';
import { VerifierListLG } from './Verifiers.lg';

export interface IProps {
    onSearchClick?(): void;
    onCardClick?(eventKey?: string): void;
    drawerWidth: number;
    verifiersList?: any[];
    onInviteVerifier?(formData?:any):void;
    getUsersLoading?:boolean;
}

export const VerifiersList = (props: IProps) => {
    const { bp } = useViewport();

    // if (bp === 'xs' || bp === 'sm') {
    //     return <DashboardSM />;
    // }

    // if (bp === 'md') {
    //   return <DashboardMD />;
    // }

    //bp === 'lg' || bp === 'xl' || bp === 'md
    return <VerifierListLG {...props} />;
};
