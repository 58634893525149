//@ts-nocheck
import React, { useState, useEffect, useContext } from 'react';
import { View, StyleSheet } from 'react-native';
import config from './config/default-blueprint.json';
import { EventBus } from 'event-bus-vfs';
import moment from 'moment';
import { useViewport, ViewportProvider } from 'react-viewport-provider';
import { ThemeContext } from '../../../../../../../../providers/theme';
import { BodyTemplateV } from 'react-body-template-1';
import { FormV, SENDER_ID, defaultValuesDS, FORM_VALUES } from 'react-form-rs';
import { StepTemplateV } from 'react-step-rs-template';
import { SelectInputV } from 'react-select-input-rs';
import { humanize } from 'uno-js';
import IconButton from 'rsuite/lib/IconButton';
import Icon from 'rsuite/lib/Icon';
import Alert from 'rsuite/lib/Alert';
import { beforeToday, afterToday, before } from 'rsuite/lib/DateRangePicker';
import { ProfileTemplateV } from 'react-profile-template-rs';
import { LogoSpinnerV } from 'react-logo-spinner-ext';
import { empty } from 'apollo-link';
import serverConfig from '../../../../../../../../config/default.config.json';

type IssueCredentailProps = {
    drawerWidth: number;
    credName?: string;
    formData?: any[];
    holdersList?: any[];
    onIssueCredential?(issueData?: any): any;
    issueCredentialSuccess?: any;
    issueCredLoading?: boolean;
};
const BP = config.IssueCredential;
const { imageUploadURL } = serverConfig?.servers;
let formContext = {};
let count = 0;
let _formValues = {};

const handleEvidenceClick = (evidence: string) => {
    evidence && window.open(evidence, "_blank");
}

const filtered_keys = function (obj, filter) {
    var key, keys = [];
    let _found: any;
    for (key in obj) {
        if (obj.hasOwnProperty(key) && obj[key] && filter.test(key)) {
            _found = {};
            _found.key = key;
            _found.value = obj[key];
            break;
        }
    }
    return _found;
}

const IssueCredentialInternal = (props: IssueCredentailProps) => {
    const parentRef = React.useRef(() => { });
    const [enable, setEnable] = useState(true);
    const [step, setStep] = useState<number>(0);
    const [userData, setUserData] = useState([]);
    const [credentialdata, setCredentialdata] = useState<any[]>([]);
    const { height, width } = useViewport();
    const theme = useContext(ThemeContext);
    const [validateForm, setValidateForm] = useState<boolean>(false);
    const [courseName, setCourseName] = useState('');
    const [courseDate, setCourseDate] = useState('');
    const [issuerLogo, setIssuerLogo] = useState<string>();
    const [issuer, setIssuer] = useState<string>();

    useEffect(() => {
        const app = EventBus.on(FORM_VALUES, value => {
            formContext = { ...value };
        });
        return function () {
            app.off();
        };
    }, []);

    const styles = {
        bodyContainerStyle: {
            backgroundColor: '#fff',
            justifyContent: 'center',
            margin: '0 auto',
        },
        containerStyle: {
            width: '100%',
            alignItems: 'center',
            display: 'inline-block',
            paddingLeft: 30,
            paddingTop: 10,
        },
        priewContainerStyle: {
            width: '70%',
            alignItems: 'center',
            display: 'inline-block',
            paddingLeft: 30,
            paddingTop: 10,
        },
        priewStyle: { margin: '0 auto', width: 600, padding: 5 },
        navBarStyle: {
            backgroundColor: '#f6f6f6',
            width: 100,
        },
        logoStyle: {
            width: 120,
        },
        headingStyle: {
            ...theme?.typography.certificateTitle,
        },
        formStyle: {
            width: '40%',
            backgroundColor: '#ffffff',
            justifyContent: 'flex-start',
            alignItems: 'start',
            borderRadius: 10,
        },
        signatureStyle: {
            width: 100,
        },
        stepsStyle: {
            //width: width - props.drawerWidth + 70,
        },
        skoolContainerStyle: { width: 600, height: 464, margin: 5 },
        imageBackgroundStyle: {
            backgroundColor: '#fff',
            padding: '4%',
            margin: '0 auto',
            justifyContent: 'center',
        },
        footerTextStyle: {
            ...theme?.typography?.certificateBody,
            textAlign: 'left',
            fontSize: 12,
        },
        bodyTextStyle: {
            ...theme?.typography?.certificateBody,
            fontSize: 16,
            lineHeight: 18,
        },
        qrTextStyle: {
            ...theme?.typography?.certificateBody,
            fontSize: 12,
        },
        gridStyle: {
            justifyContent: 'space-between',
            alignSelf: 'center',
        },
        headerGridStyle: {
            width: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignSelf: 'center',
            backgroundColor: '#3498FF',
            borderBottom: '2px solid #f1f1f1',
        },
        labelStyle: {
            display: 'block',
            padding: 10,
            fontSize: 14,
            fontWeight: 500
        },
        valueStyle: {
            padding: 10,
            fontSize: 14,
        },
        tableHeadingStyle: {
            fontSize: 28,
            fontWeight: 500,
            color: '#fff',
            margin: '0 auto',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 5
        },
        linkContainerStyle: {
            position: 'fixed',
            bottom: '30%',
            padding: 10,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignSelf: 'center'
        }
    };

    const renderContent = (key: string) => {
        console.info(`render content key ${props.issueCredLoading}`);
        return _getData(key);

    };

    const handleValidateResult = (
        formContext?: any,
        status?: 'success' | 'error',
        validationResult: any) => {
        // console.log('validate result', defaultValuesDS.get());
        if (status === 'success' && step === 1 && defaultValuesDS.get().length !== 0) {
            console.log('validate result', defaultValuesDS.get());
            let validFrom = defaultValuesDS.get('validFrom') && defaultValuesDS.get('validFrom').defaultValue;
            let validUntil = defaultValuesDS.get('validUntil') && defaultValuesDS.get('validUntil').defaultValue;
            if (validUntil?.getTime() < validFrom?.getTime()) {
                Alert.warning("Valid until should be more than valid from date");
            }
            else {
                let _credValues = Array.isArray(defaultValuesDS.get()) && defaultValuesDS.get().map(item => {
                    return ({
                        key: item.key,
                        value: item.defaultValue
                    })
                });
                // if (!defaultValuesDS.get('logo')) {

                // }
                setCredentialdata(_credValues);
                setEnable(true);
                setStep(step + 1);
            }

        }
    };

    const onUserChange = (value: any) => {
        console.log('userSelected', value);
        if (value === null || value === undefined) {
            setEnable(false);
        }
        setUserData(value);
    };

    const onIssueCredential = () => {
        console.log(`credentials data`, credentialdata);
        const logoIndex = credentialdata.findIndex(item => item.key == 'logo');
        console.log('logo values', logoIndex);

        if (logoIndex < 0 && issuerLogo) {
            credentialdata.push({
                key: 'logo',
                value: issuerLogo
            });
        }
        if (logoIndex >= 0 && !credentialdata[logoIndex]?.value) {
            credentialdata[logoIndex] = {
                key: 'logo',
                value: issuerLogo
            }
        }

        const issuerIndex = credentialdata.findIndex(item => item === 'issuer');
        console.log('issuer', issuerIndex);
        if (issuerIndex < 0 && issuer) {
            credentialdata.push({
                key: 'issuer',
                value: issuer
            })
        }
        if (issuerIndex >= 0 && !credentialdata[issuerIndex]?.value) {
            credentialdata[issuerIndex] = {
                key: 'issuer',
                value: issuer
            }
        }
        let _issueData = {
            category: props?.formData?.category,
            holderEmail: userData,
            credentialKeyVals: credentialdata,
            credentialTemplateKey: props?.formData?.templateKey,
            credentialLogo: props.formData?.icon,
            issuerName: props.formData?.issuer
        }
        props.onIssueCredential && props.onIssueCredential(_issueData);
    }

    const _getData = (key: string) => {
        console.log('formData', props.formData);
        let formData = Array.isArray(props.formData?.dataProperties) && props.formData?.dataProperties.map(item => {
            if (item.valType === "Input") {
                if (item?.key === 'issuer') {
                    setIssuer(item?.placeholder)
                }
                return (
                    {
                        component: "input",
                        schemaType: item?.schemaRule,
                        defaultValue: "",
                        value: item?.placeholder,
                        componentProps: {
                            containerStyle: {
                                width: "100%",
                                margin: 10
                            },
                            placeholder: item?.placeholder,
                            inline: false,
                            inside: false,
                            appearance: "liner",
                            color: "green",
                            mandatory: item?.isMandatory,
                        },
                        titleStyle: {
                            color: "#909090",
                            fontWeight: "bold"
                        },

                        key: item?.key,
                        label: item?.label
                    }
                )
            }
            if (item?.valType === "Date" && item?.key === "validFrom") {
                return ({
                    component: "datepicker",
                    defaultValue: "",
                    schemaType: item?.schemaRule,
                    componentProps: {
                        format: item?.format ? item?.format : "YYYY-MM-DD HH:mm:ss",
                        containerStyle: {
                            width: "100%",
                            margin: 10
                        },
                        datePickerStyle: {
                            width: "100%"
                        },
                        placeholder: item?.placeholder,
                        titleInline: false,
                        inside: false,
                        oneTap: true,
                        errorMessage: "date required",
                        disabledDate: beforeToday(),
                        mandatory: item?.isMandatory,
                    },
                    titleStyle: {
                        color: "#909090",
                        fontWeight: "bold"
                    },
                    key: item?.key,
                    label: item?.label
                })
            }
            if (item?.valType === "Date" && item?.key === "validUntil") {
                return ({
                    component: "datepicker",
                    defaultValue: "",
                    schemaType: item?.schemaRule,
                    componentProps: {
                        format: item?.format ? item?.format : "YYYY-MM-DD HH:mm:ss",
                        containerStyle: {
                            width: "100%",
                            margin: 10
                        },
                        datePickerStyle: {
                            width: "100%"
                        },
                        placeholder: item?.placeholder,
                        titleInline: false,
                        inside: false,
                        oneTap: true,
                        errorMessage: "date required",
                        disabledDate: before(defaultValuesDS.get('validFrom')?.defaultValue),
                        mandatory: item?.isMandatory,
                    },
                    titleStyle: {
                        color: "#909090",
                        fontWeight: "bold"
                    },
                    key: item?.key,
                    label: item?.label
                })
            }
            if (item?.valType === "Date" && item?.key === "dob") {
                return ({
                    component: "datepicker",
                    defaultValue: "",
                    schemaType: item?.schemaRule,
                    componentProps: {
                        format: item?.format ? item?.format : "YYYY-MM-DD HH:mm:ss",
                        containerStyle: {
                            width: "100%",
                            margin: 10
                        },
                        datePickerStyle: {
                            width: "100%"
                        },
                        placeholder: item?.placeholder,
                        titleInline: false,
                        inside: false,
                        oneTap: true,
                        errorMessage: "date required",
                        disabledDate: afterToday(),
                        mandatory: item?.isMandatory,
                    },
                    titleStyle: {
                        color: "#909090",
                        fontWeight: "bold"
                    },
                    key: item?.key,
                    label: item?.label
                })
            }
            if (item?.valType === "Date") {
                return ({
                    component: "datepicker",
                    defaultValue: "",
                    schemaType: item?.schemaRule,
                    componentProps: {
                        format: item?.format ? item?.format : "YYYY-MM-DD HH:mm:ss",
                        containerStyle: {
                            width: "100%",
                            margin: 10
                        },
                        datePickerStyle: {
                            width: "100%"
                        },
                        placeholder: item?.placeholder,
                        titleInline: false,
                        inside: false,
                        oneTap: true,
                        errorMessage: "date required",
                        mandatory: item?.isMandatory,
                    },
                    titleStyle: {
                        color: "#909090",
                        fontWeight: "bold"
                    },
                    key: item?.key,
                    label: item?.label
                })
            }
            if (item?.valType === "Upload") {
                if (item && item.key === 'logo') {
                    setIssuerLogo(item?.placeholder)
                }
                return ({
                    component: "uploader",
                    schemaType: item?.schemaRule,
                    defaultValue: item?.placeholder,
                    // value:item?.placeholder,
                    uploadProps: {
                        action: imageUploadURL,
                        containerStyle: { "width": "100%" },
                        listType: "picture",
                        avatarButtonStyles: true,
                        title: item?.label,
                        titleStyle: {
                            color: "#909090",
                            fontWeight: "bold"
                        }
                    },
                    key: item?.key,
                    label: item?.label
                })
            }
            else {
                return {

                }
            }
        });

        let _userList = Array.isArray(props.holdersList) && props.holdersList.filter(item => {
            if (item.isWalletRegistered === true) {
                return true;
            }
        })

        let userList = Array.isArray(_userList) && _userList.map(item => {
            return ({
                key: item.key,
                label: item.fullName,
                value: item.email,
                // email:item.email,
                // avatar:item?.image ? item?.image : `https://ui-avatars.com/api/?name=${item?.fullName}`
            })
        });

        console.log('get data key1', props.issueCredLoading);
        switch (key) {
            case 'selectUser':
                return (
                    <div style={StyleSheet.flatten([styles.containerStyle])}>
                        <SelectInputV
                            data={userList || BP?.selectUser}
                            selectInputStyle={{ width: 350 }}
                            inline={false}
                            onChange={value => onUserChange(value)}
                        />
                    </div>
                );
            case 'addDetails':
                return (
                    <div style={StyleSheet.flatten([styles.containerStyle])}>
                        <FormV
                            components={formData}
                            formContext={formContext}
                            ref={parentRef}
                            containerStyle={StyleSheet.flatten([styles.formStyle])}
                            validateForm={validateForm}
                            onValidateResult={handleValidateResult}
                            defaultValues={defaultValuesDS.get()}
                            ref={parentRef}
                        />
                    </div>
                );
            case 'previewScreen':
                let logo: string;
                if (credentialdata && Array.isArray(credentialdata) && credentialdata.length > 0) {
                    for (let i = 0; i < credentialdata.length; ++i) {
                        let _cred = credentialdata[i];
                        if (_cred.key && String(_cred.key).toLowerCase().includes('image')) {
                            logo = _cred.value;
                            break;
                        }
                        if (_cred.key && String(_cred.key).toLowerCase().includes('logo')) {
                            logo = _cred.value;
                            break;
                        }
                    }
                }
                if (!logo) {
                    logo = issuerLogo ? issuerLogo : `https://ui-avatars.com/api/?name=${props.formData?.name}`;
                }

                let _credentialData =
                    Array.isArray(credentialdata) &&
                    credentialdata.map(item => {
                        if (item.key === 'dob') {
                            return ({
                                name: humanize(item?.key.toString()),
                                value: moment(item?.value).format("ddd DD MMM YYYY"),
                            })
                        }
                        else if (item.key === 'validFrom' || item.key === 'validUntil') {
                            return ({
                                name: humanize(item?.key.toString()),
                                value: moment(item?.value).format("ddd DD MMM YYYY HH:MM:SS"),
                            })
                        }
                        else {
                            return ({
                                name: humanize(item?.key.toString()),
                                value: humanize(item?.value.toString()),

                            })
                        }

                    });
                if (props?.issueCredLoading) {
                    return <LogoSpinnerV imageStyle={{ left: '50%' }} />
                }
                else {
                    let evidence;
                    _credentialData = _credentialData.filter(credential => {
                        if (credential.name &&
                            !String(credential.name).toLowerCase().includes('image') &&
                            !String(credential.name).toLowerCase().includes('logo')) {

                            if (String(credential.name).toLowerCase().includes('evidence')) {
                                evidence = credential.value;
                                return false;
                            }
                            return true;
                        }
                        return false;
                    })
                    return (
                        <div style={{ alignSelf: 'center' }}>
                            <ProfileTemplateV
                                avatar={logo}
                                profileDetails={_credentialData}
                                name={props.formData?.name}
                                containerStyle={StyleSheet.flatten([styles.priewStyle])}
                            />
                            {
                                evidence && (
                                    <View style={{ alignItems: 'center', justifyContent: 'center', top: -15, }}>
                                        <IconButton icon={<Icon icon="attachment" />} color="red" circle style={{ alignItems: 'center', alignSelf: 'center' }}
                                            onClick={handleEvidenceClick.bind(null, evidence)} />
                                    </View>
                                )
                            }
                        </div>

                    );
                }

            // case 'issueCredential':
            //     return <SignVerification />;
            default:
                return null;
        }
    };

    const _handleStepperNext = (currentStep: any, currentKey: string) => {
        console.log('inside next button click', currentKey);
        if (userData !== null && userData !== undefined && currentStep === 0) {
            setStep(step + 1);
            // setValidateForm({...formContext});
        }
        else if (currentStep === 1 && currentKey === 'addDetails') {
            setValidateForm({ ...formContext });
            count++;
        }
        else if (currentStep === 2 && currentKey === 'previewScreen') {
            setEnable(true);
        }
        // else {
        //     setStep(step + 1);
        //     setEnable(true);
        // }
    };

    const bodyContent = () => {
        return (
            <View style={StyleSheet.flatten([styles.stepsStyle])}>
                <StepTemplateV
                    width={'100%'}
                    height={'90vh'}
                    step={step}
                    bordered
                    shaded
                    items={BP?.steps}
                    renderStepperContent={renderContent}
                    stepIconOnly={true}
                    stepperColSpan={1}
                    headerTitleToDisplay={true}
                    stepperBackgroundColor={BP?.primaryColor || theme?.palette.secondary}
                    stepperIconActiveColor={theme?.palette.secondary}
                    onNext={_handleStepperNext}
                    onPrev={_handleStepperPrev}
                    loading={false}
                    stepperPrevTitle={'Previous'}
                    stepperNextTitle={'Next'}
                    enableNextonCurrentStep={enable}
                    enablePrevonCurrentStep={enable}
                    onStepperDonePress={onIssueCredential}
                />
            </View>
        );
    };

    const _handleStepperPrev = (currentStep: any, currentKey: string) => {
        console.log('prev button click', currentStep);

        if (currentStep === 2) {
            defaultValuesDS.clear();
            setStep(step - 1);
        }
        setStep(step - 1);
    };
    return (
        <>
            <BodyTemplateV
                primaryColor={BP?.primaryColor || theme?.palette.primary}
                primaryHighlightColor={
                    BP?.primaryHighlightColor || theme?.palette.secondary
                }
                secondaryColor={BP?.secondaryColor || theme?.palette.secondary}
                ternaryColor={BP?.ternaryColor || theme?.palette.ternary}
                renderContent={bodyContent}
                contentScrollableInline={true}
                headerTitleStyle={{ marginLeft: 20 }}
                navbarStyle={{}}
                layout={'sidebar-header-content-footer'}
                // onNavItemSelect={eventKey => setNavItem(eventKey)}
                // sideBarItems={BP?.nav_items}
                // onSideNavItemSelect={eventKey => setNavItem(eventKey)}
                renderFooter={() => <div />}
            />
        </>
    );
};

export const IssueCredential = (props: IssueCredentailProps) => {
    return (
        <ViewportProvider>
            <IssueCredentialInternal {...props} />
        </ViewportProvider>
    );
};
