//@ts-nocheck
import React, {ReactNode} from 'react';
import {StyleSheet} from 'react-native';
import Graph from 'react-graph-vis';
import 'vis-network/dist/vis-network.min.css';

export type OptionsProps = {
  autoResize?: boolean;
  width?: string;
  height?: string;
  locale?: string;
  locales?: any;
  clickToUse?: boolean;
  configure?: any;
  edges?: any;
  nodes?: any;
  groups?: any;
  layout?: any;
  interaction?: any;
  manipulation?: any;
  physics?: any;
  click?(event: any): void;
  doubleClick?(event: any): void;
  oncontext?(event: any): void;
  hold?(event: any): void;
  release?(event: any): void;
  select?(event: any): void;
  selectNode?(event: any): void;
  selectEdge?(event: any): void;
  deselectNode?(event: any): void;
  deselectEdge?(event: any): void;
  dragStart?(event: any): void;
  dragging?(event: any): void;
  dragEnd?(event: any): void;
  hoverNode?({node: any}): void;
  blurNode?({node: any}): void;
  hoverEdge?({edge: any}): void;
  blurEdge?({edge: any}): void;
};

export type GraphProps = {
  nodes: {
    id: string | number;
    label?: string;
    title?: string;
    image: string;
  }[];
  edges: {
    from: string | number;
    to: string | number;
    title?: string;
    label?: string;
    id?: string | number;
  }[];
};

export type WidgetProps = {
  options: OptionsProps;
  graph: GraphProps;
  events?: any;
  style?: any;
};

export const NetworkGraphV = (props: WidgetProps) => {
  return (
    <Graph
      graph={props.graph}
      options={props.options}
      events={props.events}
      style={props.style}
    />
  );
};
