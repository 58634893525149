import React, { useContext } from 'react';
import { LoginPageV } from 'react-login-template-2';
import { ThemeContext } from '../../../providers/theme';
import blueprint from '../config/default-blueprint.json';
import { useViewport } from 'react-viewport-provider';
import { useHistory, useLocation, Prompt } from 'react-router-dom';
import { EventBus } from 'event-bus-vfs';
import { FORM_VALUES, defaultValuesDS } from 'react-form-rs';
import { IProps } from './index';
import Alert from 'rsuite/lib/Alert';

let formContext = {};
const BP = blueprint.LoginPage;

export const LoginPageLG = (props: IProps) => {
  const theme = useContext(ThemeContext);
  const { width, height } = useViewport();
  const history = useHistory();
  const [date, setDate] = React.useState<Date>(new Date());
  const [validateForm, setValidateForm] = React.useState<any>(null);
  React.useEffect(() => {
    const app = EventBus.on(FORM_VALUES, value => {
      formContext = { ...value };
    });
    return function () {
      app.off();
    };
  }, []);

  const handleValidateResult = (
    formContext: any,
    status: 'success' | 'error',
    validateResult: any,
  ) => {
    //console.log('defaultValuesDS', defaultValuesDS.get());
    if (status === 'success') {
    //  console.log('login data collected successful');
      props.onLogin &&
        props.onLogin(
          defaultValuesDS.get('emailAddress')?.defaultValue,
          defaultValuesDS.get('password')?.defaultValue,
          defaultValuesDS.get('rememberMe')?.defaultValue,
        );
      defaultValuesDS.clear();
    }
    if (status === 'error') {
      BP.loginFFEC && Alert.error(BP.loginFFEC, 10000);
    }
    setDate(new Date());
    setValidateForm(false);
  };

  const onForgotPasswordClick = () => {
    history.push('/forgotpassword');
  };

  return (
    <LoginPageV
      containerStyle={{ width: 1 * width, height: 1 * height }}
      title={BP.title}
      leftAvatar={BP.leftAvatar}
      loginButtonTitle={BP.loginButtonTitle}
      loginButtonColor={BP.loginButtonColor}
      signupButtonColor={theme?.palette.secondary}
      bodyTitle={BP.bodyTitle}
      formFields={BP.formFields}
      formContext={formContext}
      forgotPasswordText={"Forgot Password ?"}
      validateForm={validateForm}
      defaultValues={defaultValuesDS.get()}
      onHeaderLogoPress={() => alert('handle navigation')}
      onValidateResult={handleValidateResult}
      onGetStarted={() => {
       // console.log('get Started');
        setValidateForm({
          ...formContext,
        });
      }}
      waveColor={theme?.palette.secondary}
      qrValue={BP.qrValue}
      qrCodeSize={BP.qrCodeSize}
      qrcodeDescription={BP.qrcodeDescription}
      onForgotPasswordClick={onForgotPasswordClick}
    />
  );
};
