import {ConsoleLogger, LoggerService} from 'logger-extension-vfs';
import React, {useEffect, useState} from 'react';
import {pretty} from 'react-native-helpers';
import {RegistrationPage} from './components';
import {LogoSpinnerV} from 'react-logo-spinner-ext';
import {useViewport} from 'react-viewport-provider';
import Alert from 'rsuite/lib/Alert';
import {useMutation} from '@apollo/react-hooks';
import {useHistory, useLocation, Prompt} from 'react-router-dom';
import {VERIFY_EMAIL} from './mutation';
import {DataSet, DataView} from 'vis-data';
import blueprint from './config/default-blueprint.json';
import {useProvRegistration} from './provider';
import {useAuth} from '../../services/auth-service/auth.provider';

const BPR = blueprint.RegistrationPage;
const test = new DataSet();

/**
 * Dataset and DataView
 */
export const SENDER_ID = 'Registration:RegistationPage';
export const registrationItemsDS = new DataSet({
  fieldId: 'key',
});


export const registrationItemsDV = new DataView(registrationItemsDS);

const clearDataSet = () => {
  registrationItemsDS && registrationItemsDS.clear();
}

//--------------------------------------------------------

// const console: LoggerService = new LoggerService(
//   new ConsoleLogger('Registration:container'),
// );

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function isQueryPresent(query?: any) {
  return query && query.get('email') && query.get('token');
}

export const Registration = () => {
  const[loginPassword, setLoginPassword] = useState<string>('');
  const {signIn} = useAuth();
  const {VerifyEmail, CreateAccount, verifyEmailData, verifyEmailLoading, verifyEmailError, stepItems, 
    createAccountLoading, createAccountData, createAccountError, userType,
    LoginUser, loginUserData, loginUserLoading, loginUserError,
    CreateOrg, createOrgData, createOrgLoading, createOrgError, 
  } = useProvRegistration();
  registrationItemsDS.update(stepItems, SENDER_ID);

  const _renderBody = () => {    
    const history = useHistory();
    const query = useQuery();  
    
    useEffect(() => {
      if (query.get('email') && query.get('token')) {
        VerifyEmail({
          variables: {
            credential: {
              email: query.get('email'),
              token: query.get('token'),
            },
          },
        });
        var uri = window.location.toString();
        if (uri.indexOf('?') > 0) {
          var clean_uri = uri.substring(0, uri.indexOf('?'));
          window.history.replaceState({}, document.title, clean_uri);
        }
      }
    }, []);
  
    useEffect(() => {
      if (createAccountData) {
        console.info(`account create, ${pretty(createAccountData)}`); 
        const isOrganization = (Array.isArray(userType) && userType.includes("ISSUER_ADMIN"))||
        (Array.isArray(userType) && userType.includes("VERIFIER_ADMIN")) ||
        (Array.isArray(userType) && userType.includes("NETWORK_OPERATOR_ADMIN"));
        if(!isOrganization){
          history.push('/login');
          Alert.success("Registration Successful please login", 5000)
        }
        else {
          LoginUser({
            variables: {
              credential: {
                email: verifyEmailData?.verifyEmail?.email,
                password: loginPassword,
              },
            },
          }); 
        }
      }
      return function cleanup() {
        clearDataSet();
      };
    }, [createAccountData]);

    useEffect(()=>{
      if (verifyEmailData?.verifyEmail?.userStatus === 'REGISTERED') {
        history.push('/login');
        Alert.error('Account Already Created, please login', 10000);
      }
      else if (verifyEmailData?.verifyEmail?.userStatus === 'ORG_CREATED') {
        history.push('/login');
        Alert.error('Account Already Created, please login', 10000);
      }
      else if(verifyEmailData) {
        Alert.success(`${verifyEmailData?.verifyEmail?.email} successfully verified`, 10000);
      }
    }, [verifyEmailData]);

    useEffect(()=>{
      if(createOrgData){
        history.push('/login');
        Alert.success(`Registration Successful`, 10000);
      }
    }, [createOrgData]);

    const onAccountCreation = (image:string, firstName:string, lastName:string, password:string) => {
      setLoginPassword(password)
      console.log('fomrdata inside account creation', firstName);
      if (verifyEmailData?.verifyEmail?.email) {
        CreateAccount({
          variables: {
            credential: {
              email: verifyEmailData?.verifyEmail?.email,
              image,
              firstName,
              lastName,
              password
            },
          },
        });
        
      }     
    }

  const onOrgCreate = (formData: any) => {
      CreateOrg({
        variables:{
          data: formData
        }
      });
    }

    
    if (loginUserData) {
      signIn({
        newToken: loginUserData.signIn?.accessToken,
        actorName: loginUserData.signIn?.features[0],
        userProfile: {
          fullName: loginUserData.signIn?.fullName,
        },
      });
      if (loginUserData.signIn.userStatus === 'ORG_CREATED') {
        history.push('/dashboard');
      } 
    }

    if(createAccountError){
      Alert.warning(`Please fill in all the fields and try again`, 10000);
    }

    if(verifyEmailLoading) {
      return <LogoSpinnerV />
    }
    if (
      verifyEmailError &&
      verifyEmailError.graphQLErrors &&
      Array.isArray(verifyEmailError.graphQLErrors) &&
      verifyEmailError.graphQLErrors[0] &&
      verifyEmailError.graphQLErrors[0].message
    ) {
      Alert.warning(`${verifyEmailError.graphQLErrors[0].message.message}`, 10000);
      history.push('/');
    }   
  
    if (true) {
      return (
        <>
          <RegistrationPage
            onHeaderLogoPress={() => history.push('/')}
            items={stepItems}
            title={BPR.title}
            leftAvatar={BPR.leftAvatar}
            headerTitleToDisplay={BPR.headerTitleToDisplay}
            verifiedEmail={verifyEmailData?.verifyEmail?.email}
            onAccountCreation={onAccountCreation}
            createAccountLoading={createAccountLoading}
            isAccountCreated={loginUserData? true: false}
            onOrganizationCreate= {onOrgCreate}
            creteOrgLoading ={createOrgLoading}
            isOrgCreated ={createOrgData? true: false}
            termsandcondition={verifyEmailData?.verifyEmail?.userTermsAndConditions}
            // onCompleteRegistration={completeRegistration}
            userType={userType}
          />
        </>
      );
    } else {
      history.push('/');
    }
    return <div />;
  };
  return <>{_renderBody()}</>;
};
