import React from 'react';
import { useViewport } from 'react-viewport-provider';
import { NotarizeLG } from './Notarize.lg';

export interface IProps {
}

export const Noterize = (props: IProps) => {
    const { bp } = useViewport();

    // if (bp === 'xs' || bp === 'sm') {
    //     return <DashboardSM />;
    // }

    // if (bp === 'md') {
    //   return <DashboardMD />;
    // }

    //bp === 'lg' || bp === 'xl' || bp === 'md
    return <NotarizeLG {...props} />;
};
