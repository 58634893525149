import React, { useContext, useLayoutEffect } from 'react';
import { View } from 'react-native';
import { StyleSheet } from 'react-native';
import config from './config/default-blueprint.json';
import { ThemeContext } from '../../../../../../providers/theme';
import { useViewport } from 'react-viewport-provider';
import { BodyTemplateV } from 'react-body-template-1';
import { CredentialTemplateV } from 'react-credentail-template';
import { Icon, Whisper, Tooltip, Divider } from 'rsuite';
import { ButtonV } from 'react-button-rs';
import { SelectInputV } from 'react-select-input-rs';

import { TableV, TableHeaderDataProps } from 'react-table-rs';
import { DrawerV } from 'react-drawer-rs';
import jsf from 'json-schema-faker';
import faker from 'faker';
import Chance from 'chance';
import { IProps } from './index';

jsf.extend('faker', () => faker);
jsf.extend('chance', () => new Chance());

const humanizeString = require('humanize-string');
const BP = config.Groups;


const selectField = [
    {
        label: 'Active Users',
        Value: 'Avtive Users',
    },
];


export const GroupsLG = (props: IProps) => {
    const theme = useContext(ThemeContext);
    const [data, setData] = React.useState<any>([]);
    const { width, height } = useViewport();
    const [userActionKey, setUserActionKey] = React.useState('');
    const [drawer, setDrawer] = React.useState<any>({
        placement: 'right',
        title: '',
        size: 'sm',
        backdrop: false,
        show: false,
    });

    const styles = {
        leftcontainerStyle: {
            display: 'flex',
        },
        rightContainerStyle: {
            float: 'right',
            padding: 10,
        },
        containerStyle: {
            backgroundColor: '#f7f7fa',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '0 auto',
            width: '100%',
            minHeight: height - (height * 0.12),
            padding: 20,
        },
    };


    const renderDrawerBody = () => {
        if (userActionKey === 'adduser') {
            return <div />;
        }
        return <div />;
    };

    const handleOnDrawerHide = () => {
        let _drawer = { ...drawer };
        _drawer.show = false;
        setDrawer({
            ..._drawer,
        });
    };

    const renderDrawerHeader = () => {
        return <div />;
    };

    const renderDrawerFooter = () => {
        return <div />;
    };

    const bodyContent = () => {
        return (
            <CredentialTemplateV
                templateDetails={BP?.GROUP_DETAILS}
                onCardClick={eventKey => props.onCardClick(eventKey)}
                containerStyle={StyleSheet.flatten([styles.containerStyle])}
            />
        );
    };

    return (
        <>
            <BodyTemplateV
                primaryColor={BP?.primaryColor || theme?.palette.primary}
                primaryHighlightColor={
                    BP?.primaryHighlightColor || theme?.palette.secondary
                }
                secondaryColor={BP?.secondaryColor || theme?.palette.secondary}
                ternaryColor={BP?.ternaryColor || theme?.palette.ternary}
                renderContent={bodyContent}
                contentScrollableInline={true}
                headerTitleStyle={{ marginLeft: 20 }}
                navbarStyle={{}}
                layout={'sidebar-header-content-footer'}
                navBarRightItems={BP?.navRightItems}
                onNavItemSelect={eventKey => console.log(eventKey)}
                navBarLeftItems={BP?.navLeftItems}
                headerBackgroundColor={'#fff'}
                renderFooter={() => <div />}
            />
            <DrawerV
                placement={drawer.placement}
                show={drawer.show}
                backdrop={drawer.backdrop}
                size={drawer.size}
                renderHeader={renderDrawerHeader}
                onHide={handleOnDrawerHide}
                drawerTitle={drawer.title}
                renderBody={renderDrawerBody}
                renderFooter={renderDrawerFooter}
            />
        </>
    );
};
