import React, { useContext, useState, useEffect } from 'react';
import { View, StyleSheet, Text } from 'react-native';
import config from '../../config/default-blueprint.json';
import { ThemeContext } from '../../../../providers/theme';
import { useViewport } from 'react-viewport-provider';
import { DashboardContainerV } from 'react-dashboard-container-rs';
import { DrawerV } from 'react-drawer-rs';
import { OrganizationProfile } from './components/OrganizationProfile';
import { OperatorProfile } from './components/OperatorProfile';
import { ProfileTemplate } from './components/ProfileTemplate';
import { IntroCard } from './components/IntroCard';
import { CredentailCategory } from './components/credentials/CredentialCategory';
import { CredentailList } from './components/credentials/components/credentialList';
import { IssueCredential } from './components/credentials/components/issueCredential';
import { Admins } from './components/Admins';
import {Verification } from './components/VerificationPage';
import { VerificationRequest } from './components/VerificationPage/components/VerificationSuite';
import { DashboardGraph } from './components/dashboard/Dashboard';
import { HolderGroups } from './components/holderGroups';
import { TrustNetwork } from './components/networks';
import { IssuersList } from './components/Issuers';
import { VerifiersList } from './components/Verifiers';
import { HoldersList } from './components/holders';
import { UserList } from './components/holderGroups/userlist/UserList';
import { Noterize } from './components/notarize';
import { CredentialView } from './components/networks/components/credential-view';
import {IProps} from './index';

const humanizeString = require('humanize-string');
const BP = config.dashboard;



export const DashboardLG = (props: IProps) => {
  const theme = useContext(ThemeContext);
  const [drawer, setDrawer] = React.useState<any>({
    placement: 'right',
    title: '',
    size: 'xs',
    backdrop: false,
    show: false,
  });
  const [navBarEventKey, setNavBarEventKey] = React.useState<string>();
  const [sideBarEventKey, setSideBarEventKey] = React.useState<string>();
  const [formData, setFormData] = useState<any>();
  const [drawerWidth, setDrawerWidth] = useState(330);
  const { width, height } = useViewport();
  const [claims, setClaims] = useState<any>([]);



  useEffect(()=>{
    if(props.issueCredentialSuccess){
      setSideBarEventKey('credentials');
    }
    
  },[props.issueCredentialSuccess]);

  useEffect(()=>{
    if(props.reqCredSuccess){
      setSideBarEventKey('verification');
    }
  }, [props.reqCredSuccess]);


  const styles = StyleSheet.create({
    sidebarHeaderContainer: {
      alignItems: 'center',
      justifyContent: 'center',
      borderWidth: StyleSheet.hairlineWidth,
      height: 0.06 * height,
    },
    sidebarHeaderTitleStyle: {
      color: '#ffffff',
      fontWeight: 'bold',
      fontSize: 22,
    },
  });

  const RenderSidenavHeader = () => {
    return (
      <View style={StyleSheet.flatten([styles.sidebarHeaderContainer])}>
        <Text style={StyleSheet.flatten([styles.sidebarHeaderTitleStyle])}>
          {BP.sidebarHeaderTitle}
        </Text>
      </View>
    );
  };



  const renderContent = () => {
    if (sideBarEventKey === 'organization') {
      return <OrganizationProfile 
      orgProfileData={props?.orgProfileData}
      />;
    } else if (sideBarEventKey === 'dashboard') {
      return <DashboardGraph drawerWidth={drawerWidth} />;
    } else if (sideBarEventKey === 'users') {
      return <HoldersList 
      holdersList = {props.holdersList}
      onInviteHolders={props.onInviteHolders}
      getUsersLoading={props?.getUsersLoading}
      />;
    } else if (sideBarEventKey === 'groups') {
      return <HolderGroups drawerWidth={drawerWidth}
        onCardClick={eventKey => setSideBarEventKey(eventKey)}
      />;
    } else if (sideBarEventKey === 'credentials') {
      return (
        <CredentailCategory
          onCardClick={eventKey => {
            setSideBarEventKey("credentialTemplates")
            props.onTemplateSelect && props.onTemplateSelect(eventKey)        
        }}
          credentialCategories={props.credentialCategries}
          credentialCategoryLoading={props.credentialCategoryLoading}
          
        />
      );
    } else if (sideBarEventKey === 'issuers') {
      return (
        <IssuersList
          onCardClick={eventKey => setSideBarEventKey(eventKey)}
          drawerWidth={drawerWidth}
          issuersList={props.issuersList}
          onInviteIssuer={props.onInviteIssuer}
          getUsersLoading={props?.getUsersLoading}
        />
      );
    }
    else if (sideBarEventKey === 'group1') {
      return (
        <UserList 

        />
      )
    }
    else if (sideBarEventKey === 'verifiers') {
      return (
        <VerifiersList
          onCardClick={eventKey =>{ 
            setSideBarEventKey(eventKey)
            props.onTemplateSelect && props.onTemplateSelect(eventKey)
          }}
          drawerWidth={drawerWidth}
          verifiersList={props.verifiersList}
          onInviteVerifier={props.onInviteVerifier}
          getUsersLoading={props?.getUsersLoading}
        />
      );
    }

    else if (sideBarEventKey === 'credentialTemplates') {
      return (
        <CredentailList
          onCredentialClick={(data) => {
            setSideBarEventKey("issueCredential");
            setFormData(data)
          }}
          credList={props.templateData}
          findTemplates={props.findTemplates}
          breadCrumbsClick={(eventKey)=>setSideBarEventKey(eventKey)}
        />
      );
    } else if (sideBarEventKey === 'issueCredential') {
      return <IssueCredential drawerWidth={drawerWidth} 
      formData= {formData}
      holdersList = {props.holdersList}
      onIssueCredential={props.onIssueCredential}
      issueCredentialSuccess={props.issueCredentialSuccess}
      issueCredLoading={props?.issueCredLoading}
      />;
    }
    // else if(sideBarEventKey === 'applications') {
    //   return<Admins 
    //   admins={BP?.applications}
    //   navItems= {BP?.navItems}
    //   />;
    // }
    else if (sideBarEventKey === 'verification') {
      return (
        <Verification
          onCategoryClick={eventKey => props.onTemplateSelect && props.onTemplateSelect(eventKey)}
          // onCardClick={eventKey => console.log('verification', eventKey)}
          drawerWidth={drawerWidth}
          credentialCategories={props?.credentialCategries}
          credList={props?.templateData}
          onClaimPress={(claims) => {
            setSideBarEventKey("verifyHealthcare");
            setClaims(claims);
          }}
        />
      );
    }
    else if (sideBarEventKey === 'notarize') {
      return <Noterize
      />;
    }
    else if (sideBarEventKey === 'trustNetwork') {
      return <TrustNetwork drawerWidth={drawerWidth}
        navItemSelect={eventKey => setSideBarEventKey(eventKey)}
        networkData={props.networkData}
        networkLoading={props.networkLoading}
        networkPageChange={props.onNetworkPageChange}
        onPresentationSelect={props.onPresentationSelect}
        presentationDetails={props.presentationDetails}
        presentationDetailLoading={props?.presentationDetailLoading}
        onCredentialResend={props?.onCredentialResend}
        resendCredentialLoading={props?.resendCredentialLoading}
      />;
    }
    else if (sideBarEventKey === 'tableView') {
      return <CredentialView 
      netwrokCredData={props?.netwrokCredData}
      navItemSelect={eventKey => setSideBarEventKey(eventKey)}
      />
    }
    else if (sideBarEventKey === 'admins') {
      return <Admins 
      admins={props?.adminUsers}
      onInviteAdmin={props?.onInviteAdmin}
      adminLoading={props?.adminLoading}
      />;
    } else if (sideBarEventKey === 'verifyHealthcare') {
      return <VerificationRequest 
      drawerWidth={drawerWidth} 
      holdersList = {props.holdersList}
      claims={claims}
      onVerifyRequest={props?.onVerifyRequest}
      reqCredLoading={props?.reqCredLoading}
      />;
    }
    else if(sideBarEventKey === 'operator'){
      return <OperatorProfile 
      opeProfileData={props?.opeProfileData}
      />;
    }
    
    return <OperatorProfile 
      opeProfileData={props?.opeProfileData}
    />;

  };

  const renderFooter = () => {
    return <div />;
  };

  const handleOnNavItemSelect = (eventKey: string) => {
    setNavBarEventKey(eventKey);
    if (eventKey === 'notifications') {
      let _drawer = { ...drawer };
      _drawer.title = humanizeString(eventKey);
      _drawer.placement = BP.notificationsDrawerPlacement;
      _drawer.show = true;
      setDrawer({
        ..._drawer,
      });
    }
    if (eventKey === 'profile') {
      let _drawer = { ...drawer };
      _drawer.title = humanizeString(eventKey);
      _drawer.placement = BP.notificationsDrawerPlacement;
      _drawer.show = true;
      setDrawer({
        ..._drawer,
      });
    }
  };

  const handleOnSideNavItemselect = (eventKey: string) => {
    setSideBarEventKey(eventKey);
    props.sidebarNavItemSelect && props.sidebarNavItemSelect(eventKey); 
  };

  const setExpanded = (eventKey: boolean) => {
    console.log('drawer expand', eventKey);
    if (eventKey) {
      setDrawerWidth(330);
    } else {
      setDrawerWidth(120);
    }
  };

  const renderDrawerHeader = () => {
    return <div />;
  };

  const renderDrawerFooter = () => {
    return <div />;
  };

  const renderDrawerBody = () => {
    if (navBarEventKey === 'profile') {
      return <ProfileTemplate 
      userProfile={props?.userProfile}
      />;
    }
    return <div />;
  };

  const handleOnDrawerHide = () => {
    let _drawer = { ...drawer };
    _drawer.show = false;
    setDrawer({
      ..._drawer,
    });
  };

  let _navBarItems = Array.isArray(BP?.navBarItems) && BP?.navBarItems.map(item=>{
     if(item?.eventKey === 'profile'){
       return({
         ...item,
         avatar: {
          src:props?.userProfile?.logo,
          title: props?.userProfile?.fullName.match(/\b(\w)/g)
        }
       });
     }
     else {
       return({
         ...item
       });
     }
  })

  return (
    <>
      <DashboardContainerV
        navBarRightItems={_navBarItems}
        headerLogo={BP.headerLogo}
        headerTitle={BP.headerTitle}
        primaryColor={theme?.palette.primary}
        secondaryColor={theme?.palette.secondary}
        ternaryColor={theme?.palette.ternary}
        primaryHighlightColor={theme?.palette.primaryDark}
        sidebarWidth={180}
        sideBarItems={props.sideNavItems}
        renderContent={renderContent}
        headerTitleStyle={{}}
        navbarStyle={{}}
        onNavItemSelect={handleOnNavItemSelect}
        onSideNavItemSelect={handleOnSideNavItemselect}
        renderSidenavHeader={RenderSidenavHeader}
        renderFooter={renderFooter}
        isexpanded={eventKey => setExpanded(eventKey)}
      />
      <DrawerV
        placement={drawer.placement}
        show={drawer.show}
        backdrop={drawer.backdrop}
        size={drawer.size}
        renderHeader={renderDrawerHeader}
        onHide={handleOnDrawerHide}
        drawerTitle={drawer.title}
        renderBody={renderDrawerBody}
        renderFooter={renderDrawerFooter}
      />
    </>
  );
};
