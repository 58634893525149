import React, {useState, useEffect} from 'react';
import {StyleSheet} from 'react-native';
import {FormV, defaultValuesDS,FORM_VALUES, SENDER_ID as FORMV_SENDER_ID} from 'react-form-rs';
import {useViewport} from 'react-viewport-provider';
import {FBGridV, FBItemV} from 'react-flexbox-grid-rs';
import {DataSet, DataView, createNewDataPipeFrom} from 'vis-data';
import BP from './config/default-blueprint.json';
import Checkbox from 'rsuite/lib/Checkbox';
import { EventBus } from 'event-bus-vfs';
import { Alert } from 'rsuite';
import serverConfig from '../../../../../../config/default.config.json';

/**
 * Dataset and DataView
 */
export const SENDER_ID = 'Registration:RegistationPage:AccountProfile';
export const accountProfileDS = new DataSet({
  fieldId: 'key',
});
export const KEY = 'accountProfile';
export const accountProfileDV = new DataView(accountProfileDS);
accountProfileDS.add([
  {
    key: KEY,
  },
]);


export type IProps = {
  validate?: boolean;
  onValidateResult?(formContext: any, 
        status: 'success' | 'error', 
        validateResult: any): void;
        verifiedEmail?:string;
        userType?:any;
        orgTermsAccepted?(checked?:boolean):void;
        termsAccepted?(checked?:boolean):void;
};

let formContext = {};

const {imageUploadURL} = serverConfig?.servers;

export const AccountProfile = (props: IProps) => {
    const parentRef = React.useRef(() => {})
    const {height} = useViewport();
    const [termsChecked, setTermsChecked] = useState<boolean>(false);
    const [orgTermsChecked, setOrgTermsChecked] = useState<boolean>(false);
    // React.useEffect(() => {
    //     if (props.validate) {
    //         parentRef.current && parentRef.current();
    //     }
    // });
    useEffect(() => {
      const app = EventBus.on(FORM_VALUES, value => {
          formContext = { ...value };
      });
      return function () {
          app.off();
      };
  }, []);
    console.log('updated user type', props?.userType);
    const isOrganization = (Array.isArray(props?.userType) && props?.userType.includes("ISSUER_ADMIN"))||
    (Array.isArray(props?.userType) && props?.userType.includes("VERIFIER_ADMIN")) ||
    (Array.isArray(props?.userType) && props?.userType.includes("NETWORK_OPERATOR_ADMIN"));

    console.log('updated user type', isOrganization);
    const styles = StyleSheet.create({
      formContainer: {
        width: '60%',
        // height: 0.6 * height,
        marginLeft: 30,
        marginTop: 10,
        backgroundColor: '#ffffff',
        justifyContent: 'flex-start',
        alignItems: 'center',
        borderRadius: 10,
        // overflow: 'scroll',
      },
      container: {},
    });

    const handleValidateResult = (
      formContext?: any,
      status?: 'success' | 'error',
      validationResult: any,
    ) => {
      accountProfileDS.update({ key: KEY,
        defaultValues: defaultValuesDS.get(),
      });
          props.onValidateResult && props.onValidateResult(formContext, status, validationResult);
     
    };

    const _getFormFields =() => {
      let _formFields =  Array.isArray(BP?.formFields) && BP?.formFields.map(item=>{
        if(item.key=== 'emailAddress'){
          return ({
            ...item,
            componentProps:{
              containerStyle: {
                width: "100%",
                marginTop: 23,
                backgroundColor: "transparent"
              },
              disabled:true,
              inline: false,
              inside: false,
              appearance: "liner",
              placeholder:props.verifiedEmail,
              prefixComponent: [
                {
                  name: "envelope",
                  style: {
                    color: "#909090"
                  }
                }
          ]
        }
           
          })
        }
        else if(item.key=== 'avatar'){
          return ({
            ...item,
            uploadProps: {
              action: imageUploadURL,
              containerStyle: {"width": "100%"},
              listType: "picture",
              avatarButtonStyles: true,
              title: "Profile Image",
              titleStyle: {
              color: "#909090",
              fontWeight: "bold"
              }
            },           
          })
        }
        else{
          return {...item}
        }
        
      });
      console.log('forom fields after update', _formFields);
      return _formFields;
    }

    return (
      <FBGridV justify={'start'} align={'middle'}>
        <FBItemV colspan={20}>
          <FormV
            components={_getFormFields()}
            formContext={formContext}
            containerStyle={styles.formContainer}
            validateForm={props.validate}
            onValidateResult={handleValidateResult}
            defaultValues={defaultValuesDS.get()}
            ref={parentRef}
          />
          {isOrganization && isOrganization ? 
          (
            <>
            <Checkbox
          style={{padding:'2%'}}
          checked={orgTermsChecked}
          onChange={(value,checked)=>{
            setOrgTermsChecked(checked);
            props.orgTermsAccepted && props.orgTermsAccepted(checked)
          }}
          >
          {BP?.orgTerms}
          </Checkbox>
            <Checkbox
            style={{paddingLeft:'2%'}}
          checked={termsChecked}
          onChange={(value, checked)=>{
            setTermsChecked(checked);
            props.termsAccepted && props.termsAccepted(checked)}}
          >
            <div onClick={()=>{'terms clicked'}}>
            {BP?.acceptTerms}
            </div>
          
        </Checkbox>
        </>
          ):
          (
            <Checkbox
          style={{padding:'2%'}}
          checked={termsChecked}
          onChange={(value, checked)=>{
            setTermsChecked(checked);
            props.termsAccepted && props.termsAccepted(checked)}}
        >
          <div onClick={()=>{'terms clicked'}}>
          {BP?.acceptTerms}
          </div>
        </Checkbox>
          )  
        }
        </FBItemV>
      </FBGridV>
    );
}