import React from 'react';
import { useViewport } from 'react-viewport-provider';
import { TrustNetworkLG } from './TrustNetwork.lg';

export interface IProps {
    profileData: object;
    drawerWidth: number;
    navItemSelect?(eventKey?: string): void;
    networkData?:any;
    networkLoading?:any;
    networkPageChange?(pageNo?: number):void;
    onPresentationSelect?(edgeTag?:string):void;
    presentationDetails?:any;
    presentationDetailLoading?:boolean;
    onCredentialResend?(resendData?:any):void;
    resendCredentialLoading?:boolean;
}

export const TrustNetwork = (props: IProps) => {
    const { bp } = useViewport();

    // if (bp === 'xs' || bp === 'sm') {
    //     return <DashboardSM />;
    // }

    // if (bp === 'md') {
    //   return <DashboardMD />;
    // }

    //bp === 'lg' || bp === 'xl' || bp === 'md
    return <TrustNetworkLG {...props} />;
};
