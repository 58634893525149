//@ts-nocheck
import {EventBus} from 'event-bus-vfs';
import React, {useEffect, useState, useContext} from 'react';
import {FormV, FORM_VALUES} from 'react-form-rs';
import {StyleSheet, Text, View} from 'react-native';
import {ButtonV} from 'react-native-button-vfw';
import {ThemeContext} from '../../../providers/theme';
import blueprint from '../config/default-blueprint.json';

const BP = blueprint.Form;

export interface FormProps {
  onSubmitPress?(): void;
}

let formContext = {};
const formFields = [
  {
    component: 'input',
    defaultValue: '',
    schemaType:
      "StringType().isEmail('Please input the correct email address').isRequired('Email is required')",
    componentProps: {
      containerStyle: {width: '100%', marginTop: 20},
      placeholder: 'Enter you Email Address',
      inline: false,
      inside: false,
      appearance: 'liner',
      color: 'green',
      helpblock: {
        tooltip: true,
        message: 'Mandatory',
        style: {},
      },
      prefixComponent: [
        {
          name: 'envelope',
          size: '1x',
          style: {
            color: '#909090',
          },
        },
      ],
      titleStyle: {color: '#909090', fontWeight: 'bold'},
    },
    key: 'emailAddress',
    label: 'Email Address',
  },
];
export const Form = (props: FormProps) => {
  useEffect(() => {
    const app = EventBus.on(FORM_VALUES, value => {
      formContext = {...value};
    });
    return function () {
      app.off();
    };
  }, []);
  const [validateForm, setValidateForm] = useState<any>(null);
  const theme = useContext(ThemeContext);

  const styles = {
    formContainerStyle: {
      backgroundColor: 'transparent',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 10,
    },
    buttonTitleStyle: {
      color: '#ffffff',
    },
    buttonContainerStyle: {
      width: 120,
      borderRadius: 10,
      alignSelf: 'center',
      marginTop: 30,
      marginBottom: 60,
      backgroundColor: theme?.palette.secondary,
    },
    labelTextStyle: {
      paddingTop: 5,
      fontSize: 18,
      color: theme?.palette.secondary,
    },
  };

  const _getTextFields = () => {
    return (
      <Text style={StyleSheet.flatten([styles.labelTextStyle])}>
        {BP.label}
      </Text>
    );
  };

  return (
    <React.Fragment>
      {_getTextFields()}
      <FormV
        components={formFields}
        formContext={formContext}
        containerStyle={StyleSheet.flatten([styles.formContainerStyle])}
        validateForm={validateForm}
        onValidateResult={props.onSubmitPress}
      />
      <ButtonV
        {...props}
        title={BP.buttonTitle ?? 'Submit'}
        titleStyle={StyleSheet.flatten([styles.buttonTitleStyle])}
        type={'solid'}
        buttonStyle={StyleSheet.flatten([styles.buttonContainerStyle])}
        onPress={id => {
          setValidateForm({
            ...formContext,
          });
        }}
      />
    </React.Fragment>
  );
};
