import gql from 'graphql-tag';

export const INVITE_USER = gql`
  mutation createUser($user: createUserInput!, $type: UserType!, $organizationName: String) {
    createUser(user: $user, type: $type, organizationName: $organizationName) {
      email
    }
  }
`;

export const ISSUE_CREDENTIAL = gql`
mutation IssueCredential($credentialKeyVals:[KeyVal], $holderEmail:String!, $credentialTemplateKey:String!, $category:String){
  issueCredential(
    credentialKeyVals: $credentialKeyVals,
    holderEmail: $holderEmail,
    credentialTemplateKey: $credentialTemplateKey,
    category: $category
  )
}
`;

export const REQUEST_CREDENTIAL = gql`
mutation RequestCredentials($requests: [RequestCredentialsInput]){
  requestCredentials(
    requests:$requests
  )
}
`;

export const RESEND_CREDENTIAL = gql`
mutation ResendCredential($edgeTag: String!, $did: String!, $type:ResendPushNotificationType!){
  resendPushNotification(
    edgeTag: $edgeTag
    did: $did
    type: $type
  )
}
`;