import React, { useContext, useState, useEffect } from 'react';
import { useViewport } from 'react-viewport-provider';
import { StyleSheet } from 'react-native';
import { EventBus } from 'event-bus-vfs';
import { FBGridV, FBItemV } from 'react-flexbox-grid-rs';
import { ButtonV } from 'react-button-rs';
import { FormV, FORM_VALUES, defaultValuesDS } from 'react-form-rs';
import { SelectInputV } from 'react-select-input-rs';

import config from '../config/default-blueprint.json';
import { IProps } from '../../index';

const BP = config.InviteUser;
let formContext = {};

export const InviteVerifier = (props:IProps) => {
    const parentRef = React.useRef(() => { });
    const [validateForm, setValidateForm] = useState<any>(null);
    const { width, height } = useViewport();
    const [userType, setUserType] = useState('Individual');
    // useEffect(() => {
    //     const app = EventBus.on(FORM_VALUES, value => {
    //         formContext = { ...value };
    //     });
    //     return function () {
    //         app.off();
    //     };
    // }, []);
    const styles = {
        titleStyle: {
            textAlign: 'center',
        },
        formStyle: {
            width: '100%',
            backgroundColor: '#ffffff',
            justifyContent: 'flex-start',
            alignItems: 'center',
            borderRadius: 10,
        },
    };

    const handleValidateResult = (
        key: string,
        formContext?: any,
        status?: 'success' | 'error',
        validationResult: any,
    ) => {
        console.log('form values', formContext);
        let formData = {
            email: defaultValuesDS.get('emailAddress')?.defaultValue,
            type: userType,
            organization: defaultValuesDS.get('organization')?.defaultValue
        }
        props.onInviteVerifier &&
            props.onInviteVerifier(formData);
    };

    const onSelectChange = (value: string) => {
        if (value !== userType) {
            setUserType(value);
        }
    }

    const getForm = () => {

        let form = [];
        let formFields = (userType === 'Individual') ? BP?.indformFields : BP?.orgformFields;
        form.push(
            <div style={{ alignItems: 'center'}}>
                <SelectInputV
                    data={BP?.userType}
                    title={BP?.selectLabel}
                    onSelect={onSelectChange}
                    selectInputStyle={{ width: '100%' }}
                />
                <FormV
                    components={formFields}
                    formContext={formContext}
                    containerStyle={StyleSheet.flatten([styles.formStyle])}
                    validateForm={validateForm}
                    onValidateResult={handleValidateResult}
                    defaultValues={defaultValuesDS.get()}
                    ref={parentRef}
                />
            </div>,
        );

        return <React.Fragment>{form}</React.Fragment>;
    };
    return (
        <FBGridV justify="center" style={{ justifyContent: 'center' }}>
            <FBItemV colspan={24}>{getForm()}</FBItemV>
            <FBItemV colspan={24}>
                <ButtonV
                    appearance={'primary'}
                    color={'red'}
                    buttonTitle={BP?.submitButton?.buttonTitle}
                    buttonTitleStyle={BP?.submitButton?.buttonTitleStyle}
                    containerStyle={{
                        width: '100%',
                        margin: '0 auto',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                    }}
                    onPress={id => {
                        // setValidateForm(true);
                        // // console.log('formContext', formContext);
                        setValidateForm({
                          ...formContext,
                        });
                    }}
                />
            </FBItemV>
        </FBGridV>
    );
};
