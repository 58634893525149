import React, { useContext, useLayoutEffect, useState } from 'react';
import { View } from 'react-native';
import { StyleSheet } from 'react-native';
import config from './config/default-blueprint.json';
import { ThemeContext } from '../../../../../../providers/theme';
import { useViewport, ViewportProvider } from 'react-viewport-provider';
import { BodyTemplateV } from 'react-body-template-1';
import { Icon, Whisper, Tooltip, Divider, Checkbox, PanelGroup, Panel, Alert } from 'rsuite';
import { ButtonV } from 'react-button-rs';
import { ImageV } from 'react-native-image-vfw';
import { TextV } from 'react-native-text-vfw';
import { PanelV } from 'react-panel-rs';
import { TableV, TableHeaderDataProps } from 'react-table-rs';
import { DrawerV } from 'react-drawer-rs';
import jsf from 'json-schema-faker';
import faker from 'faker';
import Chance from 'chance';
import { FBGridV, FBItemV } from 'react-flexbox-grid-rs';
import { ChipV } from 'react-chip-mui';
import { InputV } from 'react-input-rs';
import { ToggleV } from 'react-toggle-rs';
import Dash from 'react-native-dash';
import { CheckboxV } from 'react-checkbox-rs';
import { IProps } from './index';


jsf.extend('faker', () => faker);
jsf.extend('chance', () => new Chance());

const AVATAR_WIDTH = 80;
const AVATAR_HEIGHT = 80;
const TITLE_FONT_COLOR = '#333333';
const TEMPLATE_ICON_COLOR = '#333333';

const humanizeString = require('humanize-string');
const BP = config.verificationCategory;




export const VerificationLG = (props: IProps) => {
  const theme = useContext(ThemeContext);
  const { width, height } = useViewport();
  const [data, setData] = React.useState<any>([]);
  const [checkedKeys, setCheckedKeys] = useState<any>([]);
  const [essential, setEssential] = useState<any>([])
  const [suites, setSuites] = React.useState<any>([]);
  const [userActionKey, setUserActionKey] = React.useState('');
  const [reason, setReason] = useState<any>({});
  const [drawer, setDrawer] = React.useState<any>({
    placement: 'right',
    title: '',
    size: 'sm',
    backdrop: false,
    show: false,
  });

  const generateFakeData = async (schema, rows = 1) => {
    console.log('schema', schema);
    let res = [];
    try {
      if (schema) {
        for (let i = 0; i < rows; ++i) {
          let _sample = { ...schema[i] };
          if (_sample?.reason === 'Reason') {
            _sample.reason = <span style={StyleSheet.flatten([styles.statusStyle])}><InputV
              titleStyle={{ color: '#000' }}
              titleContainerStyle={{ width: 200 }}
              containerStyle={{ width: '80%' }}
              inside={true}
              placeholder={"Reason"}
              size={'md'}
            /></span>;
          }

          _sample.mandatory = <View>
            <ToggleV
              checkedChildren={<Icon icon="check" />}
              unCheckedChildren={<Icon icon="close" />}
            //  onChange={_handleToggleChange.bind(null, _sample.key)}
            />
          </View>
          res.push(_sample);
        }
      }
    } catch (err) { }
    return res;
  };

  const columns: TableHeaderDataProps[] = [
    {
      label: 'Select',
      dataKey: 'key',
      appearance: 'checkbox',
      columnProps: {
        fixed: true,
        width: 80,
      },
      labelStyle: {
        color: '#606161',
        textTransform: 'uppercase',
      },
      headerCellStyle: {
        backgroundColor: '#f5f5f5',
        height: 50,
      },
    },
    {
      label: 'Credential Name',
      dataKey: 'value',
      appearance: 'text',
      appearanceProps: {
        style: {
          // color: '#d3d3d3'
        },
      },
      columnProps: {
        sortable: true,
        width: 200,
      },
      cellStyle: {
        //  backgroundColor: '#d3d3d3'
      },
      labelStyle: {
        color: '#606161',
        textTransform: 'uppercase',
      },
      headerCellStyle: {
        backgroundColor: '#f5f5f5',
        height: 50,
      },
    },
    {
      label: 'Reason',
      dataKey: 'reason',
      appearance: 'text',
      columnProps: {
        sortable: true,
        width: 200,
      },
      labelStyle: {
        color: '#606161',
        textTransform: 'uppercase',
      },
      headerCellStyle: {
        backgroundColor: '#f5f5f5',
        height: 50,
      },
    },
    {
      label: 'Mandatory',
      dataKey: 'mandatory',
      appearance: 'text',
      columnProps: {
        sortable: true,
        width: 200,
      },
      labelStyle: {
        color: '#606161',
        textTransform: 'uppercase',
      },
      headerCellStyle: {
        backgroundColor: '#f5f5f5',
        height: 50,
      },
    },
  ];

  const styles = {
    leftcontainerStyle: {
      display: 'flex',
    },
    rightContainerStyle: {
      float: 'right',
      padding: 10,
    },
    containerStyle: {
      backgroundColor: '#f7f7fa',
      justifyContent: 'flex-start',
      borderRadius: 'none',
      minHeight: height - (height * 0.12),
      padding: 20,
    },
    templateItemContainer: {
      justifyContent: 'flex-start',
      flexDirection: 'row',
      alignItems: 'center',
      marginHorizontal: 30,
      marginTop: 5,
      marginBottom: 5,
      minHeight: 40,
      border: '0.5px solid #fff',
      borderRadius: 5,
    },
    suiteContainerStyle: {
      justifyContent: 'flex-start',
      flexDirection: 'row',
      alignItems: 'center',
      marginHorizontal: 30,
      marginTop: 5,
      marginBottom: 5,
      minHeight: 40,
      borderRadius: 5,
    },
    templateIconStyle: {
      color: TEMPLATE_ICON_COLOR,
      alignSelf: 'center',
      width: 10,
    },
    titleStyle: {
      fontSize: 24,
      padding: 20,
      lineHeight: 42
    },
    iconStyle: {
      display: 'flex',
      padding: 10,
      alignItems: 'center',
    },
    templateTitleGroupContainer: {
      marginLeft: 5,
      alignItems: 'flex-start',
      justifyContent: 'center',
      maxWidth: '100%',
      flex: 1,
      flexWrap: 'wrap',
    },
  };

  // const onAddNewTemplate = (eventKey: string) => {
  //   setUserActionKey(eventKey);
  //   if (eventKey === 'new certificate') {
  //     let _drawer = { ...drawer };
  //     _drawer.title = humanizeString(eventKey);
  //     _drawer.placement = BP?.userDrawerPlacement;
  //     _drawer.show = true;
  //     setDrawer({
  //       ..._drawer,
  //     });
  //   }
  // };

  const renderDrawerBody = () => {
    if (userActionKey === 'adduser') {
      return <div />;
    }
    return <div />;
  };

  const handleOnDrawerHide = () => {
    let _drawer = { ...drawer };
    _drawer.show = false;
    setDrawer({
      ..._drawer,
    });
  };

  const renderDrawerHeader = () => {
    return <div />;
  };

  const renderDrawerFooter = () => {
    return <div />;
  };

  const onSuiteSelect = (value: string, checked: boolean) => {
    console.log('checkedKeys', checkedKeys)
    const nextCheckedKeys = checked ? [...checkedKeys, value] : checkedKeys.filter(item => item !== value);
    setCheckedKeys(nextCheckedKeys);
  }


  const onCredCheckboxChange = (checked: boolean, value: string) => {
    console.log('selected checkbox', checked);
    console.log('selected checkbox value', data);
    const nextCheckedKeys = checked
      ? [...checkedKeys, value]
      : checkedKeys.filter(item => item !== value);
    setCheckedKeys(nextCheckedKeys);
    const nextEssentials = checked ? [...essential, value] : essential.filter(item => item !== value);
    setEssential(nextEssentials);
  }

  const onCategoryClick = (eventKey?: any) => {
    props.onCategoryClick && props.onCategoryClick(eventKey);
  }

  const _getCategoryList = () => {
    const { credentialCategories } = props;
    const verifierCategory = credentialCategories ? credentialCategories : BP?.VERIFICATION_DETAILS;

    console.log('verify categories', verifierCategory);

    let categories: any[] = [];

    verifierCategory.forEach((element, index) => {
      categories.push(
        <div
          onClick={() => onCategoryClick(element.templateIdentifiers)}
          style={StyleSheet.flatten([{ cursor: 'pointer' }])}
        >
          <View style={StyleSheet.flatten([styles.templateItemContainer])}>
            {element.icon && (
              <Whisper
                placement="top"
                trigger="hover"
                speaker={<Tooltip>{element.tooltip ?? element.title}</Tooltip>}
              >
                <div style={StyleSheet.flatten([styles.iconStyle])}>
                  <ImageV
                    source={{ uri: element.icon?.imageURI }}
                    imageStyle={StyleSheet.flatten([
                      styles.templateIconStyle,
                      element.icon?.imgWidth && {
                        width: element.icon.imgWidth,
                      },
                      element.icon?.imgHeight && {
                        height: element.icon?.imgHeight,
                      },
                    ])}
                  />
                  <Divider
                    vertical
                    style={StyleSheet.flatten([
                      {
                        height: element.icon.imgHeight
                          ? element.icon.imgHeight - 15
                          : 50,
                      },
                    ])}
                  />
                </div>
              </Whisper>
            )}
            {element.title && (
              <View
                style={StyleSheet.flatten([
                  styles.templateTitleGroupContainer,
                ])}
              >
                <TextV type={'subhead'}>
                  {element.type === 'url' ? (
                    <a
                      href={element.title}
                      target="_blank"
                      style={StyleSheet.flatten([
                        { color: TITLE_FONT_COLOR },
                      ])}
                    >
                      {element.title}
                    </a>
                  ) : (
                      element.title
                    )}
                </TextV>
                {element.subtitle1 && (
                  <TextV type={'footnote'} numberOfLines={2}>
                    {element.subtitle1}
                  </TextV>
                )}
                {element.subtitle2 && (
                  <TextV type={'footnote'} numberOfLines={2}>
                    {element.subtitle2}
                  </TextV>
                )}
              </View>
            )}
          </View>
        </div>
      )
    });
    return categories;
  }

  const handleDelete = (item: string) => {
    console.log('handle delete', item);
    let _deletechip = checkedKeys.filter(key => key !== item);
    setCheckedKeys(_deletechip);
    setEssential(_deletechip);
  }

  const onReasonValueChange = (value: string, key: string) => {
    console.log('reason key value', key, value);
    let _reason = { ...reason };
    _reason[key] = value;

    setReason({
      ..._reason
    });
  }

  const onToggleChange = (checked: boolean, key: string) => {
    let _essentials = essential;
    const nextEssentials = checked
      ? [..._essentials, key]
      : essential.filter(item => item !== key);
    setEssential(nextEssentials);
  }

  const onSubmitButtonPress = () => {
    console.log('submit button pressed', checkedKeys, essential, reason);
    let _claims: any[] = [];
    Array.isArray(checkedKeys) && checkedKeys.forEach(element => {
      console.log('reson ofchecked key', reason[element]);
      if (!reason[element] || reason[element] === "") {
        Alert.warning(`Reason is Mandatory for ${element}`, 5000);
      }
      else {
        _claims.push(
          {
            essential: essential.includes(element),
            reason: reason[element],
            credentialType: element
          }
        );
        props.onClaimPress && props.onClaimPress(_claims);
      }
    });
    console.log('claims after submit', _claims);
  }

  const bodyContent = () => {
    console.log('data in render', props?.credList);
    // let _newCredData = [];

    let _newCredData = Array.isArray(props?.credList) && props?.credList.map(item => {
      if (checkedKeys.includes(item?.templateKey)) {
        console.log('matched', item.templateKey);
        return ({
          ...item,
          selected: true,
        });
      }
      else return ({
        ...item
      });

    });
    // let _newCredData = Array.isArray(data) && data.map(item=>{
    //   for(let i=0; i<checkedKeys.length; i ++){
    //     console.log('item key', item?.key);
    //     console.log('checkedKeys', checkedKeys);
    //     if(item.key === checkedKeys[i]){
    //       console.log('matched', item.key);
    //       return({
    //         ...item,
    //         selected:true
    //       });
    //     }
    //     else return({
    //       ...item
    //     });
    //   }
    //   return({
    //     ...item
    //   })      

    // });
    console.log('new data after selection', _newCredData);
    console.log('essential keys', essential);
    console.log('reason', reason);

    let _credData = Array.isArray(_newCredData) && _newCredData.map(item => {
      if (item?.selected) {
        return (
          <FBGridV>
            <FBItemV colspan={8}>
              <Checkbox
                style={{ padding: 5 }}
                checked={checkedKeys.some(key => key === item?.templateKey)}
                key={item?.templateKey}
                onChange={(value, checked) => onCredCheckboxChange(checked, item.templateKey)}
                value={item?.key}
              >
                {item?.name}
              </Checkbox>
            </FBItemV>
            <FBItemV colspan={8}>
              <InputV
                titleStyle={{ color: '#000' }}
                titleContainerStyle={{ width: 200 }}
                containerStyle={{ width: '80%' }}
                inside={true}
                placeholder={"Reason"}
                size={'md'}
                key={item?.templateKey}
                // errorMessage={'Reason is required'}
                onChange={value => onReasonValueChange(value, item?.templateKey)}
              />
            </FBItemV>
            <FBItemV colspan={8}>
              <ToggleV
                defaultChecked
                checkedChildren={<Icon icon="check" />}
                unCheckedChildren={<Icon icon="close" />}
                onChange={(checked) => onToggleChange(checked, item?.templateKey)}
              />
            </FBItemV>
          </FBGridV>
        )
      }
      else {
        return (
          <FBGridV>
            <FBItemV colspan={24}>
              <Checkbox
                style={{ padding: 5 }}
                checked={checkedKeys.some(key => key === item?.templateKey)}
                key={item?.templateKey}
                onChange={(value, checked) => onCredCheckboxChange(checked, item?.templateKey)}
              >
                {item?.name}
              </Checkbox>
            </FBItemV>
          </FBGridV>
        )
      }
    });

    return (
      <>
        <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', flexWrap: 'wrap' }} >
          {checkedKeys.map(item => {
            return (
              <ChipV
                size={"small"}
                label={item}
                clickable={true}
                onDelete={() => handleDelete(item)}
                chipStyle={{ backgroundColor: '#cdcdcd', margin: 10, color: '#fff' }}
              />
            )
          })}
        </View>
        <FBGridV justify={'start'} style={StyleSheet.flatten([styles.containerStyle])}>
          <FBItemV colspan={8}>
            {_getCategoryList()}
          </FBItemV>
          <FBItemV colspan={16}>
            {_credData}
          </FBItemV>
          <div style={{ width: '100%' }}>
            {checkedKeys.length > 0 ?
              <ButtonV
                buttonTitle={BP?.submitButton?.title}

                color={BP?.submitButton?.color}
                id={BP?.submitButton?.id}
                buttonTitleStyle={{ color: '#fff', fontWeight: 'bold' }}
                onPress={onSubmitButtonPress}
                containerStyle={{ justifyContent: 'flex-end', width: 200, float: 'right', color: '#fff' }}
              /> : null
            }
          </div>
        </FBGridV>

      </>
    );
  };
  return (
    <>
      <BodyTemplateV
        primaryColor={BP?.primaryColor || theme?.palette.primary}
        primaryHighlightColor={
          BP?.primaryHighlightColor || theme?.palette.secondary
        }
        secondaryColor={BP?.secondaryColor || theme?.palette.secondary}
        ternaryColor={BP?.ternaryColor || theme?.palette.ternary}
        renderContent={bodyContent}
        contentScrollableInline={true}
        headerTitleStyle={{ marginLeft: 20 }}
        navbarStyle={{}}
        layout={'sidebar-header-content-footer'}
        navBarLeftItems={BP?.navLeftItems}
        onNavItemSelect={eventKey => console.log(eventKey)}
        headerBackgroundColor={'#fff'}
        renderFooter={() => <div />}
      />
      <DrawerV
        placement={drawer.placement}
        show={drawer.show}
        backdrop={drawer.backdrop}
        size={drawer.size}
        renderHeader={renderDrawerHeader}
        onHide={handleOnDrawerHide}
        drawerTitle={drawer.title}
        renderBody={renderDrawerBody}
        renderFooter={renderDrawerFooter}
      />
    </>
  );
};
