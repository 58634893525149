import React, { useContext } from 'react';
import { StyleSheet, Text } from 'react-native';
import config from './config/default-blueprint.json';
import { ThemeContext } from '../../../../../../providers/theme';
import { useViewport, ViewportProvider } from 'react-viewport-provider';
import { BodyTemplateV } from 'react-body-template-1';
import { CredentialTemplateV } from 'react-credentail-template';
import { DrawerV } from 'react-drawer-rs';
import { defaultValuesDS } from 'react-form-rs';
import jsf from 'json-schema-faker';
import faker from 'faker';
import Chance from 'chance';
import { InviteIssuer } from './components/InviteIssuer/InviteIssuer';
import { Divider } from 'rsuite';
import { IProps } from './index';
import { NetworkCredentialTemplateV } from 'react-network-credentail-template';
import Loader from 'rsuite/lib/Loader';
import { LogoSpinnerV } from 'react-logo-spinner-ext';

jsf.extend('faker', () => faker);
jsf.extend('chance', () => new Chance());

const humanizeString = require('humanize-string');
const BP = config.issuers;


export const IssuersListLG = (props: IProps) => {
    const theme = useContext(ThemeContext);
    const { width, height } = useViewport();
    const [data, setData] = React.useState<any>([]);
    const [userActionKey, setUserActionKey] = React.useState('');
    const [drawer, setDrawer] = React.useState<any>({
        placement: 'right',
        title: '',
        size: 'xs',
        backdrop: false,
        show: false,
    });
   console.log('issuer list', props.issuersList);

    let indivisualIssuer = props.issuersList && props.issuersList.filter((item:any) => {
        if(item.organization === null){
            return true;
        }
    });

    let _indIssuers = Array.isArray(indivisualIssuer) && indivisualIssuer.map(item=>{
        return({
                key: item?.email,
                title: item?.fullName ? item?.fullName : item?.email,
                tooltip: item?.fullName ? item?.fullName : item?.email,
                isOrganization: true,
                subtile2: "",
                icon: {
                    imageURI: item?.image ? item?.image : `https://ui-avatars.com/api/?name=${item?.fullName ? item?.fullName : item?.email}`,
                    imgWidth: 40,
                    imgHeight: 40
                }
        });
    });

    let organizationIssuer = props.issuersList && props.issuersList.filter((item:any) => {
        if(item.organization!==null){
            return true;
        }
    });

    let _orgIssuers = Array.isArray(organizationIssuer) && organizationIssuer.map(item=>{
        return({
                key: item?.email,
                title: item?.organizationFull?.name ? item?.organizationFull?.name: item?.email,
                tooltip: item?.organizationFull?.name ? item?.organizationFull?.name: item?.email,
                isOrganization: true,
                subtile2: "",
                icon: {
                    imageURI: item?.organizationFull?.image ? item?.organizationFull?.image : `https://ui-avatars.com/api/?name=${item?.organizationFull?.name ? item?.organizationFull?.name: item?.email}`,
                    imgWidth: 40,
                    imgHeight: 40
                }
        });
    })

    console.log('indivisual issuer', organizationIssuer);

    const styles = {
        leftcontainerStyle: {
            display: 'flex',
        },
        rightContainerStyle: {
            float: 'right',
            padding: 10,
        },
        containerStyle: {
            backgroundColor: '#f7f7fa',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: 'none',
            padding: 20,
            minHeight:height - (height  * 0.15)
        },
    };

    const onNavItemClick = (eventKey: string) => {
        setUserActionKey(eventKey);
        if (eventKey === 'newIssuer') {
            let _drawer = { ...drawer };
            _drawer.title = "New Issuer";
            _drawer.placement = BP?.drawerPlacement;
            _drawer.show = true;
            setDrawer({
                ..._drawer,
            });
        }
    };


    const renderDrawerBody = () => {
        if (userActionKey === 'newIssuer') {
            return <InviteIssuer
                onInviteIssuer={props.onInviteIssuer}
            />;
        }
        return <div />;
    };

    const handleOnDrawerHide = () => {
        let _drawer = { ...drawer };
        _drawer.show = false;
        setDrawer({
            ..._drawer,
        });
    };

    const renderDrawerHeader = () => {
        return <div />;
    };

    const renderDrawerFooter = () => {
        return <div />;
    };

    const bodyContent = () => {
        if(props?.getUsersLoading){
            return <LogoSpinnerV imageStyle={{marginLeft:'40%'}}/>;
        }
        else {
            return (
                <ViewportProvider>
                     {props?.inviteUserLoading ?
                    <Loader center style={{ backgroundColor: '#000', opacity: 0.6, zIndex: 999 }} size={'md'} content={<span style={{ color: '#fff' }}>Inviting Holder...</span>} /> : null
                    }
              
                    <NetworkCredentialTemplateV
                         templateDetails={_indIssuers}
                         title={BP?.indivisualTitle}
                         titleStyle={{ fontSize: 18 }}
                         containerStyle={StyleSheet.flatten([styles.containerStyle])}
                         onCardClick={eventKey => console.log(eventKey)}
                         iconStyle={{ borderRadius: 20 }}
                    >
                        <Divider />
                        <NetworkCredentialTemplateV
                        templateDetails={_orgIssuers}
                        title={BP?.organizationTitle}
                        // onCardClick={eventKey => props.onCardClick(eventKey)}                   
                        titleStyle={{ fontSize: 18 }}
                        iconStyle={{ borderRadius: 20 }}  
                        />
    
                    </NetworkCredentialTemplateV>
                </ViewportProvider>
            );
        }
        
    };

    return (
        <>
            <BodyTemplateV
                primaryColor={BP?.primaryColor || theme?.palette.primary}
                primaryHighlightColor={
                    BP?.primaryHighlightColor || theme?.palette.secondary
                }
                secondaryColor={BP?.secondaryColor || theme?.palette.secondary}
                ternaryColor={BP?.ternaryColor || theme?.palette.ternary}
                renderContent={bodyContent}
                contentScrollableInline={true}
                headerTitleStyle={{ marginLeft: 20 }}
                navbarStyle={{}}
                layout={'sidebar-header-content-footer'}
                navBarRightItems={BP?.navRightItems}
                navBarLeftItems={BP?.navLeftItems}
                onNavItemSelect={onNavItemClick}
                headerBackgroundColor={'#fff'}
                // sideBarItems={BP?.nav_items}
                // onSideNavItemSelect={eventKey => setNavItem(eventKey)}
                renderFooter={() => <div />}
            />
            <DrawerV
                placement={drawer.placement}
                show={drawer.show}
                backdrop={drawer.backdrop}
                size={drawer.size}
                renderHeader={renderDrawerHeader}
                onHide={handleOnDrawerHide}
                drawerTitle={drawer.title}
                renderBody={renderDrawerBody}
                renderFooter={renderDrawerFooter}
            />
        </>
    );
};
