import React, { useContext, useLayoutEffect } from 'react';
import { View } from 'react-native';
import { StyleSheet } from 'react-native';
import config from './config/default-blueprint.json';
import { ThemeContext } from '../../../../../../providers/theme';
import { useViewport } from 'react-viewport-provider';
import { BodyTemplateV } from 'react-body-template-1';
import { Icon, Whisper, Tooltip, Divider, Avatar } from 'rsuite';
import { ButtonV, IconButtonV } from 'react-button-rs';
import { LogoSpinnerV } from 'react-logo-spinner-ext';
import { SelectInputV } from 'react-select-input-rs';
import Loader from 'rsuite/lib/Loader';

import { TableV, TableHeaderDataProps } from 'react-table-rs';
import { DrawerV } from 'react-drawer-rs';
import jsf from 'json-schema-faker';
import faker from 'faker';
import Chance from 'chance';

import { InviteUser } from './components/inviteUser/InviteUser';
import { IProps} from './index';

jsf.extend('faker', () => faker);
jsf.extend('chance', () => new Chance());

const humanizeString = require('humanize-string');
const BP = config.UserList;



type HolderListProps = {
    onSearchClick?(): void;
};
const selectField = [
    {
        label: 'Active Users',
        Value: 'Avtive Users',
    },
];
const styles = {
    leftcontainerStyle: {
        display: 'flex',
        alignItems: 'center',
    },
    rightContainerStyle: {
        float: 'right',
        display: 'flex',
    },
    tableStyle: {
        margin: '0 auto',
    },
    activeStyle: {
        backgroundColor: 'green',
        borderRadius: 15, width: 10, height: 10,
        marginRight: 5
    },
    InactiveStyle: {
        backgroundColor: 'orange',
        borderRadius: 15, width: 10, height: 10,
        marginRight: 5
    },
    revokedStyle: {
        backgroundColor: 'red',
        borderRadius: 15, width: 10, height: 10,
        marginRight: 5
    },
    statusStyle: { alignItems: 'center', display: 'flex', flexDirection: 'row' }
};
const generateFakeData = async (schema, rows = 1) => {
    console.log('schema', schema);
    let res = [];
    try {
        if (schema) {
            for (let i = 0; i < rows; ++i) {
                let _sample = { ...schema[i] };
                _sample.avatar = _sample?.image? _sample?.image: `https://ui-avatars.com/api/?name=${_sample.fullName}`;
                if (_sample.userActiveStatus === 'Active') {
                    _sample.userActiveStatus = <span style={StyleSheet.flatten([styles.statusStyle])}><Avatar style={StyleSheet.flatten([styles.activeStyle])} size={'xs'} />{_sample.userActiveStatus}</span>;
                }
                if (_sample.userActiveStatus === 'Invited') {
                    _sample.userActiveStatus = <span style={StyleSheet.flatten([styles.statusStyle])}><Avatar style={StyleSheet.flatten([styles.InactiveStyle])} />{_sample.userActiveStatus}</span>;
                }
                if (_sample.userActiveStatus === 'Revoked') {
                    _sample.userActiveStatus = <span style={StyleSheet.flatten([styles.statusStyle])}><Avatar style={StyleSheet.flatten([styles.revokedStyle])} />{_sample.userActiveStatus}</span>;
                }
                res.push(_sample);
            }
        }
    } catch (err) { }
    // try {
    //   if (schema) {
    //     for (let i = 0; i < rows; ++i) {
    //       const sample = await jsf.resolve(BP?.users);
    //       let _sample = {...sample.user};
    //       _sample.avatar = `https://ui-avatars.com/api/?name=${_sample.name}`;
    //       res.push(_sample);
    //     }
    //   }
    // } catch (err) {
    //   console.log('error in fake data generation', err);
    // }
    return res;
};

const columns: TableHeaderDataProps[] = [
    {
        label: 'AVATAR',
        dataKey: 'avatar',
        appearance: 'avatar',
        columnProps: {
            fixed: true,
            width: 80,
        },
        labelStyle: {
            color: '#606161',
            textTransform: 'uppercase',
        },
        headerCellStyle: {
            backgroundColor: '#f5f5f5',
            height: 50,
        },
    },
    {
        label: 'FULL NAME',
        dataKey: 'fullName',
        appearance: 'text',
        appearanceProps: {
            style: {
                // color: '#d3d3d3'
            },
        },
        columnProps: {
            sortable: true,
            width: 200,
        },
        cellStyle: {
            //  backgroundColor: '#d3d3d3'
        },
        labelStyle: {
            color: '#606161',
            textTransform: 'uppercase',
        },
        headerCellStyle: {
            backgroundColor: '#f5f5f5',
            height: 50,
        },
    },
    {
        label: 'EMAIL',
        dataKey: 'email',
        appearance: 'email',
        columnProps: {
            sortable: true,
            width: 200,
        },
        labelStyle: {
            color: '#606161',
            textTransform: 'uppercase',
        },
        headerCellStyle: {
            backgroundColor: '#f5f5f5',
            height: 50,
        },
    },
    {
        label: 'User Status',
        dataKey: 'userActiveStatus',
        appearance: 'text',
        columnProps: {
            sortable: true,
            width: 200,
        },
        labelStyle: {
            color: '#606161',
            textTransform: 'uppercase',
        },
        headerCellStyle: {
            backgroundColor: '#f5f5f5',
            height: 50,
        },
    },
];

export const HoldersListLG = (props: Iprops) => {
    const theme = useContext(ThemeContext);
    const [data, setData] = React.useState<any>([]);
    const [userActionKey, setUserActionKey] = React.useState('');
    const [drawer, setDrawer] = React.useState<any>({
        placement: 'right',
        title: '',
        size: 'xs',
        backdrop: false,
        show: false,
    });
    console.log('Holders list', props.holdersList); 
    const schema = props.holdersList? props.holdersList : BP?.users;

    React.useEffect(() => {
        generateFakeData(schema, schema.length).then((res) => {
            console.log('result', res);
            res && Array.isArray(res) && setData([...res]);
        });
    }, [props.holdersList]);

    const renderDrawerBody = () => {
        if (userActionKey === 'newUser') {
            return <InviteUser 
            onInviteHolders={props.onInviteHolders}
            />;
        } else if (userActionKey === 'filter') {
            return <UserFilter />;
        }
        return <div />;
    };

    const handleOnDrawerHide = () => {
        let _drawer = { ...drawer };
        _drawer.show = false;
        setDrawer({
            ..._drawer,
        });
    };

    const renderDrawerHeader = () => {
        return <div />;
    };

    const renderDrawerFooter = () => {
        return <div />;
    };

    const bodyContent = () => {
        if(props?.getUsersLoading){
            return <LogoSpinnerV imageStyle={{marginLeft:'40%'}}/>;
        }
        else {
            return (
                <div style={StyleSheet.flatten([styles.tableStyle])}>
                    {props?.inviteUserLoading ?
              <Loader center style={{ backgroundColor: '#000', opacity: 0.6, zIndex: 999 }} size={'md'} content={<span style={{ color: '#fff' }}>Inviting Holder...</span>} /> : null
            }
                    <TableV
                        onRowClick={(data) => console.log('row clicked', data)}
                        table={{
                            autoHeight:true,
                            headerHeight: 40,
                            rowHeight: 50,
                            wordWrap: true,
                            //cellBordered: true,
                            // data: [...data],
                            data:[...data]
                        }}
                        columns={columns}
                        pagination={{
                            lengthMenu:BP?.LENGTH_MENU
                        }}
                    />
                </div>
            );
        }
        
    };
    const onNavItemClick = (eventKey: string) => {
        setUserActionKey(eventKey);
        if (eventKey === 'newUser') {
            let _drawer = { ...drawer };
            _drawer.title = "New User";
            _drawer.placement = BP?.drawerPlacement;
            _drawer.show = true;
            setDrawer({
                ..._drawer,
            });
        }
    };
    return (
        <>
            <BodyTemplateV
                primaryColor={BP?.primaryColor || theme?.palette.primary}
                primaryHighlightColor={
                    BP?.primaryHighlightColor || theme?.palette.secondary
                }
                secondaryColor={BP?.secondaryColor || theme?.palette.secondary}
                ternaryColor={BP?.ternaryColor || theme?.palette.ternary}
                renderContent={bodyContent}
                contentScrollableInline={true}
                headerTitleStyle={{ marginLeft: 20 }}
                navbarStyle={{}}
                layout={'sidebar-header-content-footer'}
                navBarRightItems={BP?.navRightItems}
                navBarLeftItems={BP?.navLeftItems}
                onNavItemSelect={onNavItemClick}
                headerBackgroundColor={'#fff'}
                renderFooter={() => <div />}
            />
            <DrawerV
                placement={drawer.placement}
                show={drawer.show}
                backdrop={drawer.backdrop}
                size={drawer.size}
                renderHeader={renderDrawerHeader}
                onHide={handleOnDrawerHide}
                drawerTitle={drawer.title}
                renderBody={renderDrawerBody}
                renderFooter={renderDrawerFooter}
            />
        </>
    );
};
