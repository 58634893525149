import React, { useContext, useEffect, useState } from 'react';
import { AvatarV, AvatarSize } from 'react-native-avatar-vfw';
import { BodyTemplateV } from 'react-body-template1';
import { DrawerV } from 'react-drawer-rs';
import { FBGridV, FBItemV } from 'react-flexbox-grid-rs';
import { IconV } from 'react-icon-rs';
import { StyleSheet, View } from 'react-native';
import { ButtonV } from 'react-button-rs';
import { TextV } from 'react-native-text-vfw';
import Avatar from 'rsuite/lib/Avatar';
import { NavV } from 'react-nav-rs';
import { FormV, FORM_VALUES, defaultValuesDS } from 'react-form-rs';
import { EventBus } from 'event-bus-vfs';
import { LogoSpinnerV } from 'react-logo-spinner-ext';
import { ThemeContext } from '../../../../../providers/theme';
import blueprint from '../../../config/default-blueprint.json';
import {useAuth} from '../../../../../services/auth-service/auth.provider';

const TITLE_COLOR = '#636669';

const BP = blueprint.Admins;

interface AdminProps {
  admins?: [];
  userData?: [];
  navItems?:[];
  adminLoading?:boolean;
  onInviteAdmin?(formData?:any):void,
}
let formContext = {};

export const Admins = (props: AdminProps) => {
  let selectedUsers = [];
  const parentRef = React.useRef(() => { });
  const theme = useContext(ThemeContext);
  const {getActor} = useAuth();
  const [tab, setTab] = useState<string>('admins');
  const [validateForm, setValidateForm] = useState<any>(null);
  const [search, setSearch] = useState(null);
  const [actor, setActor] = useState<string>();
  const [drawer, setDrawer] = useState<any>({
    placement: 'right',
    title: '',
    size: 'xs',
    backdrop: false,
    show: false,
  });
  useEffect(() => {
    const app = EventBus.on(FORM_VALUES, value => {
        formContext = { ...value };
    });
    return function () {
        app.off();
    };
}, []);

  useEffect(()=> {
  getActor().then(actor=>{
    if(actor){
      setActor(actor);
    }
  });
  },[]);

  const styles = {
    nameTextStyle: {
      fontSize: 18,
    },
    roleTextStyle: {},
    addAvatarStyle: {
      color: theme?.palette.secondary,
      backgroundColor: 'transparent',
      borderRight: `2px dotted ${theme?.palette.secondary}`,
    },
    addAvatarContainerStyle: {
      backgroundColor: 'transparent',
      justifyContent: 'center',
      borderRight: `2px dotted ${theme?.palette.secondary}`,
      borderSpacing: '25px',
      borderCollapse: 'separate',
    },
    detailsContainerStyle: {
      flexDirection: 'row',
      paddingTop: 30,
      paddingLeft: 10,
    },
    buttonTitleStyle: {
      color: theme?.palette.ternary,
    },
    buttonContainerStyle: {
      marginTop: 30,
      marginRight: '2%',
      width: 120,
    },
    inviteButtonStyle: {
      backgroundColor: theme?.palette.secondary,
      borderRadius: 5,
    },
    cancelButtonStyle: {
      backgroundColor: '#ede1e5',
      borderRadius: 5,
    },
    cancelButtonTitleStyle: {
      color: '#000',
    },
    drawerHeaderTextStyle: {
      fontSize: 18,
      fontWeight: 600,
      lineSpacing: 2,
      letterSpacing: 1,
      textAlign: 'center',
      padding: 5,
      color: TITLE_COLOR,
    },
    formStyle: {
      width: '100%',
      backgroundColor: '#ffffff',
      justifyContent: 'flex-start',
      alignItems: 'center',
      borderRadius: 10,
  }
  };

  const updateSearch = (search) => {
    setSearch(search);
  };

  const handleValidateResult = (
    key: string,
    formContext?: any,
    status?: 'success' | 'error',
    validateResult: any,
) => {
    let formData = {
        email: defaultValuesDS.get('emailAddress')?.defaultValue
    }
    props.onInviteAdmin &&
        props.onInviteAdmin(formData);
};

  const renderDrawerHeader = () => {
    return (
      <View>
        <TextV
          type="title3"
          style={StyleSheet.flatten([styles.drawerHeaderTextStyle])}
        >
          {BP.drawerHeaderTitle}
        </TextV>
      </View>
    );
  };

  const renderDrawerBody = () => {
    const data = props.userData ?? BP.userData;
    return (
      <FBGridV justify="center" style={{ justifyContent: 'center' }}>
            <FBItemV colspan={24}>
            <FormV
                    components={BP?.formFields}
                    formContext={formContext}
                    containerStyle={StyleSheet.flatten([styles.formStyle])}
                    validateForm={validateForm}
                    onValidateResult={handleValidateResult}
                    defaultValues={defaultValuesDS.get()}
                    ref={parentRef}
                />
            </FBItemV>
            <FBItemV colspan={24}>
                <ButtonV
                    appearance={'primary'}
                    color={'red'}
                    buttonTitle={BP?.submitButton?.buttonTitle}
                    buttonTitleStyle={BP?.submitButton?.buttonTitleStyle}
                    containerStyle={{
                        width: '100%',
                        margin: '0 auto',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                    }}
                    onPress={id => {
                        // setValidateForm(true);
                        //  handleValidateResult
                        console.log('formContext', formContext);
                        setValidateForm({
                          ...formContext,
                        });
                    }}
                />
            </FBItemV>
        </FBGridV>
      // <View>
      //   <TagPickerV
      //     data={data}
      //     tagPickerStyle={{ width: '100%' }}
      //     onChange={(value) => {
      //       selectedUsers = [value];
      //     }}
      //   />
      //   <View style={{ flexDirection: 'row' }}>
      //     <ButtonV
      //       title={BP.addButtonText}
      //       containerStyle={StyleSheet.flatten([styles.buttonContainerStyle])}
      //       buttonStyle={StyleSheet.flatten([styles.inviteButtonStyle])}
      //       onPress={_handleInviteUser}
      //     />

      //     <ButtonV
      //       title={BP.cancelButtonText}
      //       buttonStyle={StyleSheet.flatten([styles.cancelButtonStyle])}
      //       containerStyle={StyleSheet.flatten([styles.buttonContainerStyle])}
      //       titleStyle={StyleSheet.flatten([styles.cancelButtonTitleStyle])}
      //       onPress={handleOnDrawerHide}
      //     />
      //   </View>
      // </View>
    );
  };
  const handleOnDrawerHide = () => {
    let _drawer = { ...drawer };
    _drawer.show = false;
    setDrawer({
      ..._drawer,
    });
  };

  const renderDrawerFooter = () => {
    return null;
  };

  const _getDetails = () => {
    let _admins; 
    if(actor === 'NETWORK_OPERATOR_ADMIN'){
      _admins = Array.isArray(props?.admins) && props?.admins.filter(item=>{
        if(item?.features.includes("NETWORK_OPERATOR_ADMIN") || item?.features.includes("NETWORK_OPERATOR")){
          return true;
        }
      });
    }
    else if(actor === 'VERIFIER_ADMIN'){
      _admins = Array.isArray(props?.admins) && props?.admins.filter(item=>{
        if(item?.features.includes("VERIFIER_ADMIN") || item?.features.includes("VERIFIER")){
          return true;
        }
      })
    }
    else if(actor === 'ISSUER_ADMIN'){
      _admins = Array.isArray(props?.admins) && props?.admins.filter(item=>{
        if(item?.features.includes("ISSUER_ADMIN") || item?.features.includes("ISSUER")){
          return true;
        }
      })
    }

    let data = Array.isArray(_admins) && _admins.map(item=>{
      return({
          "avatar": item?.image ? item?.image : `https://ui-avatars.com/api/?name=${item?.fullName}`,
          "name": item?.fullName,
          "email": item?.email,
          "role": (item?.features.includes("NETWORK_OPERATOR_ADMIN") || item?.features.includes("VERIFIER_ADMIN") || item?.features.includes("ISSUER_ADMIN"))? "Owner": null
      });
    })
    let admin = [];
    console.log('admins inside component', _admins);
    // const data = props.admins ? props.admins : BP.admins;
    data &&
      data.forEach((element, index) => {
        admin.push(
          <FBItemV colspan={5}>
            <View style={StyleSheet.flatten([styles.detailsContainerStyle])}>
              <AvatarV rounded size={AvatarSize.Medium} source={{ uri: element?.avatar }} />

              <View style={{ paddingLeft: 10 }}>
                <TextV style={StyleSheet.flatten([styles.nameTextStyle])}>
                  {element.name}
                </TextV>
                <TextV>
                  <a href={`mailto:${element.email}`}>{element.email}</a>
                </TextV>
                <TextV style={StyleSheet.flatten([styles.roleTextStyle])}>
                  {element.role}
                </TextV>
              </View>
            </View>
          </FBItemV>
        );
      });
    return admin;
  };

  const _handleAddAdmin = () => {
    console.log(`handle admin invite drawer`);
    let _drawer = { ...drawer };
    _drawer.show = true;
    setDrawer({
      ..._drawer,
    });
  };

  const bodyContent = () => {
    if (tab === 'admins') {
      if(props?.adminLoading){
        return <LogoSpinnerV imageStyle={{marginLeft:'40%'}}/>;
      }
    else {
      return (
        <FBGridV justify="start">
          {/* <View style={{paddingTop: 30, paddingLeft: 10}}></View> */}
          <FBItemV colspan={2}>
            <div style={{ paddingTop: 30, paddingLeft: 30 }}
            onClick={()=>_handleAddAdmin()}
            >
              <Avatar
                circle={false}
                size={'md'}
                // src={'https://i.ibb.co/NjNwyXD/images.jpg'}
                style={StyleSheet.flatten([styles.addAvatarStyle])}
                containerStyle={StyleSheet.flatten([
                  styles.addAvatarContainerStyle,
                ])}
              >
                <IconV
                  icons={[
                    {
                      name: 'plus-square-o',
                      size: '2x',
                    },
                  ]}
                />
              </Avatar>
              {/* <Divider
                orientation="vertical"
                flexItem
                style={{width: 5, marginLeft: 10, marginRight: 5}}
              /> */}
            </div>
          </FBItemV>
          {_getDetails()}
        </FBGridV>
      );
    }    
    }
  };
  const _getTabBody = (activeKey: string) => {
    if (activeKey === 'admins') {
      setTab(activeKey);
    } else if (activeKey === 'credentialAdmins') {
      setTab(activeKey);
    }
  };
  const hederLeftData = () => {
    return (
      <NavV
        items={props.navItems || BP.navItems}
        appearance="tabs"
        onSelect={(activeKey: string) => {
          _getTabBody(activeKey);
        }}
      />
    );
  };
  const headerRightData = () => { };
  return (
    <View style={{ backgroundColor: '#fff' }}>
      <BodyTemplateV
        width={'100%'}
        height={'100vh'}
        // image={'https://i.ibb.co/8s7gp1t/pattern-1.png'}
        renderContent={bodyContent}
        headerLeftData={hederLeftData}
        headerRightData={headerRightData}
      />
      <DrawerV
        placement={drawer.placement}
        show={drawer.show}
        backdrop={drawer.backdrop}
        size={drawer.size}
        renderHeader={renderDrawerHeader}
        onHide={handleOnDrawerHide}
        drawerTitle={drawer.title}
        renderBody={renderDrawerBody}
        renderFooter={renderDrawerFooter}
      />
    </View>
  );
};
