import {useLazyQuery, useQuery, useMutation} from '@apollo/react-hooks';
import {useEffect} from 'react';
import {createNewDataPipeFrom, DataSet} from 'vis-data';
import { VERIFY_EMAIL, CREATE_ACCOUNT, AUTO_LOGIN_USER, CREATE_ORGANIZATION } from './mutation';
import BP from './config/default-blueprint.json';

export const useProvRegistration = () => {

// const pipeTransformation = () => {
//         //@ts-ignore
//         const pipe = createNewDataPipeFrom(provHoldersDS)
//           //@ts-ignore
//           .map((item: any) => ({
//             //
//           }))
//           //@ts-ignore
//           .to(provHoldersTransformedDS);
//         pipe.all().start();
//       };
    const [VerifyEmail, { 
        data:verifyEmailData, loading:verifyEmailLoading, error:verifyEmailError
    }] = useMutation(VERIFY_EMAIL);

    const [CreateAccount, {data:createAccountData , loading:createAccountLoading , error:createAccountError }] = useMutation(CREATE_ACCOUNT);
    const [LoginUser, {data: loginUserData, loading:loginUserLoading, error: loginUserError}] = useMutation(AUTO_LOGIN_USER);
    const [CreateOrg, {data: createOrgData, loading: createOrgLoading, error: createOrgError}] = useMutation(CREATE_ORGANIZATION);

    const _getUserType = () => {
        let _verifyEmail
        if(verifyEmailData && verifyEmailData?.verifyEmail){
            _verifyEmail = verifyEmailData?.verifyEmail?.features
        }
        return _verifyEmail
        
    }

    const _getItems = () => {
        let _stepItems;
        let isAdmin = Array.isArray(userType) && userType.includes("ISSUER_ADMIN") || Array.isArray(userType) && userType.includes("VERIFIER_ADMIN")

        if(isAdmin){
            _stepItems = BP?.RegistrationPage?.adminItems;
        }
        else {
            _stepItems = BP?.RegistrationPage?.holderItems;
        }
        return _stepItems;
    }

    const userType = _getUserType();

    const stepItems = _getItems();

    return {
     VerifyEmail,
     verifyEmailData,
     userType,
     stepItems,
     verifyEmailError,
     verifyEmailLoading,
     CreateAccount,
     createAccountData,
     createAccountLoading,
     createAccountError,
     LoginUser,
     loginUserData,
     loginUserLoading,
     loginUserError,
     CreateOrg,
     createOrgData,
     createOrgLoading,
     createOrgError
    };
};