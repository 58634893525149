import React, { useContext, useState } from 'react';
import { View, Text } from 'react-native';
import { StyleSheet } from 'react-native';
import config from './config/default-blueprint.json';
import { ThemeContext } from '../../../../../../../../providers/theme';
import { pretty } from 'react-native-helpers';
import { useViewport } from 'react-viewport-provider';
import { BodyTemplateV } from 'react-body-template-1';
import { Icon, Whisper, Tooltip, Divider } from 'rsuite';
import { ButtonV, IconButtonV } from 'react-button-rs';
import { ButtonV as NativeButton } from 'react-native-button-vfw';
import { IconV } from 'react-icon-rs';
import { CheckboxV } from 'react-checkbox-rs';
import { SelectInputV } from 'react-select-input-rs';
import { ConsoleLogger, LoggerService } from 'logger-extension-vfs';
import { TableV, TableHeaderDataProps } from 'react-table-rs';
import { StepV } from 'react-steps-rs';
import { DrawerV } from 'react-drawer-rs';
import jsf from 'json-schema-faker';
import faker from 'faker';
import Chance from 'chance';
import { SelectUserForm } from '../UserSelection/SelectUserForm';
import {CheckPickerV} from 'react-checkpicker-rs';
import { StepTemplateV } from 'react-step-rs-template';
import { SignVerification } from '../SignVerificationRequest';
import { LogoSpinnerV } from 'react-logo-spinner-ext';

jsf.extend('faker', () => faker);
jsf.extend('chance', () => new Chance());

const humanizeString = require('humanize-string');
const BP = config.HealthCareList;
const console: LoggerService = new LoggerService(
  new ConsoleLogger('Dashboard:HealthCareList'),
);
const schema = BP?.list;

type IProps = {
  onSearchClick?(): void;
  drawerWidth: number;
  holdersList?:any;
  claims?:any;
  onVerifyRequest?(requests?:any):void;
  reqCredLoading?:boolean
};
const selectField = [
  {
    label: 'Active Users',
    Value: 'Avtive Users',
  },
];


let selectedCredential: {
  key: string;
  value: any[];
  selected: boolean;
  reason: string;
  essential: boolean;
}[] = [];

export const VerificationRequest = (props: IProps) => {
  const [enable, setEnable] = React.useState(true);
  const [step, setStep] = React.useState<number>(0);
  const theme = useContext(ThemeContext);
  const { height, width } = useViewport();
  const [screen, setScreen] = useState<string>('tableScreen');
  const [users, setUsers]= useState<any>([]);
  const [drawer, setDrawer] = useState<any>({
    placement: 'right',
    title: '',
    size: 'sm',
    backdrop: false,
    show: false,
  });

  if (screen !== 'stepper') {
    selectedCredential = [];
  }

  const styles = {
    containerStyle: {
      width:'100%',
      alignItems: 'center',
      display: 'inline-block',
      paddingLeft: 30,
      paddingTop: 10,
    },
    leftcontainerStyle: {
      display: 'flex',
    },
    rightContainerStyle: {
      float: 'right',
    },
    twoColStyle: {
      display: 'block',
    },
    tableStyle: {
      width: width - props.drawerWidth + 70,
      // margin: '0 auto',
    },
    iconStyle: {
      height: 50,
      width: 50,
      paddingRight: 10,
    },
    submitButtonStyle: {
      backgroundColor: theme?.palette.secondary,
      borderRadius: 10,
    },
  };

  const onselectedUsersChange = (value:string) => {
    // let _selectedUsers=  value.split(',');
    console.info(`selected Users: ${pretty(value)}`);
    setUsers(value);
  }

  const onVerifyRequest = ()=>{
    console.info(`users on complete press, ${pretty(users)}`);
    console.info(`on vrify complete claims ${pretty(props?.claims)}`);
    let _requests = Array.isArray(users) && users.map(item=>{
        return({
          toEmail:item,
          claims: props?.claims
        });
      });
    console.info(`on request submit, ${pretty(_requests)}`);
    props.onVerifyRequest && props.onVerifyRequest(_requests);
  }


  const _getData = (key: string) => {
    console.info(`users List: ${pretty(props.holdersList)}`);
    let _registeredUsers = Array.isArray(props?.holdersList) && props?.holdersList.filter(item=>{
      if(item.isWalletRegistered === true){
          return true;
      }
  });
  let userList = Array.isArray(_registeredUsers) && _registeredUsers.map(item => {
    return({
            key:item.key,
            label:item.fullName,
            value:item.email,
            // email:item.email,
            // avatar:item?.image ? item?.image : `https://ui-avatars.com/api/?name=${item?.fullName}`
        })
});
    switch (key) {
      case 'selectUser':
        if(props?.reqCredLoading){
          return <LogoSpinnerV imageStyle={{left:'50%'}}/>
        }
        else {
          return (
            <div style={StyleSheet.flatten([styles.containerStyle])}>
            <CheckPickerV
              data={userList}
              onChange={value=>onselectedUsersChange(value)}
              checkPickerStyle={{width:280}}
              titleContainerStyle={{padding:5, borderBottom:'1px dotted #ccc'}}
              size={"lg"}
            />
            </div>
          );
        }
        
      case 'request':
        return <SignVerification />;
      default:
        return null;
    }
  };

  const renderContent = (key: string) => {
    console.info(`key ${key}`);
    return _getData(key);
  };

  const _handleStepperNext = (currentStep: any, currentKey: string) => {
    let credCount = 0;
    let userCount = 0;
    if (selectedCredential && selectedCredential.length > 0) {
      selectedCredential.forEach(element => {
        if (element.selected) {
          credCount++;
        }
      });
      console.trace(`users Selected :: ${pretty(selectedUsers)}`);
      if (selectedUsers && selectedUsers.length > 0) {
        userCount++;
      }
    }
    if (credCount > 0 && userCount > 0) {
      setStep(1);
    }
  };

  const _handleStepperPrev = (currentStep: any, currentKey: string) => {
    setStep(0);
  };


  const renderDrawerBody = () => {
    
    return <div />;
  };

  const handleOnDrawerHide = () => {
    let _drawer = { ...drawer };
    _drawer.show = false;
    setDrawer({
      ..._drawer,
    });
  };

  const renderDrawerHeader = () => {
    return <div />;
  };

  const renderDrawerFooter = () => {
    return <div />;
  };

  const bodyContent = () => {
    return (
      <StepTemplateV
        width={'100%'}
        height={'90vh'}
        step={step}
        bordered
        shaded
        items={BP.steps}
        renderStepperContent={renderContent}
        stepIconOnly={true}
        stepperColSpan={1}
        headerTitleToDisplay={true}
        stepperBackgroundColor={BP?.primaryColor || theme?.palette.secondary}
        stepperIconActiveColor={theme?.palette.secondary}
        onNext={_handleStepperNext}
        onPrev={_handleStepperPrev}
        loading={false}
        stepperPrevTitle={'Previous'}
        stepperNextTitle={'Next'}
        enableNextonCurrentStep={enable}
        enablePrevonCurrentStep={enable}
        onStepperDonePress={onVerifyRequest}
      />
    );
  };

  return (
    <>
      <BodyTemplateV
        primaryColor={BP?.primaryColor || theme?.palette.primary}
        primaryHighlightColor={
          BP?.primaryHighlightColor || theme?.palette.secondary
        }
        secondaryColor={BP?.secondaryColor || theme?.palette.secondary}
        ternaryColor={BP?.ternaryColor || theme?.palette.ternary}
        renderContent={bodyContent}
        contentScrollableInline={true}
        headerTitleStyle={{ marginLeft: 20 }}
        navbarStyle={{}}
        layout={'sidebar-header-content-footer'}
        // onNavItemSelect={eventKey => setNavItem(eventKey)}
        // sideBarItems={BP?.nav_items}
        // onSideNavItemSelect={eventKey => setNavItem(eventKey)}
        renderFooter={() => <div />}
      />
      <DrawerV
        placement={drawer.placement}
        show={drawer.show}
        backdrop={drawer.backdrop}
        size={drawer.size}
        renderHeader={renderDrawerHeader}
        onHide={handleOnDrawerHide}
        drawerTitle={drawer.title}
        renderBody={renderDrawerBody}
        renderFooter={renderDrawerFooter}
      />
    </>
  );
};
