import React, {useContext } from 'react';
import {ProfileTemplateV} from 'react-profile-template-rs';
import { FBGridV, FBItemV } from 'react-flexbox-grid-rs';
import {ViewportProvider, useViewport} from 'react-viewport-provider'
import {Text, View, StyleSheet} from 'react-native';
import Icon from 'rsuite/lib/Icon';
import { Avatar } from 'react-native-elements';
import {ThemeContext} from '../../../../providers/theme'
import {IProps} from './index';
import blueprint from './config/default-blueprint.json';

const BP = blueprint?.verifierDetails;

export const VerifierSM = (props:IProps) => {
    const theme = useContext(ThemeContext);
    const {width} = useViewport();
    const styles = {
        contactItemStyle: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center'
        },
        footerColumnStyle: {
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            textAlign: 'left',
            margin: '0 auto'
        },
        footerHeaderStyle: {
            paddingLeft: '5%',
            paddingBottom: 15,
            color: '#fff',
            fontSize: 20,
            fontWeight: 600,
            display: 'block'
        },
        footerTextStyle: {
            paddingLeft: '5%',
            textAling: 'center',
            fontSize: 12,
            color: '#fff',
        },
        footerContainerStyle: {
            backgroundColor: '#25242A',
            padding: '1% 5%'
        },
        headerStyle: {
            backgroundColor: theme?.palette?.primary,
            width: width * 0.99,
            paddingTop: 10,
            paddingBottom: 10,
            paddingRight: '5%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center'
        },
        hederLogoStyle: {
            marginLeft: '5%'
        }
    }
    let _credentialDetails = Array.isArray(props.credentialData?.details) && props.credentialData?.details.map(item=>{
        return({
            ...item,
            name: item?.label
        })
    })

    const _getContactDetails = () => {
        let contact: any[] = [];
        const contactData = BP?.footerContent?.contactDetail;
        if (contactData && Array.isArray(contactData)) {
            contactData.forEach((element, i) => {
                contact.push(
                    <View style={StyleSheet.flatten([styles.contactItemStyle])}>
                        <Icon style={{ backgroundColor: '#fff', padding: 5, borderRadius: '50%', margin: 5, color: theme?.palette?.primary }} icon={element.icon} size={40} />
                        <Text style={{ textAlign: 'left', fontSize: 14, color: '#fff' }}>{element.title}</Text>
                    </View>
                )
            })
        }
        return contact;
    }
    const _getFooterContent = () => {
        let footerContent = props.footerContent || BP?.footerContent;
        return (
            <>
                <FBItemV colspan={6} style={{ textAlign: 'center' }}>
                    {/* <Text style={StyleSheet.flatten([styles.footerHeaderStyle])}>{footerContent?.socialTitle}</Text> */}
                    <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', }}>
                        {
                            footerContent && Array.isArray(footerContent?.socialIcons) ?
                                footerContent?.socialIcons.map((item, key) => {
                                    return (
                                        <a href={item.link} target="_blank" style={{ padding: 5 }}>
                                            <Avatar source={{ uri: item.icon }} size={20} />
                                        </a>
                                    )
                                }) : null
                        }</View>
                </FBItemV>
                <FBItemV colspan={8} style={{ textAlign: 'center', paddingTop: 5 }}>
                    <a href={footerContent?.copyRightLink} target="_blank"> <img src={footerContent?.copyRightLogo} width={80} height={20} style={{ display: 'block', margin: '0 auto' }} /> </a>

                </FBItemV>
                <FBItemV colspan={10} style={{ textAlign: 'center', paddingTop: 5 }}>
                    <Text style={StyleSheet.flatten(styles.footerTextStyle)}><Icon icon='copyright' size={8} />{' '}{footerContent?.copyRightText}</Text>
                </FBItemV>

            </>
        )
    }
    return (
        <>
        <View style={StyleSheet.flatten([styles.headerStyle])}>
                <div onClick={()=> props.headerLogoClick && props.headerLogoClick()}>
                <img src={BP?.headerLogo} width={100} height={'auto'} style={styles.hederLogoStyle} />
                </div>
                {/* <ButtonV
                    buttonTitle={BP?.VerifyButtot}
                    color={'orange'}
                    onPress={id => onVerifyButtonPress()}
                    containerStyle={StyleSheet.flatten([styles.buttonStyle])}
                /> */}

            </View>
        <ProfileTemplateV
            containerStyle={{width:'100%', margin:'10px auto'}} 
            avatar={props.credentialData?.image}
            name={props.credentialData?.title}
            docNumber={props.credentialData?.issuer}
            profileDetails={_credentialDetails}
            numberOfCol={"single"}
        />
        <FBGridV justify={'start'} style={StyleSheet.flatten([styles.footerContainerStyle])}>
                {_getFooterContent()}
            </FBGridV>
        </>
    );
}