import React from 'react';
import { useViewport } from 'react-viewport-provider';
import { GroupsLG } from './Groups.lg';

export interface IProps {
    onSearchClick?(): void;
    onCardClick?(eventKey?: string): void;
    drawerWidth: number;
    inviteIssuerFormData?(
        formContext: any,
        status: 'success' | 'error',
        validateResult: any,
    ): void;
}

export const HolderGroups = (props: IProps) => {
    const { bp } = useViewport();

    // if (bp === 'xs' || bp === 'sm') {
    //     return <DashboardSM />;
    // }

    // if (bp === 'md') {
    //   return <DashboardMD />;
    // }

    //bp === 'lg' || bp === 'xl' || bp === 'md
    return <GroupsLG {...props} />;
};
