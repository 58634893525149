
import { ConsoleLogger, LoggerService } from 'logger-extension-vfs';
import React, { useState, useEffect } from 'react';
import { pretty } from 'react-native-helpers';
import { Dashboard } from './components';
import { useProvDashboard } from './provider';
import { useProvLogin } from '../Login/provider';
import Alert from 'rsuite/lib/Alert';
import { useAuth } from '../../services/auth-service/auth.provider';
import { defaultValuesDS } from 'react-form-rs';

// const console: LoggerService = new LoggerService(
//   new ConsoleLogger('Dashboard:container'),
// );


export const DashboardView = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [userProfile, setUSerProfile] = useState<any>(null);
  const [createUserStatus, setCreateUserStatus] = useState<boolean>(false);

  const {
    holdersData,
    getUserError,
    getUserLoading,
    CreateUser,
    createUserData,
    createUserError,
    createUserLoading,
    GetHolders,
    FindTemplates,
    provTemplateFormData,
    IssueCredential,
    issueCredData,
    issueCredError,
    issueCredLoading,
    CredentialCategory,
    credCategoryData,
    credentialCategoryLoading,
    credentialCategoryError,
    templateFormLoading,
    templateFormError,
    getMyGraph,
    trustNetworkData,
    networkLoading,
    RequestCredentials,
    reqCredData,
    reqCredLoading,
    reqCredError,
    getOrganizationProfile,
    getOperatorProfile,
    orgProfData,
    operatorProfData,
    issuersList,
    verifierList,
    getAdmins,
    adminUsers,
    getAdminsLoading,
    getAdminsError,
    netwrokCredData,
    getPresentationDetials,
    presentationDetail,
    presentationDetailLoading,
    ResendCredential,
    resendCredentialData,
    resendCredentialLoading
  } = useProvDashboard();

  const { sideNavItems } = useProvLogin();

  const { getUserProfile } = useAuth();

  useEffect(() => {
    getUserProfile().then(userProfile => {
      console.log('userprofile', userProfile);
      setUSerProfile(userProfile)
    });
    getMyGraph({
      variables: {
        offset: 0
      }
    });
    getOperatorProfile({
      variables: {}
    });

    if (issueCredData) {
      defaultValuesDS.clear();
      Alert.success("Credential Issued Successful", 2000);
    }
    if (issueCredError) {
      Alert.error("Issue credential failed please try again", 10000);
    }
    if (templateFormError) {
      Alert.error("Rendering credential template failed, please try again", 10000);
    }
    if (credentialCategoryError) {
      Alert.error("Rendering credential category failed, please try again", 3000);
    }
  },[issueCredData, issueCredError, templateFormError, credentialCategoryError])

  useEffect(() => {
    if (reqCredData) {
      Alert.success("Credential Request sent successfully", 5000);
    }
    if (reqCredError) {
      Alert.error("Credential Request Failed", 10000);
    }
  }, [reqCredData, reqCredError]);

  useEffect(()=>{
    if (getAdminsError) {
      Alert.success("Fetching admin list failed", 5000);
    }
  },[getAdminsError]);

  useEffect(()=>{
    if(resendCredentialData){
      Alert.success("Resend credential Successful", 5000);
    }
  }, [resendCredentialData])

  useEffect(()=>{
    if (createUserError && createUserError.graphQLErrors[0]) {
      console.log('createUser Error', createUserError.graphQLErrors[0]);
      Alert.error(`${createUserError.graphQLErrors[0].message}`, 10000);
    }
  }, [createUserError]);

  const onInviteIssuer = (formData) => {
    console.log('invite Issuer', formData);
    CreateUser({
      variables: {
        user: {
          email: formData?.email
        },
        type: "ISSUER_ADMIN",
        organizationName: formData?.organization
      }
    });
  };

  const onInviteHolders = (formData) => {
    console.log('invite holder', formData);
    CreateUser({
      variables: {
        user: {
          email: formData?.email
        },
        type: "HOLDER"
      }
    });
  };

  const onInviteVerifier = (formData) => {
    console.log('invite Verifier', formData);
    CreateUser({
      variables: {
        user: {
          email: formData?.email
        },
        type: "VERIFIER_ADMIN",
        organizationName: formData?.organization
      }
    });
  };

  const onInviteAdmin = (formData) => {
    console.log('invite admin', formData);
    // CreateUser({
    //   variables: {
    //     user: {
    //       email: formData?.email
    //     },
    //     type: "NETWORK_OPERATOR"
    //   }
    // });
  };

  const onNavSelect = (eventKey: string) => {
    console.log('inside nav select', eventKey);
    if (eventKey === 'credentials' || eventKey === 'verification') {
      CredentialCategory({
        variables: {}
      });
    }
    if (eventKey === 'organization') {
      getOrganizationProfile({
        variables: {}
      });
    }
    if(eventKey === 'admins') {
      getAdmins({
        variables: {}
      });
    }
    if(eventKey === 'trustNetwork'){
      getMyGraph({
        variables: {
          offset: 0
        }
      });
    }
    if(eventKey === 'verifiers'){
      GetHolders({
        variables: {}
      });
    }
    if(eventKey === 'issuers'){
      GetHolders({
        variables: {}
      });
    }
    if(eventKey === 'users'){
      GetHolders({
        variables: {}
      });
    }
    
  }

  if (createUserData) {
    if (!createUserStatus) {
      setCreateUserStatus(true);
    }
    Alert.success('User Invited Scuccessfully', 2000);
  }


  if (createUserLoading) {
    if (!loading) {
      setLoading(true)
    }

  }

  const onTemplateSelect = (eventKey: any) => {
    console.log('category click', eventKey);
    FindTemplates({
      variables: {
        templateKeys: eventKey
      }
    });
    GetHolders({
      variables: {}
    });
  };

  const onIssueCredential = (issueData: any) => {
    console.log('credential issueData', issueData);
    IssueCredential({
      variables: {
        credentialKeyVals: issueData?.credentialKeyVals,
        holderEmail: issueData?.holderEmail,
        credentialTemplateKey: issueData?.credentialTemplateKey,
        category: issueData?.category
      }
    });
  };

  const onVerifyRequest = (requests: any) => {
    console.log('request credential', requests);
    RequestCredentials({
      variables: {
        requests: requests
      }
    });
  };

  const onNetworkPageChange = (page: number) => {
    console.log('network page change', page);
    getMyGraph({
      variables: {
        offset: (page - 1) * 12
        // offset:page,
      },
    });
  };

  const onPresentationSelect = (edgeTag: string) =>{
    console.log('onPresentation select', edgeTag);
    getPresentationDetials({
      variables:{
        isOrganization:true,
        edgeTag
      },
    });
  };

  const onCredentialResend = (resendData:any) => {
    console.log('container:: resend credential', resendData);
    ResendCredential({
      variables:{
        edgeTag:resendData?.edgeTag,
        did:resendData?.did,
        type:resendData?.type
      }
    });
  };

  const _renderBody = () => {
    console.log('presentation Details', presentationDetail);
    console.log('user Staus', createUserStatus);
    console.log('template form Data', provTemplateFormData);
    return (
      <>
        <Dashboard
          getUsersLoading = {getUserLoading}
          orgProfileData={orgProfData}
          opeProfileData={operatorProfData}
          sideNavItems={sideNavItems}
          userProfile={userProfile}
          holdersList={holdersData}
          issuersList={issuersList}
          verifiersList={verifierList}
          adminUsers={adminUsers}
          onInviteIssuer={onInviteIssuer}
          onInviteVerifier={onInviteVerifier}
          onInviteHolders={onInviteHolders}
          onInviteAdmin={onInviteAdmin}
          inviteUserLoading={loading}
          inviteUserStaus={createUserStatus}
          sidebarNavItemSelect={onNavSelect}
          credentialCategoryLoading={credentialCategoryLoading}
          credentialCategries={credCategoryData}
          onTemplateSelect={onTemplateSelect}
          templateData={provTemplateFormData}
          onIssueCredential={onIssueCredential}
          issueCredentialSuccess={issueCredData}
          issueCredLoading={issueCredLoading}
          findTemplates={templateFormLoading}
          networkData={trustNetworkData}
          networkLoading={networkLoading}
          onVerifyRequest={onVerifyRequest}
          reqCredLoading={reqCredLoading}
          reqCredSuccess={reqCredData ? true : false}
          onNetworkPageChange={onNetworkPageChange}
          adminLoading={getAdminsLoading}
          netwrokCredData={netwrokCredData}
          onPresentationSelect={onPresentationSelect}
          presentationDetails={presentationDetail}
          presentationDetailLoading={presentationDetailLoading}
          onCredentialResend={onCredentialResend}
          resendCredentialLoading={resendCredentialLoading}
        />
      </>
    );
  };

  return <>{_renderBody()}</>;
};
