import React, { useContext } from 'react';
import { Text, View, StyleSheet } from 'react-native';
import { PanelV } from 'react-panel-rs';
import { FBGridV, FBItemV } from 'react-flexbox-grid-rs';
import { LineChartV } from 'react-linechart-chartjs-2';
import { PieChartV } from 'react-piechart-chartjs-2';
// import { PiechartV } from 'react-piechart-nivo';
import { BarchartV } from 'react-barchart-nivo';
import { DashboardCardsV } from 'react-dashboard-cards-web';
import config from './config/default-blueprint.json';
import { IconV } from 'react-icon-rs';
import { TableV, TableHeaderDataProps } from 'react-table-rs';
import { ThemeContext } from '../../../../../../providers/theme';
import { ViewportProvider, useViewport } from 'react-viewport-provider';
import { StreamV } from 'react-streamchart-nivo';
import { Panel } from 'rsuite';

const BP = config.issuerDashboard;

const generateFakeData = async (schema, rows = 1) => {
    console.log('schema', schema);
    let res = [];
    try {
        if (schema) {
            for (let i = 0; i < rows; ++i) {
                let _sample = { ...schema[i] };

                res.push(_sample);
            }
        }
    } catch (err) { }
    return res;
};

const IssuerDashboardInternal = props => {
    const { width, height } = useViewport();
    const theme = useContext(ThemeContext);
    const columns: TableHeaderDataProps = BP?.userTable?.tableHeader;
    const schema = BP?.userTable?.tableData;
    const cardData = BP?.cardData;
    const [data, setData] = React.useState<any>([]);

    const { drawerWidth } = props;

    React.useEffect(() => {
        generateFakeData(schema, schema.length).then(res => {
            console.log('result', res);
            res && Array.isArray(res) && setData([...res]);
        });
    }, []);

    const styles = {
        textStyle: {
            fontSize: 24,
            fontWeight: 'bold',
            color: theme?.palette.ternary,
        },
        cardTitleStyle: {
            fontSize: 18,
            color: theme?.palette?.primary,
        },
        titleStyle: {
            fontSize: 18,
            color: theme?.palette.ternary,
        },
        tableStyle: {
            margin: '20px 0px',
            alignItems: 'center',
        },
        containerStyle: {
            maxHeight: height - (height * 0.06)
        },
        panelstyle: {
            backgroundColor: '#fff',
            margin: 5,
            alignItems: 'center',
        },
        statsStyle: {
            border: '5px solid #fff',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '50%',
            width: 150,
            height: 150,
            backgroundImage: 'linear-gradient(#a90cfe, #13b5f9)'
        },
        cardStyle: {
            padding: 8
        }
    };

    return (
        <View style={StyleSheet.flatten([styles.containerStyle])}>
            <FBGridV justify={'space-around'}>
                <FBItemV colspan={23}>
                    <PanelV shaded style={{ backgroundColor: '#fff', marginTop: 10 }}>
                        <DashboardCardsV items={cardData}
                            cardStyle={StyleSheet.flatten([styles.cardStyle])}
                        />
                    </PanelV>
                </FBItemV>
            </FBGridV>

            <FBGridV justify={'space-around'}>
                <FBItemV colspan={23}>
                    <PanelV shaded style={StyleSheet.flatten(styles.panelstyle)}>
                        <Text style={StyleSheet.flatten([styles.cardTitleStyle])}>{BP?.credentialsIssued?.title}</Text>
                        <LineChartV
                            data={BP?.credentialsIssued}
                            options={{ responsive: true }}
                        />
                    </PanelV>
                </FBItemV>

                <FBItemV colspan={23} style={StyleSheet.flatten([styles.tableStyle])}>
                    <TableV
                        onRowClick={data => console.log('row clicked', data)}
                        table={{
                            height: 500,
                            headerHeight: 40,
                            rowHeight: 50,
                            wordWrap: true,
                            data: [...data],
                        }}
                        tableContainerStyle={{ margin: '0 auto' }}
                        columns={columns}
                    />
                </FBItemV>
            </FBGridV>
        </View >
    );
};

export const IssuerDashboard = props => {
    return (
        <ViewportProvider>
            <IssuerDashboardInternal {...props} />
        </ViewportProvider>
    );
};
