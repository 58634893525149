import React from 'react';
import { useViewport } from 'react-viewport-provider';
import { ForgotPasswordLG } from './ForgotPassword.lg';

export interface IProps {
    onForgotPassword?(email?: string): void;
}

export const ForgotPasswordPage = (props: IProps) => {
    const { bp } = useViewport();

    // if (bp === 'xs' || bp === 'sm') {
    //   return <div />;
    // }

    // if (bp === 'md') {
    //   return <div />;
    // }

    //bp === 'lg' || bp === 'xl' || bp === 'md
    return <ForgotPasswordLG {...props} />;
};
