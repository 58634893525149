import React, { useContext } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { PanelV } from 'react-panel-rs';
import { AvatarV } from 'react-native-avatar-vfw';
import { ImageBackgroundV } from 'react-image-background';
import { ButtonGroupV } from 'react-button-rs';

import config from '../../config/default-blueprint.json';
import { FBGridV, FBItemV } from 'react-flexbox-grid-rs';
import { Modal, Button } from 'rsuite';
import { ModalStepper } from '../modalStepper';

const BP = config?.credentialList;

export const CredentialList = () => {
    const [show, setShow] = React.useState(false);
    const [list, setList] = React.useState({});
    const onModalClose = () => {
        if (show) {
            setShow(false);
        }
    }
    const styles = {
        panelStyle: {
            backgroundColor: '#fff',
            minHeight: 200,
            margin: 20,
            borderRadius: 10,
            shadowOpacity: 0.4,
            shadowRadius: 5,
            shadowColor: '#373a3b',
            elevation: 2,
            shadowOffset: { height: 0, width: 0 },
        },
        iconContainerStyle: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'start',
            alignSelf: 'center'
        },
        dateContainerStyle: {
            marginTop: 20,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItem: 'center',
            borderTop: '1px dotted #ccc'
        },
        avatarContainerStyle: {
            backgroundColor: '#fff',
            //  borderRadius: 10,
            shadowOpacity: 0.4,
            shadowRadius: 5,
            shadowColor: '#373a3b',
            elevation: 4,
            shadowOffset: { height: 0, width: 0 },
        },
        headingStyle: {
            fontSize: 20,
            alignSelf: 'center',
            fontWeight: 600,
            paddingLeft: 15
        },
        dateHeadingStyle: {
            display: 'block',
            fontSize: 14,
            fontWeight: '500',

        },
        dateStyle: {
            fontSize: 12,
        },
        issuerTitleStyle: {
            display: 'block',
            fontSize: 16,
            fontWeight: 500,
            paddingTop: 8
        },
        modalStyle: {
            width: '80%',
            alignSelf: 'center',
            alignItems: 'center',
            margin: '4% auto 0 auto'
        },
        buttonGroupStyle: { position: 'fixed', alignItems: 'flex-end', padding: 2, bottom: 5, right: 5 },
    }

    const onCardPress = (list: any) => {
        console.log('listData', list)
        setList(list);
        if (!show) {
            setShow(true)

        }
        return (
            // <Modal show={true} >
            //     <Modal.Body>
            //         <h3>hello</h3>
            //     </Modal.Body>
            // </Modal>
            <></>

        )
    }

    const _getModalBody = () => {
        return (
            <ModalStepper
                list={list}
            />
        )
    }

    const onNext = () => {

    }

    const _getCredentialList = () => {
        let _list = [];
        BP?.listData.forEach((element, index) => {
            _list.push(
                <FBItemV colspan={7}>
                    <div onClick={() => onCardPress(element)} style={{ cursor: 'pointer' }}>
                        <PanelV
                            shaded
                            style={StyleSheet.flatten([styles.panelStyle])}
                        >
                            <ImageBackgroundV image={"https://i.ibb.co/k0V3g40/cert600x400.png"} width={'100%'} height={150} >
                                <View style={StyleSheet.flatten([styles.iconContainerStyle])}>
                                    <AvatarV
                                        source={{ uri: element.icon }}
                                        size={80}
                                        containerStyle={StyleSheet.flatten([styles.avatarContainerStyle])}
                                    />
                                    <Text style={StyleSheet.flatten([styles.headingStyle])}>{element.title}</Text>
                                </View>
                                <Text style={StyleSheet.flatten([styles.issuerTitleStyle])}>{element.issuedBy}</Text>
                                <View style={StyleSheet.flatten([styles.dateContainerStyle])}>
                                    <div>
                                        <Text style={StyleSheet.flatten([styles.dateHeadingStyle])}>{element.issuedText}</Text>
                                        <Text style={StyleSheet.flatten([styles.dateStyle])}>{element.issuedDate}</Text>
                                    </div>
                                    <div>
                                        <Text style={StyleSheet.flatten([styles.dateHeadingStyle])}>{element.validText}</Text>
                                        <Text style={StyleSheet.flatten([styles.dateStyle])}>{element.validDate}</Text>
                                    </div>
                                </View>
                            </ImageBackgroundV>
                        </PanelV>
                    </div>
                </FBItemV>

            )
        });
        return _list
    }
    return (
        <>
            <FBGridV justify={'start'} align={'middle'}>
                {_getCredentialList()}
            </FBGridV>
            <Modal show={show} onHide={onModalClose} style={StyleSheet.flatten([styles.modalStyle])}>
                <Modal.Header >
                    <div />
                </Modal.Header>
                <Modal.Body style={{ height: 400 }}>

                    {_getModalBody()}
                </Modal.Body>
                <Modal.Footer>
                    <ButtonGroupV containerStyle={StyleSheet.flatten([styles.buttonGroupStyle])}>
                        <Button
                            onClick={() => window.location = BP?.livelynessURL} color={BP?.activeColor}
                        >{'Next'}
                        </Button>
                    </ButtonGroupV>
                </Modal.Footer>
            </Modal>
        </>
    )
}
