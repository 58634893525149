
import React, {useContext} from 'react';
import {ThemeContext} from '../../../providers/theme';
import blueprint from '../config/default-blueprint.json';
import { useViewport } from 'react-viewport-provider';
import {FORM_VALUES, defaultValuesDS} from 'react-form-rs';
import {SignupPageV} from 'react-signup-template-1';


let formContext = {};
const BP = blueprint.SignupPage;

export const SignupPageLG = (props: any) => {
  const theme = useContext(ThemeContext);
  const {width, height} = useViewport();
  const [validateForm, setValidateForm] = React.useState<boolean>(false);

  const handleValidateResult = (
    formContext: any,
    status: 'success' | 'error',
    validateResult: any,
  ) => {
    console.log('defaultValuesDS', defaultValuesDS.get());
    if (status === 'success') {
      props.onGetStarted &&
        props.onGetStarted(defaultValuesDS.get('emailAddress')?.defaultValue);
      defaultValuesDS.clear();
    }
    if (status === 'error') {
      BP.getStartedFFEC && Alert.error(BP.getStartedFFEC, 10000);
    }
    setDate(new Date());
    setValidateForm(false);
  };
  return (
    <SignupPageV
      containerStyle={{
        width: 1 * width,
        height: 1 * height,
        backgroundColor: '#FAFBFC',
      }}
      title={"Contact Me"}
      leftAvatar={BP.leftAvatar}
      signupButtonColor={theme?.palette.secondary}
      bodyTitle={'New Account'}
      formFields={BP.formFields}
      formContext={formContext}
      validateForm={validateForm}
      onHeaderLogoPress={() => alert('handle navigation')}
      onValidateResult={(formContext, status, validateResult) => {
        console.log('formcontext', formContext);
        console.log('status', status);
        console.log('validateResult', validateResult);
      }}
      onGetStarted={() => {
        setValidateForm(true);
      }}
      waveColor={theme?.palette.secondary}
    />
  );
};
