//@ts-nocheck
import React, {useState, useContext} from 'react';
import {FBGridV, FBItemV} from 'react-flexbox-grid-rs';
import {StyleSheet} from 'react-native';
import {ImageV} from 'react-native-image-vfw';
import {PanelV} from 'react-panel-rs';
import {useViewport} from 'react-viewport-provider';
import 'rsuite/dist/styles/rsuite-default.css';
import Divider from 'rsuite/lib/Divider';
import {Form} from '../index';
import {ThemeContext} from '../../../../providers/theme';
import {QRCodeGeneratorV} from 'react-native-qr-code-generator-vfw';
import blueprint from '../../config/default-blueprint.json';
import {QrScanner} from '../QrScanner';

const BP = blueprint.VerifyWallet;

interface VerifyWalletProps {
  qrValue?: string;
  qrLabel?: string;
}

export const VerifyWalletLG = (props: VerifyWalletProps) => {
  const theme = useContext(ThemeContext);
  const {height} = useViewport();
  const styles = {
    panelStyle: {
      marginLeft: '10%',
      marginRight: '10%',
      marginBottom: '5%',
      justifyContent: 'center',
      backgroundColor: ''.concat(theme?.palette.secondaryPrime, '70'),
      borderRadius: 20,
    },
    FBGridStyle: {
      justifyContent: 'center',
      alignItems: 'center',
    },
    FBItemStyle: {
      justifyContent: 'center',
      alignItems: 'center',
      margin: '0 auto',
    },
    rightContainerImageStyle: {
      width: 200,
      height: 200,
    },
    qrLableStyle: {
      fontSize: 20,
      // fontFamily: theme?.typography.caption,
    },
  };

  return (
    <PanelV shaded style={StyleSheet.flatten([styles.panelStyle])}>
      <FBGridV style={StyleSheet.flatten([styles.FBGridStyle])}>
        <FBItemV colspan={7} style={StyleSheet.flatten([styles.FBItemStyle])}>
          <QrScanner
            qrValue={props.qrValue ?? BP.qrValue}
            qrLabel={props.qrLabel ?? BP.qrLabel}
            qrLabelStyle={StyleSheet.flatten([styles.qrLableStyle])}
            qrSize={BP.qrSize ?? 150}
          />
        </FBItemV>
        <Divider vertical style={{height: 200, width: 5}} />
        <FBItemV colspan={5} style={StyleSheet.flatten([styles.FBItemStyle])}>
          <ImageV
            source={BP.rightContainerImage ?? 'https://i.ibb.co/QJRdZJL/3.png'}
            imageStyle={StyleSheet.flatten([styles.rightContainerImageStyle])}
          />
        </FBItemV>
      </FBGridV>
    </PanelV>
  );
};
