import React, { useContext } from 'react';
import { StyleSheet } from 'react-native';
import config from './config/default-blueprint.json';
import { ThemeContext } from '../../../../../../providers/theme';
import { useViewport, ViewportProvider } from 'react-viewport-provider';
import { BodyTemplateV } from 'react-body-template-1';
import { CredentialTemplateV } from 'react-credentail-template';
import { DrawerV } from 'react-drawer-rs';
import { LogoSpinnerV } from 'react-logo-spinner-ext';
import Divider from 'rsuite/lib/Divider';
import jsf from 'json-schema-faker';
import faker from 'faker';
import Chance from 'chance';

import { InviteVerifier } from './components/InviteVerifier/InviteVerifier';
import { IProps } from './index';
import { NetworkCredentialTemplateV } from 'react-network-credentail-template';

jsf.extend('faker', () => faker);
jsf.extend('chance', () => new Chance());


const humanizeString = require('humanize-string');
const BP = config.verifiers;


export const VerifierListLG = (props: IProps) => {
    const theme = useContext(ThemeContext);
    const { width, height } = useViewport();
    const [data, setData] = React.useState<any>([]);
    const [userActionKey, setUserActionKey] = React.useState('');
    const [drawer, setDrawer] = React.useState<any>({
        placement: 'right',
        title: '',
        size: 'xs',
        backdrop: false,
        show: false,
    });

    const styles = {
        leftcontainerStyle: {
            display: 'flex',
        },
        rightContainerStyle: {
            float: 'right',
            padding: 10,
        },
        containerStyle: {
            backgroundColor: '#f7f7fa',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: 'none',
            height: height - (height * 0.15),
            padding: 20,
        },
    };
    console.log('verifiers list in component', props.verifiersList);
    let indivisualVerifier = props?.verifiersList && props?.verifiersList.filter((item:any) => {
        if(item.organization === null){
            return true;
        }
    });

    let _indVerifiers = Array.isArray(indivisualVerifier) && indivisualVerifier.map(item=>{
        return({
                key: item?.email,
                title: item?.fullName ? item?.fullName : item?.email,
                tooltip: item?.fullName ? item?.fullName : item?.email,
                isOrganization: true,
                subtile2: "",
                icon: {
                    imageURI: item?.image ? item?.image : `https://ui-avatars.com/api/?name=${item?.fullName ? item?.fullName : item?.email}`,
                    imgWidth: 40,
                    imgHeight: 40
                }
        });
    });

    let organizationVerifier = props?.verifiersList && props?.verifiersList.filter((item:any) => {
        if(item.organization !== null){
            return true;
        }
    }); 

    let _orgVerifiers = Array.isArray(organizationVerifier) && organizationVerifier.map(item=>{
        return({
                key: item?.email,
                title: item?.organizationFull?.name ? item?.organizationFull?.name: item?.email,
                tooltip: item?.organizationFull?.name ? item?.organizationFull?.name: item?.email,
                isOrganization: true,
                subtile2: "",
                icon: {
                    imageURI: item?.organizationFull?.image ? item?.organizationFull?.image : `https://ui-avatars.com/api/?name=${item?.organizationFull?.name ? item?.organizationFull?.name: item?.email}`,
                    imgWidth: 40,
                    imgHeight: 40
                }
        });
    });

    const onNavItemClick = (eventKey: string) => {
        setUserActionKey(eventKey);
        if (eventKey === 'newVerifier') {
            let _drawer = { ...drawer };
            _drawer.title = "New Verifier";
            _drawer.placement = BP?.drawerPlacement;
            _drawer.show = true;
            setDrawer({
                ..._drawer,
            });
        }
    };

    const renderDrawerBody = () => {
        if (userActionKey === 'newVerifier') {
            return <InviteVerifier 
            onInviteVerifier={props.onInviteVerifier}
            />;
        }
        return <div />;
    };

    const handleOnDrawerHide = () => {
        let _drawer = { ...drawer };
        _drawer.show = false;
        setDrawer({
            ..._drawer,
        });
    };

    const renderDrawerHeader = () => {
        return <div />;
    };

    const renderDrawerFooter = () => {
        return <div />;
    };

    const bodyContent = () => {
        if(props?.getUsersLoading){
            return <LogoSpinnerV imageStyle={{marginLeft:'40%'}}/>;
        }
        else {
            return (
                <ViewportProvider>
                    <NetworkCredentialTemplateV
                         templateDetails={_indVerifiers}
                        // onCardClick={eventKey => props.onCardClick(eventKey)}
                        title={BP?.indivisualTitle}
                        titleStyle={{ fontSize: 18 }}
                        shaded={true}
                        containerStyle={StyleSheet.flatten([styles.containerStyle])}
                        iconStyle={{ borderRadius: 20 }}
    
                    >
                        <Divider />
                        <NetworkCredentialTemplateV
                            templateDetails={_orgVerifiers}                       
                            title={BP?.organizationTitle}
                            titleStyle={{ fontSize: 18 }}
                            onCardClick={eventKey => console.log(eventKey)}
                            iconStyle={{ borderRadius: 20 }}
                        />
    
                    </NetworkCredentialTemplateV>
                </ViewportProvider>
            );
        }       
    };

    return (
        <>
            <BodyTemplateV
                primaryColor={BP?.primaryColor || theme?.palette.primary}
                primaryHighlightColor={
                    BP?.primaryHighlightColor || theme?.palette.secondary
                }
                secondaryColor={BP?.secondaryColor || theme?.palette.secondary}
                ternaryColor={BP?.ternaryColor || theme?.palette.ternary}
                renderContent={bodyContent}
                contentScrollableInline={true}
                headerTitleStyle={{ marginLeft: 20 }}
                navbarStyle={{}}
                layout={'sidebar-header-content-footer'}
                navBarRightItems={BP?.navRightItems}
                navBarLeftItems={BP?.navLeftItems}
                onNavItemSelect={onNavItemClick}
                headerBackgroundColor={'#fff'}
                renderFooter={() => <div />}
            />
            <DrawerV
                placement={drawer.placement}
                show={drawer.show}
                backdrop={drawer.backdrop}
                size={drawer.size}
                renderHeader={renderDrawerHeader}
                onHide={handleOnDrawerHide}
                drawerTitle={drawer.title}
                renderBody={renderDrawerBody}
                renderFooter={renderDrawerFooter}
            />
        </>
    );
};
