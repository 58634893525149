import React, { useContext, useLayoutEffect, useEffect } from 'react';
import { View, Text } from 'react-native';
import { StyleSheet } from 'react-native';
import config from './config/default-blueprint.json';
import { ThemeContext } from '../../../../../../../../providers/theme';
import { BodyTemplateV } from 'react-body-template-1';
import { Icon, Whisper, Tooltip, Divider } from 'rsuite';
import { ButtonV, IconButtonV, ButtonGroupV } from 'react-button-rs';
import { Modal, Button } from 'rsuite';
import { IconV } from 'react-icon-rs';
import { SelectInputV } from 'react-select-input-rs';
import {SignVerification} from '../../signVerificationRequest';

import { TableV, TableHeaderDataProps } from 'react-table-rs';
import { DrawerV } from 'react-drawer-rs';
import jsf from 'json-schema-faker';
import faker from 'faker';
import Chance from 'chance';
import { empty } from 'apollo-link';
import { TextV } from 'react-native-text-vfw';
import { LogoSpinnerV } from 'react-logo-spinner-ext';
import { AvatarV, AvatarSize } from 'react-native-avatar-vfw';

jsf.extend('faker', () => faker);
jsf.extend('chance', () => new Chance());

const humanizeString = require('humanize-string');
const BP = config.CredentialList;

type UserCredentailsProps = {
    onSearchClick?(): void;
    onCredentialClick?(data?: any): void;
    credList?: any;
    userType?: string;
    activeCategory?:string;
    findTemplates?:boolean;
    breadCrumbsClick?(key:string):void;
};

const styles = {
    leftcontainerStyle: {
        display: 'flex',
    },
    rightContainerStyle: {
        float: 'right',
    },
    twoColStyle: {
        display: 'flex',
        flexDirection:'row',
        alignItems:'center'
    },
    tableStyle: {
        margin: '0 auto',
    },
    modalStyle: {
        width: '60%',
        alignSelf: 'center',
        alignItems: 'center',
        margin: '4% auto 0 auto'
    },
    titleStyle:{
        fontSize:22
    }
};
const generateFakeData = async (schema, rows = 1) => {
    console.log('schema', schema);
    
    let res = [];
    try {
        if (schema) {
            for (let i = 0; i < rows; ++i) {
                let _sample = { ...schema[i] };
                let logoIndex = Array.isArray(_sample?.dataProperties) && _sample?.dataProperties.findIndex(item=>item.key === 'logo');
                // console.log('logo Index in table', logoIndex);
                _sample.credential = (
                    <View style={StyleSheet.flatten([styles.twoColStyle])}>
                        <AvatarV
                            source={{uri:_sample?.dataProperties[logoIndex]?.placeholder ? _sample?.dataProperties[logoIndex]?.placeholder : `https://ui-avatars.com/api/?name=${_sample.name}`}}
                            avatarStyle={StyleSheet.flatten([styles.iconStyle])}
                            rounded
                            size={AvatarSize.Small}
                        />
                        <Text style={{paddingLeft:5}}>{_sample.name}</Text>
                    </View>
                );
                _sample.issuer = (
                    <View>
                        <Text>{_sample.issuer}</Text>
                        <Text>
                            <a href={`mailto:${_sample.email}`}>{_sample.email}</a>
                        </Text>
                    </View>
                );
                _sample.verified = (
                    <View style={StyleSheet.flatten([styles.twoColStyle])}>
                        <IconV icons={BP?.verifiedIcon} />
                        <Text>{_sample.verified}</Text>
                    </View>
                );
                _sample.action = <IconButtonV icon={{ name: 'more' }} circle />;
                res.push(_sample);
            }
        }
    } catch (err) {
        console.log('error generating data', err);
    }
    return res;
};

const columns: TableHeaderDataProps[] = [
    {
        label: 'Credential Name',
        dataKey: 'credential',
        appearance: 'icon',
        columnProps: {
            fixed: true,
            width: 250,
        },
        labelStyle: {
            color: '#606161',
        },
        headerCellStyle: {
            backgroundColor: '#f5f5f5',
            height: 50,
        },
    },
    {
        label: 'Issuer Name',
        dataKey: 'group',
        appearance: 'text',
        appearanceProps: {
            style: {
                // color: '#d3d3d3'
            },
        },
        columnProps: {
            sortable: true,
            width: 300,
        },
        cellStyle: {
            //  backgroundColor: '#d3d3d3'
        },
        labelStyle: {
            color: '#606161',
        },
        headerCellStyle: {
            backgroundColor: '#f5f5f5',
            height: 50,
        },
    },
    {
        label: 'More',
        dataKey: 'action',
        appearance: 'edit',
        labelStyle: {
            color: '#606161',
        },
        columnProps: {
            width: 200,
        },
        headerCellStyle: {
            backgroundColor: '#f5f5f5',
            height: 50,
        },
    },
];

export const CredentailList = (props: UserCredentailsProps) => {

    let schema = Array.isArray(props.credList) && props.credList.map(item=>{
        return{
            key:item?.key,
            templateKey:item?.templateKey,
            name:item?.name,
            group:item?.description,
            icon: item?.logo,
            dataProperties: item?.dataProperties,
        }
    });
    console.log('list', schema);
    const theme = useContext(ThemeContext);
    const [data, setData] = React.useState<any>([]);
    const [userActionKey, setUserActionKey] = React.useState('');
    const [category, setCategory] = React.useState({});
    const [show, setShow] = React.useState<Boolean>(false);
    const [drawer, setDrawer] = React.useState<any>({
        placement: 'right',
        title: '',
        size: 'sm',
        backdrop: false,
        show: false,
    });
    const onModalClose = () => {
        if (show) {
            setShow(false);
        }
    }

    React.useEffect(() => {
        generateFakeData(schema, schema.length).then(res => {
            console.log('result', res);
            res && Array.isArray(res) && setData([...res]);
        });
    }, [props.credList]);

    const onAddNewUserClick = (eventKey: string) => {
        setUserActionKey(eventKey);
        if (eventKey === 'adduser') {
            let _drawer = { ...drawer };
            _drawer.title = humanizeString(eventKey);
            _drawer.placement = BP?.userDrawerPlacement;
            _drawer.show = true;
            setDrawer({
                ..._drawer,
            });
        }
    };

    const renderDrawerBody = () => {
        if (userActionKey === 'adduser') {
            return <InviteUser />;
        }
        return <div />;
    };

    const handleOnDrawerHide = () => {
        let _drawer = { ...drawer };
        _drawer.show = false;
        setDrawer({
            ..._drawer,
        });
    };

    const onRowClick = data => {
        console.log('inside table', data);
        if(props.userType === "indivisual"){
            setShow(true)
        }
        else {
            props.onCredentialClick && props.onCredentialClick(data);
        }
        
    };

    const _getModalTitle = () => {
        return(
            <TextV style={StyleSheet.flatten([styles.titleStyle])}>
                {BP?.modalTitle}
            </TextV>
        )
    }

    const renderDrawerHeader = () => {
        return <div />;
    };

    const renderDrawerFooter = () => {
        return <div />;
    };

    const bodyContent = () => {
        if(props.findTemplates){
            return <LogoSpinnerV imageStyle={{left:'50%'}}/>
        }
        else {
            return (
                <div style={StyleSheet.flatten([styles.tableStyle])}>
                    <TableV
                        onRowClick={data => onRowClick(data)}
                        table={{
                            height: 530,
                            headerHeight: 40,
                            rowHeight: 50,
                            wordWrap: true,
                            //cellBordered: true,
                            loading:true,
                            data: [...data],
                        }}
                        columns={columns}
                        
                    />
                </div>
            );
        }
       
    };
    const _getModalBody = () => {
        return <SignVerification />;
    }
    const onBreadcrumbsClick=(key:string)=>{
        props.breadCrumbsClick && props.breadCrumbsClick(key)
    }
    return (
        <>
            <BodyTemplateV
                primaryColor={BP?.primaryColor || theme?.palette.primary}
                primaryHighlightColor={
                    BP?.primaryHighlightColor || theme?.palette.secondary
                }
                secondaryColor={BP?.secondaryColor || theme?.palette.secondary}
                ternaryColor={BP?.ternaryColor || theme?.palette.ternary}
                renderContent={bodyContent}
                contentScrollableInline={true}
                headerTitleStyle={{ marginLeft: 20 }}
                navbarStyle={{}}
                layout={'sidebar-header-content-footer'}
                headerBackgroundColor={'#fff'}
                onNavItemSelect={eventKey => console.log(eventKey)}
                breadcrumbs={BP?.breadcrumbs}
                onBreadcrumbsClick={(key)=>onBreadcrumbsClick(key)}
                renderFooter={() => <div />}
            />
            <DrawerV
                placement={drawer.placement}
                show={drawer.show}
                backdrop={drawer.backdrop}
                size={drawer.size}
                renderHeader={renderDrawerHeader}
                onHide={handleOnDrawerHide}
                drawerTitle={drawer.title}
                renderBody={renderDrawerBody}
                renderFooter={renderDrawerFooter}
            />
            <Modal show={show} onHide={onModalClose} style={StyleSheet.flatten([styles.modalStyle])}>
                <Modal.Header >
                    {_getModalTitle()}
                </Modal.Header>
                <Modal.Body style={{ height: 500 }}>

                    {_getModalBody()}
                </Modal.Body>
            </Modal>
        </>
    );
};
