import { useMutation } from '@apollo/react-hooks';
import { ConsoleLogger, LoggerService } from 'logger-extension-vfs';
import React, { useEffect, useState } from 'react';
import { pretty } from 'react-native-helpers';

import { useHistory } from 'react-router-dom';
import { LandingTemplate } from './components/LandingTemplate';
import { GET_STARTED } from './mutation';
import Alert from 'rsuite/lib/Alert';
import axios from 'axios';
import { LogoSpinnerV } from 'react-logo-spinner-ext';
import {Verifier} from '../LandingPage/components/VerifierPage';

import config from '../../config/default.config.json';

const { verifyEP, apiEP, httpHost } = config?.servers?.http;

const console: LoggerService = new LoggerService(
  new ConsoleLogger('LandingPage:container'),
);

export const LandingPage = () => {
  const history = useHistory();
  const [GetStarted, { data, loading, error, called }] = useMutation(GET_STARTED);
  const [detail, setDetail] = useState<any>();
  const [url, setUrl] = useState<string>('');
  const [verifyError, setVerifyError] = useState<boolean>(false);
  useEffect(() => {
    const url = window.location.href;
        _getVerifyData(url)
    if (
      error &&
      error.graphQLErrors &&
      Array.isArray(error.graphQLErrors) &&
      error.graphQLErrors[0] &&
      error.graphQLErrors[0].message
    ) {
      // console.error(`error in landing: ${pretty(error)}`);
      // Alert.error(`${error.graphQLErrors[0].message}`, 10000);
    }
  }, [error]);
  useEffect(() => {
    if (data) {
      Alert.success(data.signUp, 10000);
    }
  }, [data]);

  const _getVerifyData = (url: string) => {
    setUrl(url);
    let params = url.split("/").slice(-2);
    console.info('params', params);
    if(params){
      const getURL = encodeURI(`${httpHost}${apiEP}${verifyEP}/${params[0]}/${params[1]}`);
    axios.get(getURL).then((res) => {
        if (res && res?.data) {
            setDetail(res?.data)
        }
    }).catch(err => {
        if(!verifyError){
          setVerifyError(true);
        }
    });
    }
    
}

  const handleGetStarted = (emailAddress: string) => {
    if (emailAddress) {
      GetStarted({
        variables: {
          credential: {
            email: emailAddress,
          },
        },
      });
    }
  };
  const _renderBody = () => {

    if(detail){
      return <Verifier 
      credentialData={detail}
      // headerLogoClick={()=>history.push('/')}
      />
    }
    else if(verifyError){
      return (
        <LandingTemplate
          onLoginClick={() => history.push('/login')}
          onSignupClick={() => history.push('/signup')}
          onGetStarted={handleGetStarted}
          loading={loading}
        />
      );
    }
    else {
      return <LogoSpinnerV  />
    }
    
  };
  return <>{_renderBody()}</>;
};
