import React, { useContext } from 'react';
import { StyleSheet, Text } from 'react-native';

import { useViewport, ViewportProvider } from 'react-viewport-provider';
import { BodyTemplateV } from 'react-body-template-1';
import { CredentialList } from './components/credentialList';

import { ThemeContext } from '../../../../../../providers/theme';
import config from './config/default-blueprint.json';

const BP = config?.notarize;

export const NotarizeLG = () => {
    const theme = useContext(ThemeContext);

    const bodyContent = () => {
        return <CredentialList />;
    }
    const onNavItemClick = () => {
        return <div />
    }
    return (
        <>
            <BodyTemplateV
                primaryColor={BP?.primaryColor || theme?.palette.primary}
                primaryHighlightColor={
                    BP?.primaryHighlightColor || theme?.palette.secondary
                }
                secondaryColor={BP?.secondaryColor || theme?.palette.secondary}
                ternaryColor={BP?.ternaryColor || theme?.palette.ternary}
                renderContent={bodyContent}
                contentScrollableInline={true}
                headerTitleStyle={{ marginLeft: 20 }}
                navbarStyle={{}}
                layout={'sidebar-header-content-footer'}
                navBarRightItems={BP?.navRightItems}
                navBarLeftItems={BP?.navLeftItems}
                onNavItemSelect={onNavItemClick}
                headerBackgroundColor={'#fff'}
                renderFooter={() => <div />}
                sidebarFixedWidth={80}
            />
        </>
    )
}