
import React from 'react';
import {useViewport} from 'react-viewport-provider';
import {LandingTemplateLG} from './LandingTemplate.lg';
import {LandingTemplateMD} from './LandingTemplate.md';
import {LandingTemplateSM} from './LandingTemplate.sm';

export type IProps = {
  onLoginClick?(): void;
  onSignupClick?(): void;
  onGetStarted?(formContext: any): void;
  loading?: boolean;
}

export const LandingTemplate = (props: IProps) => {
  const {bp} = useViewport();

  // if (bp === 'xs' || bp === 'sm') {
  //   return <LandingTemplateSM />;
  // }

  // if (bp === 'md') {
  //   return <LandingTemplateMD />;
  // }

  //bp === 'lg' || bp === 'xl' || bp === 'md
  return (
    <LandingTemplateLG
      {...props}
    />
  );
};
