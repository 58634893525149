import React from 'react';
import {useViewport} from 'react-viewport-provider';
import {VerifierLG} from './Verifier.lg';
import {VerifierMD} from './Verifier.md';
import {VerifierSM} from './Verifier.sm';

export interface IProps {
  credentialData?:any;
  headerLogoClick?:void
}


export const Verifier = (props: IProps) => {
  const {bp} = useViewport();

  if (bp === 'xs' || bp === 'sm') {
    return <VerifierSM {...props}/>;
  }

  // if (bp === 'md') {
  //   return <DashboardMD />;
  // }

  //bp === 'lg' || bp === 'xl' || bp === 'md
  return <VerifierLG  {...props}/>;
};
