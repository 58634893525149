import React, { useState } from 'react';
import { StepV } from 'react-steps-rs';
import { QRCodeGeneratorV } from 'react-native-qr-code-generator-vfw';
import { StyleSheet, View, Text } from 'react-native';
import { PanelV } from 'react-panel-rs';
import { AvatarV } from 'react-native-avatar-vfw';
import { ImageBackgroundV } from 'react-image-background';
import { Divider, Toggle } from 'rsuite';
import { FBGridV, FBItemV } from 'react-flexbox-grid-rs';
import { IdCardTemplateV1 } from 'react-id-card-template-1';

const steps: any = [
    {
        key: 'first-step',
        title: 'Select Credential',
    },
    {
        key: 'liveness',
        title: 'Liveness Detection',
    },
    {
        key: 'sign',
        title: 'Sign and Confirm',
    },
];

const bodyData = [
    {
        p:
            'A paragraph is a series of related sentences developing a central idea, called the topic. Try to think about paragraphs in terms of thematic unity: a paragraph is a sentence or a group of sentences that supports one central, unified idea. Paragraphs add one idea at a time to your broader argument.',
    },
    {
        p:
            'A paragraph is a series of related sentences developing a central idea, called the topic. Try to think about paragraphs in terms of thematic unity: a paragraph is a sentence or a group of sentences that supports one central, unified idea. Paragraphs add one idea at a time to your broader argument.',
    },
    {
        p: 'step 3',
    },
];

export const ModalStepper = (list: any) => {
    const [show, setShow] = useState(true);
    const [toggle, setToggle] = useState<boolean>(false);

    const styles = {
        outerQrStyle: {
            width: '80%',
            margin: '0 auto',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
            justifyContent: 'center'
        },
        qrStyle: {
            backgroundColor: '#dcdcdc',
            padding: 8,
            width: 266,
            margin: '0 auto'
        },
        textStyle: {
            textAlign: 'center',
            fontSize: 24,
            fontWeight: 500,
            alignSelf: 'center',
        },
        buttonGroupStyle: { position: 'fixed', alignItems: 'flex-end', padding: 2, bottom: 0, right: 0 },
        panelStyle: {
            backgroundColor: '#fff',
            width: 350,
            // minHeight: 200,
            margin: 20,
            borderRadius: 10,
            shadowOpacity: 0.4,
            shadowRadius: 5,
            shadowColor: '#373a3b',
            elevation: 2,
            shadowOffset: { height: 0, width: 0 },
        },
        iconContainerStyle: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'start',
            alignSelf: 'center'
        },
        dateContainerStyle: {
            marginTop: 20,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItem: 'center',
            borderTop: '1px dotted #ccc'
        },
        avatarContainerStyle: {
            backgroundColor: '#fff',
            //  borderRadius: 10,
            shadowOpacity: 0.4,
            shadowRadius: 5,
            shadowColor: '#373a3b',
            elevation: 4,
            shadowOffset: { height: 0, width: 0 },
        },
        headingStyle: {
            fontSize: 20,
            alignSelf: 'center',
            fontWeight: 600,
            paddingLeft: 15
        },
        dateHeadingStyle: {
            display: 'block',
            fontSize: 14,
            fontWeight: '500',

        },
        dateStyle: {
            fontSize: 12,
        },
        issuerTitleStyle: {
            display: 'block',
            fontSize: 16,
            fontWeight: 500,
            paddingTop: 8
        },
        gridStyle: {
            width: '70%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignSelf: 'center',
            backgroundColor: '#ececec',
            borderBottom: '2px solid #f1f1f1',
        },
        headerGridStyle: {
            width: '70%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignSelf: 'center',
            backgroundColor: '#3498FF',
            borderBottom: '2px solid #f1f1f1',
        },
        labelStyle: {
            padding: 10,
            fontSize: 14,
            fontWeight: 500
        },
        valueStyle: {
            padding: 10,
            fontSize: 14,
        },
        tableHeadingStyle: {
            fontSize: 28,
            fontWeight: 500,
            color: '#fff',
            margin: '0 auto',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 5
        },
        linkContainerStyle: {
            position: 'fixed',
            bottom: '30%',
            padding: 10,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignSelf: 'center'
        }
    }

    const onToggleChange = (checked: boolean) => {
        console.log('checked', checked);
        if (checked) {
            setToggle(true)
        }
        else {
            setToggle(false)
        }
    }

    const _getData = (key: any) => {
        console.log('list inside modal', list?.list)
        const credData = list?.list;
        const listData: any[] = [];
        const tempView: any[] = [];

        if (credData.key === 'idCard') {
            tempView.push(
                <View style={{ justifyContent: 'center', alignItems: 'center' }}>
                    <IdCardTemplateV1
                        width={220}
                        height={100}
                        avatarSize={40}
                        profileSrc={credData?.profileImg}
                        name={credData?.givenName}
                        id={credData?.idNumber}
                        phoneNumber={credData?.telephone}
                        avatarSrc={undefined}
                        profileAvatarSize={80}
                        title={undefined}
                    />
                </View>
            )
        }

        listData.push(
            <View style={StyleSheet.flatten([styles.headerGridStyle])}>
                <Text style={StyleSheet.flatten([styles.tableHeadingStyle])}>
                    {credData?.title}
                </Text>
            </View>
        )
        credData?.details && credData?.details.forEach((element, index) => {
            listData.push(
                <View style={StyleSheet.flatten([styles.gridStyle])}>
                    <Text style={StyleSheet.flatten([styles.labelStyle])}>
                        {element.label}
                    </Text>
                    <Text style={StyleSheet.flatten([styles.valueStyle])}>
                        {element.value}
                    </Text>
                </View>
            )
        });
        switch (key) {
            case 0:
                return (
                    // <View style={{ display: 'block', flexDirection: 'row', alignSelf: 'center' }}>
                    <FBGridV justify={'center'} align={'middle'}>
                        <FBItemV colspan={14}>
                            <View>
                                {toggle ? listData : tempView}
                            </View>
                        </FBItemV>
                        <FBItemV colspan={9}>
                            <View style={StyleSheet.flatten([styles.linkContainerStyle])}>
                                <Text style={{ textAlign: 'right', fontSize: 16, paddingTop: 5, paddingRight: 10 }}>
                                    Change Style
                                </Text>
                                <Toggle size="md"
                                    onChange={onToggleChange}
                                    style={{ marginTop: 5 }}
                                    checked={toggle}
                                />

                            </View>
                            <Text style={{
                                textAlign: 'right', fontSize: 16, paddingTop: 5, paddingLeft: 10, position: 'fixed',
                                bottom: '26%',
                            }}>
                                <a href="#" target="_blank">
                                    View Document...
                            </a>
                            </Text>
                        </FBItemV>
                    </FBGridV>

                    // </View>
                )
            case 1:
                return null;
            case 2:
                return (
                    <FBGridV justify={'center'} align={'middle'}>
                        <FBItemV colspan={10} >
                            <QRCodeGeneratorV
                                containerStyle={StyleSheet.flatten([styles.qrStyle])}
                                size={250}
                            />
                        </FBItemV>
                        <FBItemV colspan={4}>
                            <Divider vertical style={{ height: 260, width: 2 }} />
                        </FBItemV>
                        <FBItemV colspan={10}>
                            <Text style={StyleSheet.flatten([styles.textStyle])}>
                                Sign Using <br /> vID Wallet
                        </Text>
                        </FBItemV>
                    </FBGridV>
                );
            default:
                return null;
        }
    }

    const credData = list?.list;
    const listData: any[] = [];
    const tempView: any[] = [];

    if (credData.key === 'idCard') {
        tempView.push(
            <View style={{ justifyContent: 'center', alignItems: 'center' }}>
                <IdCardTemplateV1
                    width={220}
                    height={100}
                    avatarSize={40}
                    profileSrc={credData?.profileImg}
                    name={credData?.givenName}
                    id={credData?.idNumber}
                    phoneNumber={credData?.telephone}
                    avatarSrc={undefined}
                    profileAvatarSize={80}
                    title={undefined}
                />
            </View>
        )
    }

    listData.push(
        <View style={StyleSheet.flatten([styles.headerGridStyle])}>
            <Text style={StyleSheet.flatten([styles.tableHeadingStyle])}>
                {credData?.title}
            </Text>
        </View>
    )
    credData?.details && credData?.details.forEach((element, index) => {
        listData.push(
            <View style={StyleSheet.flatten([styles.gridStyle])}>
                <Text style={StyleSheet.flatten([styles.labelStyle])}>
                    {element.label}
                </Text>
                <Text style={StyleSheet.flatten([styles.valueStyle])}>
                    {element.value}
                </Text>
            </View>
        )
    });
    return (
        <FBGridV justify={'center'} align={'middle'}>
            <FBItemV colspan={14}>
                <View>
                    {toggle ? listData : tempView}
                </View>
            </FBItemV>
            <FBItemV colspan={9}>
                <View style={StyleSheet.flatten([styles.linkContainerStyle])}>
                    <Text style={{ textAlign: 'right', fontSize: 16, paddingTop: 5, paddingRight: 10 }}>
                        Change Style
                                </Text>
                    <Toggle size="md"
                        onChange={onToggleChange}
                        style={{ marginTop: 5 }}
                        checked={toggle}
                    />

                </View>
                <Text style={{
                    textAlign: 'right', fontSize: 16, paddingTop: 5, paddingLeft: 10, position: 'fixed',
                    bottom: '26%',
                }}>
                    <a href="#" target="_blank">
                        View Document...
                            </a>
                </Text>
            </FBItemV>
        </FBGridV>

        // <StepV
        //     steps={steps}
        //     style={{ backgroundColor: '#fcfcfc', }}
        //     vertical={false}
        //     containerStyle={{ width: '100%', height: 440, padding: 5 }}
        //     buttonGroupStyle={StyleSheet.flatten(styles.buttonGroupStyle)}
        //     buttonStyle={{ color: '#fff' }}
        //     activeColor={'red'}
        //     bodyContent={key => _getData(key)}
        //     isNextEnabled={true}
        // />
    )
}