import React from 'react';
import { useViewport } from 'react-viewport-provider';
import { HoldersListLG } from './Holders.lg';

export interface IProps {
    holdersList?: any[];
    onInviteHolders?(formData?:any): void;
    inviteUserLoading?:boolean;
    getUsersLoading?:boolean;
}

export const HoldersList = (props: IProps) => {
    const { bp } = useViewport();

    // if (bp === 'xs' || bp === 'sm') {
    //     return <DashboardSM />;
    // }

    // if (bp === 'md') {
    //   return <DashboardMD />;
    // }

    //bp === 'lg' || bp === 'xl' || bp === 'md
    return <HoldersListLG {...props} />;
};
